@charset 'UTF-8'
@import '../_mixin';

/*
 * style.css
 */
/*
    File Name   : var.scss
    Description : 変数用
*/
/* ------------------------------------------------------ */
/* base */
/*
    File Name   : base.scss
    Description : 各ブロックのレイアウト
*/

/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}



/* ログイン時ログアウト時の状況に応じた表示制御用のスタイルを追加しました */
body:not([data-eccom-login]) #pd_main-header__navi, body:not([data-eccom-login]) #pd_main-header__entry, body:not([data-eccom-login]) #pd_main-header__info {
  visibility: hidden;
}

body[data-eccom-login='1'] #pd_main-header__navi-login, body:not([data-eccom-login='1']) #pd_main-header__navi-logout, body:not([data-eccom-login='1']) #pd_main-header__navi-mypage, body[data-eccom-login='1'] #pd_main-header__entry, body:not([data-eccom-login='1']) #pd_main-header__info, body[data-eccom-login='1'] #pd_main-header__navi-enrty {
  display: none !important;
}

.eccom-cart-mark:empty {
  display: none;
}

body:not([data-eccom-login]) .eccom-login, body:not([data-eccom-login]) .eccom-logout {
  visibility: hidden;
}

body[data-eccom-login='0'] .eccom-login {
  display: none !important;
}

body[data-eccom-login='1'] .eccom-logout {
  display: none !important;
}

body:not([data-eccom-login]) .pd_main-header-hum-menu__btnArea {
  display: none;
}

/* Body setting
------------------------------------------------------------------------------- */
html {
  font-size: 62.5%;
  /* ベース:10px */
}

/*
font-size(指定：例)

1.0rem = 10px; / 1.2rem = 12px; / 1.4rem = 14px; / 1.6rem = 16px; / 1.8rem = 18px; / 2.0rem = 20px;
*/
body {
  background: #434343;
 /* font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif; */ 
}

#page {
  max-width: inherit;
  background: #fff;
}

main {
 /* font-family: 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'ヒラギノ角ゴ W3', 'メイリオ', Meiryo, Osaka, 'sans-serif'; */ 
  color: #000000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  overflow-y: auto;
}

main * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#mobile-navigation {
  display: none;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, main {
  /*  font-family: 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif; */ 
  }

  /* IE11 ONLY */
}

#search-normal {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  body {
    padding-bottom: env(safe-area-inset-bottom);
  }

  #page {
    min-width: inherit;
  }

  main {
    min-width: inherit;
  }

  #globalheader {
    min-height: 44px;
  }

  #globalheader #brandlogo, #globalheader #brandlogo a {
    height: 44px;
  }

  #globalheader #brandlogo img {
    display: none;
  }

  #globalheader #brandlogo img.rwd {
    display: block;
    height: 44px;
  }

  #globalheader-nav {
    display: none;
  }

  #search-normal {
    display: none;
  }

  /* ------------- */
}

/* common style
------------------------------------------------------------------------------- */
.content {
  background: #fff;
  overflow: hidden;
}

.pd_content__inner {
  position: relative;
  max-width: 980px;
  padding: 0 2px 0 2px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
select::-ms-expand {
    display: none;
}
@media screen and (max-width: 640px) {
  .pd_content__inner {
    padding: 0 10px;
  }
}

.pd_content__description {
  margin-bottom: 20px;
  font-size: 1.6rem;
}

.pd_content__description span {
  font-size: 1.4rem;
}
.pd_img-center
  display: flex
  align-items: center
  justify-content: center
  img 
    height: auto
    width: auto

/* main-header
------------------------------------------------------------------------------- */
#pd_main-header {
  background: #D8D8D8;
  margin-bottom: 0px;
}

#pd_main-header #pd_main-header__inner {
  position: relative;
  margin: 0 auto;
  padding: 14px 2px 14px 2px;
  max-width: 980px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#pd_main-header__logo {
  width: 180px;
  height: 26px;
  position: absolute;
  top: 17px;
  left: 2px;
}

#pd_main-header__logo a {
  display: block;
  /* width: 146px; */
  height: 100%;
  background: url('/store/images/common/header_logo.png') 0 0 no-repeat;
  background-size: contain;
}

#pd_main-header__logo a span {
  display: none;
}

#pd_main-header__navi {
  position: relative;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  padding-right: 50px;
}

#pd_main-header__navi ul {
  min-width: 135px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 20px;
}

#pd_main-header__navi ul li {
  font-weight: bold;
  text-align: center;
}

#pd_main-header__navi ul li a {
  display: block;
  padding-top: 17px;
  color: #000;
  font-size: 1rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

#pd_main-header__navi ul #pd_main-header__navi-search {
  display: none;
}

#pd_main-header__navi ul #pd_main-header__navi-mypage {
  padding: 0 4px 0 0;
  margin: 0 4px 0 0;
  border-right: 1px solid #D1D1D1;
}

#pd_main-header__navi ul #pd_main-header__navi-mypage a {
  background: url('/store/images/common/icon_user_blue.svg') 50% 0 no-repeat;
  background-size: calc(12px * 1.4) calc(12px * 1.4);
}

#pd_main-header__navi ul #pd_main-header__navi-logout {
  padding: 0 6px 0 0;
  margin: 0 6px 0 0;
  border-right: 1px solid #D1D1D1;
}

#pd_main-header__navi ul #pd_main-header__navi-logout a {
  background: url('/store/images/common/icon_logout_blue.svg') 50% 0 no-repeat;
  background-size: calc(12px * 1.4) calc(12px * 1.4);
}

#pd_main-header__navi ul #pd_main-header__navi-login {
  padding: 0 4px 0 0;
  margin: 0 4px 0 0;
  border-right: 1px solid #D1D1D1;
}

#pd_main-header__navi ul #pd_main-header__navi-login a {
  background: url('/store/images/common/icon_login_blue.svg') 50% 0 no-repeat;
  background-size: calc(12px * 1.4) calc(12px * 1.4);
}

#pd_main-header__navi ul #pd_main-header__navi-favorite a {
  background: url('/store/images/common/icon_heart_blue.svg') 50% 0 no-repeat;
  background-size: calc(12px * 1.4) calc(12px * 1.4);
}

#pd_main-header__navi-cart {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
}

#pd_main-header__navi-cart a {
  position: relative;
  display: block;
  width: 36px;
  height: 36px;
  background: url('/store/images/common/header_cart_btn.svg') 0 0 no-repeat;
  background-size: 100% 100%;
}

#pd_main-header__navi-cart .pd_cart-num-mark {
  position: absolute;
  top: -5px;
  left: -3px;
}

.pd_cart-num-mark {
  display: block;
  overflow: visible;
  background: #D00000;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  color: #fff !important;
  font-size: 9px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  line-height: 1;
  padding-top: 4px;
}

#pd_main-header__info {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-width: calc(100% - 452px);
  margin: 0 8px 0 0px;
}

#pd_main-header__info.pd_sp-view {
  display: none !important;
}

#pd_main-header__info #pd_main-header__user-rank {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0 8px 0 27px;
  min-height: 24px;
  line-height: 24px;
}

/* #pd_main-header__info #pd_main-header__user-rank.pd_rank-entry {
  background: url(/store/images/common/icon_rank_entry.svg) 0 50% no-repeat;
  background-size: 24px 24px;
} */
#pd_main-header__info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #F2F2F2;
  border-radius: 20px;
  padding: 8px 8px;
  width: 424px;
  justify-content: space-around;
}

#pd_main-header__info ul li {
  line-height: 1.1;
  padding: 0px 8px 0 7px;
  border-right: 1px solid #D1D1D1;
  width: 100%;
}

#pd_main-header__info ul li:last-child {
  border-right: none;
}

#pd_main-header__info ul li dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 0 2px;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  justify-content: space-around;
}

#pd_main-header__info ul li dl dt {
  font-weight: normal;
  font-size: 1.2rem;
  white-space: nowrap;
  letter-spacing: 0.6px;
}

#pd_main-header__info ul li dl dd {
  color: #1876E9;
  padding-left: 3px;
  font-weight: bold;
  font-size: 1.2rem;
  white-space: nowrap;
}

#pd_main-header__info ul li dl dd span {
  font-size: 1.2rem;
}

#pd_main-header__entry {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

#pd_main-header__entry a {
  margin: 0 10px 0 0px;
  padding: 0 14px;
  background: #fff;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
  font-size: 1.3rem;
  color: #000;
  padding-left: 18px;
  padding-left: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  line-height: 1;
}

#pd_main-header__entry a::before {
  content: '';
  display: inline-block;
  width: 9.62px;
  height: 13.38px;
  background-image: url('/store/images/common/icon_entry.svg');
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  padding-left: 5px;
}

#pd_sp-rank-entry-list-wrap {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-header #pd_main-header__inner {
    padding: 12px 10px 10px 10px;
  }

  #pd_main-header__logo {
    width: 175px;
    height: 36px;
    top: 15px;
    left: 10px;
  }

  #pd_main-header__logo a {
    width: 120px;
    height: 100%;
  }

  #pd_main-header__navi {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    padding-right: 42px;
    margin: 0;
    margin-right: 30px
  }

  #pd_main-header__navi ul {
    min-width: inherit;
    padding: 5px;
  }

  #pd_main-header__navi ul li {
    line-height: 1;
  }

  #pd_main-header__navi ul li a {
    padding-top: 20px;
  }

  #pd_main-header__navi ul #pd_main-header__navi-mypage {
    padding: 0;
    margin: 0;
  }

  #pd_main-header__navi ul #pd_main-header__navi-search {
    display: block;
  }

  #pd_main-header__navi ul #pd_main-header__navi-search a {
    min-width: 32px;
    background: url('/store/images/common/icon_search_blue02.svg') 50% 0 no-repeat;
    background-size: calc(12px * 1.2) calc(12px * 1.2);
  }

  #pd_main-header__navi ul #pd_main-header__navi-search.pd_on a {
    background: url('/store/images/common/icon_close_blue.svg') 50% 0 no-repeat;
    background-size: calc(12px * 1.2) calc(12px * 1.2);
  }

  #pd_main-header__navi ul #pd_main-header__navi-enrty {
    padding: 0;
    margin: 0;
    border-right: 1px solid #D1D1D1;
  }

  #pd_main-header__navi ul #pd_main-header__navi-enrty a {
    background: url('/store/images/common/icon_entry.svg') 50% 0 no-repeat;
    background-size: calc(12px * 1.2) calc(12px * 1.2);
  }

  #pd_main-header__navi ul #pd_main-header__navi-logout {
    display: none;
  }

  #pd_main-header__navi ul #pd_main-header__navi-login {
    padding: 0;
    margin: 0;
  }

  #pd_main-header__navi-cart {
    top: 0;
    right: 0;
  }

  #pd_main-header__info {
    display: none;
  }

  /*
    #pd_main-header__info {
      margin: 0;
      position: absolute;
      top: 12px;
      right: 210px;
    }
    #pd_main-header__info #pd_main-header__user-rank {
      font-size: 0.9rem;
      padding: 21px 0 10px 0;
      min-height: 24px;
      min-width: 40px;
      line-height: 1.2;
      text-align: center;
    }
    #pd_main-header__info #pd_main-header__user-rank.pd_rank-entry {
      background: url(/store/images/common/icon_rank_entry.svg) 50% 0 no-repeat;
      background-size: 18px 18px;
      position: relative;
      overflow: hidden;
    }
    #pd_main-header__info #pd_main-header__user-rank.pd_rank-entry::after {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: auto;
      border-top: 1px solid #adadad;
      border-right: 1px solid #adadad;
      position: absolute;
      right: 0;
      bottom: 0px;
      left: 0;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      -webkit-transition: -webkit-transform .2s;
      transition: -webkit-transform .2s;
      transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s;
    }
    #pd_main-header__info #pd_main-header__user-rank.pd_rank-entry.pd_is-active::after {
      bottom: 3px;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
    }
    #pd_main-header__info ul {
      display: none;
      background: #fff;
      padding: 7px 7px;
    }
    #pd_main-header__info ul li {
      padding: 0px 5px;
    }
    #pd_main-header__info ul li dl {
      padding: 2px 0;
    }
    #pd_main-header__info ul li dl dt {
      font-size: 1.0rem;
      white-space: nowrap;
    }
    #pd_main-header__info ul li dl dd {
      padding-left: 1px;
      font-size: 1.1rem;
      white-space: nowrap;
    }
    #pd_main-header__info ul li dl dd span {
      font-size: 1.1rem;
    }
  */
  #pd_main-header__entry {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: calc(100% + 124px);
    background: #F7F7F7;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    min-width: inherit;
    margin: 12px -10px 0px -10px;
    padding: 10px;
  }

  #pd_sp-rank-entry-list-wrap {
    margin: auto;
    padding: 12px;
    position: absolute;
    right: 0;
    bottom: -59px;
    left: 0;
    z-index: 100;
    background-color: #f7f7f7;
  }

  #pd_sp-rank-entry-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 280px;
    margin: 0 auto;
    padding: 7px 7px;
    border-radius: 3px;
    background-color: #fff;
  }

  #pd_sp-rank-entry-list li {
    line-height: 1.1;
    padding: 0 10px;
    border-right: 1px solid #d1d1d1;
  }

  #pd_sp-rank-entry-list li:last-child {
    border-right: none;
  }

  #pd_sp-rank-entry-list li dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 3px 0;
  }

  #pd_sp-rank-entry-list li dl dt {
    font-weight: normal;
    font-size: 1.3rem;
    white-space: nowrap;
  }

  #pd_sp-rank-entry-list li dl dd {
    color: #1876E9;
    padding-left: 4px;
    font-weight: bold;
    font-size: 1.4rem;
    white-space: nowrap;
  }

  /* ------------- */
}

/* SP(iPhone 678 Plus等) */
@media screen and (max-width: 428px) {
  #pd_main-header__logo {
    width: 195px;
    top: 18px;
  }
}

/* SP(iPhone 678 Plus等) */
@media screen and (max-width: 425px) {
  #pd_main-header__logo {
    width: 195px;
    top: 17px;
  }
}

/* SP(iPhone 7 Plus + 等) */
@media screen and (max-width: 414px) {
  #pd_main-header__logo {
    width: 150px;
    top: 17px;
  }
}

/* SP(pixel等) */
@media screen and (max-width: 411px) {
  #pd_main-header__logo {
    width: 190px;
    top: 18px;
  }
}

/* SP(iPhone 12 + 等) */
@media screen and (max-width: 390px) {
  #pd_main-header__logo {
    width: 150px;
    top: 17px;
  }
}

/* SP(iPhone 678 + 等) */
@media screen and (max-width: 375px) {
  #pd_main-header__logo {
    width: 150px;
    top: 17px;
  }
}

/* SP(iPhone5) */
@media screen and (max-width: 320px) {
  #pd_main-header__logo {
    top: 20px;
    width: auto;
  }

  #pd_main-header__logo a {
    top: 18px;
    width: 110px;
    height: 26px;
  }

  #pd_main-header__navi {
    padding-right: 43px;
  }

  #pd_main-header__navi ul {
    padding: 5px 5px;
  }

  #pd_main-header__navi ul li {
    font-size: 0.8rem;
    font-weight: normal;
    letter-spacing: -0.05em;
  }

  #pd_main-header__navi ul li a {
    padding-left: 0;
  }

  /*
    #pd_main-header__info #pd_main-header__user-rank {
      font-size: 0.8rem;
    }
    #pd_main-header__info ul {
      padding: 7px 4px;
    }
    #pd_main-header__info ul li {
      padding: 0px 2px;
    }
    #pd_main-header__info ul li dl {
      padding: 2px 0;
    }
    #pd_main-header__info ul li dl dt {
      font-size: 0.8rem;
    }
    #pd_main-header__info ul li dl dd {
      font-size: 0.9rem;
    }
    #pd_main-header__info ul li dl dd span {
      font-size: 0.9rem;
    }
  */
  /* ------------- */
}

/* main-header-sub
------------------------------------------------------------------------------- */
#pd_main-header-sub, #pd_sp-main-header-sub {
  background: #F7F7F7;
  margin-bottom: 20px;
}

#pd_main-header-sub #pd_main-header-sub__inner, #pd_main-header-sub #pd_sp-main-header-sub__inner, #pd_sp-main-header-sub #pd_main-header-sub__inner, #pd_sp-main-header-sub #pd_sp-main-header-sub__inner {
  position: relative;
  margin: 0 auto;
  padding: 14px 2px 14px 2px;
  max-width: 980px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#pd_sp-main-header-sub {
  display: none;
}

#pd_main-header-sub__form, #pd_sp-main-header-sub__form {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  position: relative;
  border: 1px solid #1876E9;
  border-radius: 3px;
  width: 775px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

#pd_main-header-sub__form .pd_select-type-twoway, #pd_sp-main-header-sub__form .pd_select-type-twoway {
  background-color: #EDF4FF;
  border: none;
  border-radius: 3px 0 0 3px;
  min-width: 189px;
  width: 189px;
  height: 44px;
}

#pd_main-header-sub__form .pd_select-type-twoway-wrap, #pd_sp-main-header-sub__form .pd_select-type-twoway-wrap {
  border-right: 1px solid #1876E9;
  position: relative;
  min-width: 189px;
}

#pd_main-header-sub__form input[type="text"], #pd_sp-main-header-sub__form input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0 3px 3px 0;
  background: #fff;
  padding: 0px 50px 0px 13px;
  width: 100%;
  height: 44px;
  line-height: 1.3;
  border: none;
  outline: 0;
  font-size: 1.3rem;
}

#pd_main-header-sub__form input[type="text"]::-webkit-input-placeholder, #pd_sp-main-header-sub__form input[type="text"]::-webkit-input-placeholder {
  color: #ccc;
}

#pd_main-header-sub__form input[type="text"]:-ms-input-placeholder, #pd_sp-main-header-sub__form input[type="text"]:-ms-input-placeholder {
  color: #ccc;
}

#pd_main-header-sub__form input[type="text"]::-ms-input-placeholder, #pd_sp-main-header-sub__form input[type="text"]::-ms-input-placeholder {
  color: #ccc;
}

#pd_main-header-sub__form input[type="text"]::placeholder, #pd_sp-main-header-sub__form input[type="text"]::placeholder {
  color: #ccc;
}

#pd_main-header-sub__form button, #pd_sp-main-header-sub__form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 0;
  background: url('/store/images/common/icon_search_blue.svg') 50% calc(50% - 1px) no-repeat;
  background-size: 15px 15px;
}

#pd_main-header-sub__search {
  display: flex;
}

#pd_main-header-sub__search > li {
  background: #fff;
  height: 46px;
  min-width: 182px;
}

#pd_main-header-sub__search > li > a {
  cursor: default;
  display: block;
  position: relative;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  padding: 14px 27px 0 30px;
  line-height: 1.2;
  height: 46px;
  background: url('/store/images/common/select_arrow.svg') calc(100% - 11px) 20px no-repeat;
  background-size: 6.4px 4.3px;
  color: #000000;
  font-size: 1.4rem;
  white-space: nowrap;
}

#pd_main-header-sub__search li#pd_main-header-sub__search-category > a::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 23px;
  left: 13px;
  margin-top: -7px;
  width: 12px;
  height: 12px;
  background-image: url('/store/images/common/icon_category.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

#pd_main-header-sub__search li#pd_main-header-sub__search-category:hover #pd_main-header-sub__search-category-body {
  display: block;
}

#pd_main-header-sub__search li#pd_main-header-sub__search-series > a::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 23px;
  left: 13px;
  margin-top: -7px;
  width: 12px;
  height: 12px;
  background-image: url('/store/images/common/icon_series.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

#pd_main-header-sub__search li#pd_main-header-sub__search-series:hover #pd_main-header-sub__search-series-body {
  display: block;
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-header-wrap {
    position: relative;
  }

  #pd_main-header-sub, #pd_sp-main-header-sub {
    /*  */
    display: none;
    /*  */
    background: #fff;
    margin-bottom: 0px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  #pd_main-header-sub #pd_main-header-sub__inner, #pd_main-header-sub #pd_sp-main-header-sub__inner, #pd_sp-main-header-sub #pd_main-header-sub__inner, #pd_sp-main-header-sub #pd_sp-main-header-sub__inner {
    padding: 8px 10px 10px 10px;
    display: block;
  }

  #pd_sp-main-header-sub {
    display: block;
    position: static;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  #pd_main-header-sub__form, #pd_sp-main-header-sub__form {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
  }

  #pd_main-header-sub__form .pd_select-type-twoway-wrap, #pd_sp-main-header-sub__form .pd_select-type-twoway-wrap {
    min-width: 145px;
    height: 44px;
    overflow: hidden;
    border-radius: 3px 0 0 3px;
    background-color: #EDF4FF;
  }

  #pd_main-header-sub__form .pd_select-type-twoway, #pd_sp-main-header-sub__form .pd_select-type-twoway {
    /* 130%scale */
    padding-right: 27px;
    min-width: 145px;
    max-width: 190px;
    height: 57px;
    background-size: 8.3px 13px;
    background-position: calc(100% - 13px) 50%;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transform: scale(calc((1 / 1.3)));
    transform: scale(calc((1 / 1.3)));
  }

  #pd_main-header-sub__form input[type="text"], #pd_sp-main-header-sub__form input[type="text"] {
    padding: 0px 40px 0px 10px;
    font-size: 1.6rem;
  }

  #pd_main-header-sub__form input[type="text"]::-webkit-input-placeholder, #pd_sp-main-header-sub__form input[type="text"]::-webkit-input-placeholder {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  #pd_main-header-sub__form input[type="text"]:-ms-input-placeholder, #pd_sp-main-header-sub__form input[type="text"]:-ms-input-placeholder {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  #pd_main-header-sub__form input[type="text"]::-ms-input-placeholder, #pd_sp-main-header-sub__form input[type="text"]::-ms-input-placeholder {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  #pd_main-header-sub__form input[type="text"]::placeholder, #pd_sp-main-header-sub__form input[type="text"]::placeholder {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  #pd_main-header-sub__form button, #pd_sp-main-header-sub__form button {
    width: 35px;
  }

  #pd_sp-main-header-sub__form {
    margin-bottom: 0;
  }

  #pd_main-header-sub__search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    z-index: 10;
    margin-bottom: -1px;
  }

  #pd_main-header-sub__search > li {
    background: none;
    min-height: 46px;
    height: auto;
    border-radius: 3px 3px 0 0;
    min-width: inherit;
    margin-right: 0px;
    width: calc(50% - 4px);
    padding-bottom: 0px;
  }

  #pd_main-header-sub__search > li a {
    position: relative;
    z-index: 10;
  }

  #pd_main-header-sub__search > li.pd_on {
    border: 1px solid #CCCCCC;
    border-bottom: none;
    background: #F7F7F7;
  }

  #pd_main-header-sub__search > li.pd_on > a {
    border: none;
    height: 55px;
    padding-bottom: 7px;
    background-color: #F7F7F7;
  }

  #pd_main-header-sub__search li#pd_main-header-sub__search-category {
    margin-right: 8px;
  }

  /* ------------- */
}

/* pd_sp-main-header-hum
------------------------------------------------------------------------------- */
// .pd_sp-main-header-hum {
//   display: none;
// }

/* SP */
@media screen and (max-width: 640px) {
  .pd_sp-main-header-hum {
    display: block;
    width: 21px;
    height: 20px;
    margin: auto;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
  }

  .pd_sp-main-header-hum__item {
    width: 21px;
    height: 4px;
    border-radius: 20px;
    margin: auto;
    background-color: #747474;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .pd_sp-main-header-hum__item:nth-child(2) {
    bottom: 0;
  }

  .pd_sp-main-header-hum__item:last-child {
    top: auto;
    bottom: 0;
  }
}

/* pd_main-header-hum-menu
------------------------------------------------------------------------------- */
.pd_main-header-hum-menu {
  display: none;
}

.overlay {
  display: none;
}

.pd_rank-entry {
  padding: 8px 0 8px 28px;
  font-size: 1.4rem;
  font-weight: bold;
  background-image: url('/store/images/common/icon_entry_e.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 24px;
}

.pd_rank-bronze {
  padding: 8px 0 8px 28px;
  font-size: 1.4rem;
  font-weight: bold;
  background-image: url('/store/images/common/icon_bronze.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 24px;
}

.pd_rank-silver {
  padding: 8px 0 8px 28px;
  font-size: 1.4rem;
  font-weight: bold;
  background-image: url('/store/images/common/icon_silver.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 24px;
}

.pd_rank-gold {
  padding: 8px 0 8px 28px;
  font-size: 1.4rem;
  font-weight: bold;
  background-image: url('/store/images/common/icon_gold.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 24px;
}

@media screen and (max-width: 640px) {
  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;
  }

  .overlay.pd_open {
    display: block;
    opacity: 1;
  }

  .overlay.pd_is-pointNone {
    z-index: 1450;
    pointer-events: none;
  }

  .pd_main-header-hum-menu {
    display: block;
    width: 85%;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1550;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }

  .pd_main-header-hum-menu.pd_is-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .pd_main-header-hum-menu__close {
    display: none;
    width: 25px;
    height: 25px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1550;
  }

  .pd_main-header-hum-menu__close.pd_is-active {
    display: block;
  }

  .pd_main-header-hum-menu__close::before, .pd_main-header-hum-menu__close::after {
    content: '';
    display: inline-block;
    width: 25px;
    height: 4px;
    margin: auto;
    border-radius: 20px;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .pd_main-header-hum-menu__close::after {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .pd_main-header-hum-menu__btnArea {
    background-color: #d8d8d8;
  }

  .pd_main-header-hum-menu__btnArea__inner {
    // display: -webkit-box;
    // display: -ms-flexbox;
    display: flex;
    // -ms-flex-pack: distribute;
    // justify-content: space-around;
    // -webkit-box-align: center;
    // -ms-flex-align: center;
    // align-items: center;
    // -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
  }

  .pd_main-header-hum-menu__btnArea__inner #pd_main-header__hum-user-rank {
    font-size: 0.9rem;
    padding: 25px 0 0 0;
    min-height: 24px;
    min-width: 40px;
    line-height: 1.2;
    text-align: center;
  }

  .pd_main-header-hum-menu__btnArea__inner #pd_main-header__hum-user-rank.pd_rank-entry {
    background: url('/store/images/common/icon_rank_entry.svg') 50% 0 no-repeat;
    background-size: 23px 23px;
  }

  .pd_main-header-hum-menu__btnArea__inner #pd_main-header__hum-user-rank.pd_rank-bronze {
    background: url('/store/images/common/icon_bronze.svg') 50% 0 no-repeat;
    background-size: 23px 23px;
  }

  .pd_main-header-hum-menu__btnArea__inner #pd_main-header__hum-user-rank.pd_rank-silver {
    background: url('/store/images/common/icon_silver.svg') 50% 0 no-repeat;
    background-size: 23px 23px;
  }

  .pd_main-header-hum-menu__btnArea__inner #pd_main-header__hum-user-rank.pd_rank-gold {
    background: url('/store/images/common/icon_gold.svg') 50% 0 no-repeat;
    background-size: 23px 23px;
  }

  .pd_main-header-hum-menu__btnArea .pd_sp-hum-rank-entry-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 0 10px;
  }

  .pd_main-header-hum-menu__btnArea .pd_sp-hum-rank-entry-list li {
    line-height: 1.1;
    padding: 0 10px;
    border-right: 1px solid #fff;
  }

  .pd_main-header-hum-menu__btnArea .pd_sp-hum-rank-entry-list li:last-child {
    border-right: none;
  }

  .pd_main-header-hum-menu__btnArea .pd_sp-hum-rank-entry-list li dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 3px 0;
  }

  .pd_main-header-hum-menu__btnArea .pd_sp-hum-rank-entry-list li dl dt {
    font-weight: normal;
    font-size: 1.2rem;
    white-space: nowrap;
  }

  .pd_main-header-hum-menu__btnArea .pd_sp-hum-rank-entry-list li dl dd {
    color: #1876E9;
    padding-left: 4px;
    font-weight: bold;
    font-size: 1.2rem;
    white-space: nowrap;
  }

  .pd_main-header-hum-menu__btnArea__btn {
    width: 48%;
  }

  .pd_main-header-hum-menu__btnArea__btn .pd_btn {
    width: 100%;
    min-width: auto;
    border-color: #ccc;
    font-size: 1.1rem;
    font-weight: bold;
  }

  @media screen and (max-width: 320px) {
    .pd_main-header-hum-menu__btnArea__btn .pd_btn {
      padding: 10px;
    }
  }

  .pd_main-header-hum-menu__btnArea__btn .pd_icon-beginner {
    padding-top: 2px;
    padding-left: 17px;
    background-size: 11px;
  }

  .pd_main-header-hum-menu__btnArea__btn .pd_icon-mypage {
    display: inline-block;
    padding: 1px 0;
    padding-left: 15px;
    background: url('/store/images/common/icon_user_blue.svg') left 50% no-repeat;
    background-size: 11px;
  }

  .pd_main-header-hum-menu__btnArea__btn .pd_icon-login {
    display: inline-block;
    padding-top: 2px;
    padding-left: 15px;
    background: url('/store/images/common/icon_login_blue.svg') left 50% no-repeat;
    background-size: 11px;
  }

  .pd_main-header-hum-menu__btnArea__btn .pd_icon-logout {
    display: inline-block;
    padding-top: 2px;
    padding-left: 15px;
    background: url('/store/images/common/icon_logout_blue.svg') left 50% no-repeat;
    background-size: 11px;
  }

  .pd_main-header-hum-menu__btnArea__btn .pd_icon-span {
    font-size: 13px;
  }

  .pd_main-header-hum-menu__bottom {
    padding-bottom: 85px;
    overflow: hidden;
  }

  .pd_main-header-hum-menu__heading {
    padding: 10px;
    background-color: #f7f7f7;
  }

  .pd_main-header-hum-menu__item {
    border-top: 1px solid #e5e5e5;
  }

  .pd_main-header-hum-menu__item:last-child {
    border-bottom: 1px solid #e5e5e5;
  }

  .pd_main-header-hum-menu__item_heading {
    padding: 15px 30px 15px 10px;
    background-color: #fff;
    position: relative;
  }

  .pd_main-header-hum-menu__item_heading::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: auto;
    border-top: 1px solid #adadad;
    border-right: 1px solid #adadad;
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }

  .pd_main-header-hum-menu__item_heading.pd_is-active::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .pd_main-header-hum-menu__item_heading.pd_only_link {
    display: block;
    color: #000;
  }

  .pd_main-header-hum-menu__item_heading.pd_only_link::after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  body[data-eccom-login='1'] .pd_main-header-hum-menu__btnArea__btn {
    width: 39%;
  }

  .pd_main-header-in-menu-list {
    display: none;
    padding: 10px;
    background-color: #f7f7f7;
  }

  .pd_main-header-in-menu-list__item {
    padding: 10px;
    background-color: #fff;
  }

  .pd_main-header-in-menu-list__item + .pd_main-header-in-menu-list__item {
    margin-top: 6px;
  }

  .pd_main-header-in-menu-list__item_heading {
    padding: 5px 30px 5px 5px;
    font-size: 1.6rem;
    font-weight: bold;
    // background: url(/store/images/dummy/dummy_category01.png) no-repeat;
    background-position: center left;
    background-size: 37px 27px;
    position: relative;
  }

  .pd_main-header-in-menu-list__item_heading::before, .pd_main-header-in-menu-list__item_heading::after {
    content: '';
    display: block;
    width: 12px;
    height: 1px;
    margin: auto;
    background-color: #adadad;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .pd_main-header-in-menu-list__item_heading::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .pd_main-header-in-menu-list__item_heading.pd_is-active::after {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  .pd_main-header-category-list {
    display: none;
    margin-top: 10px;
  }

  .pd_main-header-category-list__item + .pd_main-header-category-list__item {
    margin-top: 20px;
  }

  .pd_main-header-category-list__link {
    color: #1876e9;
  }

  .pd_main-header-hum-menu__other {
    padding: 0 20px;
  }

  .pd_main-header-hum-menu__other_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .pd_main-header-hum-menu__other_item {
    margin: 20px 20px 0 0;
  }

  .pd_main-header-hum-menu__other_link {
    color: #1876e9;
  }
}

/* カテゴリから探す（main-header-sub__search-category-body） */
#pd_main-header-sub__search-category-body {
  display: none;
  /*  */
  position: absolute;
  top: calc(100% - 14px);
  z-index: 100;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 976px;
  height: 300px;
  border: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-category-body > ul {
  width: 240px;
  height: 300px;
  background: #F7F7F7;
  overflow: auto;
}

#pd_main-header-sub__search-category-body > ul > li {
  border-bottom: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-category-body > ul > li:last-child {
  border-bottom: none;
}

#pd_main-header-sub__search-category-body > ul > li > a {
  position: relative;
  display: block;
  padding: 8px 15px;
  color: #000;
  font-size: 1.4rem;
}

#pd_main-header-sub__search-category-body > ul > li dl {
  display: none;
  /*  */
  position: absolute;
  top: 0;
  left: 240px;
  width: calc(976px - 240px);
  height: 300px;
  overflow: auto;
  background: #fff;
  padding: 20px;
  border: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-category-body > ul > li dl dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

#pd_main-header-sub__search-category-body > ul > li dl dt figure {
  line-height: 0;
  width: 40px;
  display: none;
}

#pd_main-header-sub__search-category-body > ul > li dl dt strong {
  display: block;
  width: calc(100% - 40px);
  font-weight: bold;
  font-size: 1.8rem;
}

#pd_main-header-sub__search-category-body > ul > li dl dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#pd_main-header-sub__search-category-body > ul > li dl dd ul li {
  font-size: 1.4rem;
  width: 33.33%;
  padding-right: 10px;
  margin: 0 0 10px 0;
}

#pd_main-header-sub__search-category-body > ul > li.pd_on {
  background: #666666;
}

#pd_main-header-sub__search-category-body > ul > li.pd_on > a {
  color: #fff;
}

#pd_main-header-sub__search-category-body > ul > li.pd_on dl {
  display: block;
}

/* PC */
@media screen and (min-width: 641px) {
  #pd_main-header-sub__search-category-body > ul > li dl dd {
    display: block !important;
  }

  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-header-sub__search-category-body {
    position: absolute;
    z-index: 2;
    top: calc(100% - 4px) !important;
    left: 0 !important;
    /*
    margin-top: -1px;
    */
    margin-bottom: 8px;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: calc(100% + 20px);
    margin: 0px -10px 0;
    padding: 0 10px 10px;
    height: auto;
    border: none;
    min-width: inherit;
    max-height: inherit;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  #pd_main-header-sub__search-category-body > ul {
    border: 1px solid #CCCCCC;
    border-radius: 0px 3px 3px 3px;
    width: auto;
    height: auto;
    background: #F7F7F7;
    padding: 10px 10px 4px 10px;
  }

  #pd_main-header-sub__search-category-body > ul > li {
    border-bottom: none;
    margin-bottom: 6px;
    background: none !important;
  }

  #pd_main-header-sub__search-category-body > ul > li > a {
    display: none;
  }

  #pd_main-header-sub__search-category-body > ul > li dl {
    display: block !important;
    /*  */
    position: static;
    top: inherit;
    left: inherit;
    width: auto;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 0;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dt {
    cursor: pointer;
    /*  */
    padding: 13px 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0px;
    background: url('/store/images/common/icon_plus.svg') calc(100% - 10px) 50% no-repeat;
    background-size: 12px 12px;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dt.pd_open {
    background: url('/store/images/common/icon_minus.svg') calc(100% - 10px) 50% no-repeat;
    background-size: 12px 12px;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dt figure {
    width: 40px;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dt strong {
    width: calc(100% - 40px);
    padding-left: 12px;
    font-size: 1.6rem;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dd {
    display: none;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dd ul {
    display: block;
    padding: 5px 20px 10px;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dd ul li {
    font-size: 1.4rem;
    width: auto;
    padding-right: 0px;
    margin: 0 0 10px 0;
  }

  /* ------------- */
}

/* END カテゴリから探す（main-header-sub__search-category-body） */
/* シリーズから探す（main-header-sub__search-series-body） */
#pd_main-header-sub__search-series-body {
  display: none;
  /*  */
  position: absolute;
  top: calc(100% - 14px);
  z-index: 100;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-width: 240px;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-series-body ul li {
  border-bottom: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-series-body ul li:last-child {
  border-bottom: none;
}

#pd_main-header-sub__search-series-body ul li a {
  display: block;
  padding: 8px 15px;
  color: #000;
  font-size: 1.4rem;
  white-space: nowrap;
}

#pd_main-header-sub__search-series-body ul li a figure {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-header-sub__search-series-body {
    position: absolute;
    z-index: 2;
    top: calc(100% - 4px) !important;
    left: 0 !important;
    /*
    margin-top: -1px;
    */
    margin-bottom: 8px;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: calc(100% + 20px);
    margin: 0px -10px 0;
    padding: 0 10px 10px;
    height: auto;
    border: none;
    min-width: inherit;
    max-height: inherit;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  #pd_main-header-sub__search-series-body > ul {
    border: 1px solid #CCCCCC;
    border-radius: 3px 0px 3px 3px;
    width: auto;
    height: auto;
    background: #F7F7F7;
    padding: 10px 10px 4px 10px;
  }

  #pd_main-header-sub__search-series-body > ul li {
    border-bottom: none;
    margin-bottom: 6px;
  }

  #pd_main-header-sub__search-series-body > ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 13px 10px;
    font-weight: bold;
    font-size: 1.6rem;
    white-space: normal;
  }

  #pd_main-header-sub__search-series-body > ul li a figure {
    display: block;
    width: 40px;
    line-height: 0;
  }

  #pd_main-header-sub__search-series-body > ul li a span {
    display: block;
    width: calc(100% - 40px);
    padding-left: 12px;
  }

  /* ------------- */
}

/* END シリーズから探す（main-header-sub__search-series-body） */
/* bread-crumb
------------------------------------------------------------------------------- */
.pd_bread-crumb {
  font-size: 1.2rem;
  margin: 20px 0 35px;
}

.pd_bread-crumb ul {
  margin: 0 auto;
  max-width: 980px;
  padding: 0 2px 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #000000;
  line-height: 1.1;
}

.pd_bread-crumb ul li::before {
  content: '>';
  margin: 0 8px;
}

.pd_bread-crumb ul li:first-child::before {
  content: '';
  margin: 0;
}

.pd_bread-crumb ul a {
  color: #000000;
  text-decoration: underline;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_bread-crumb {
    display: none;
    margin: 50px 10px 0;
  }

  .pd_bread-crumb li {
    margin-bottom: 10px;
  }
}

/* container / main
------------------------------------------------------------------------------- */
/* main-footer
------------------------------------------------------------------------------- */
#pd_main-footer {
  margin-top: 90px;
  background: #434343;
}

#pd_main-footer #pd_main-footer__inner {
  margin: 0 auto;
  padding: 40px 2px 30px 2px;
  max-width: 980px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pd_main-footer__navi {
  width: 358px;
  padding-right: 5px;
}

.pd_main-footer__navi ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -10px;
}

.pd_main-footer__navi ul li {
  width: 50%;
  margin-bottom: 10px;
}

.pd_main-footer__navi ul li a {
  color: #fff;
}

.pd_main-footer__navi ul li a.pd_icon-otherlink {
  background: url('/store/images/common/icon_otherlink.svg') 100% 50% no-repeat;
  background-size: 10px 10px;
  padding-right: 16px;
}

.pd_main-footer__contact {
  width: 345px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 3px;
  text-align: center;
  padding: 10px 15px 5px;
  color: #fff;
}

.pd_main-footer__contact dl dt {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-btn {
  margin-bottom: 13px;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-btn a {
  display: block;
  background: #fff url('/store/images/common/icon_pc.svg') 15px 50% no-repeat;
  background-size: 13.7px 10px;
  color: #000;
  text-align: center;
  padding: 7px 0 0 17px;
  font-size: 1.2rem;
  font-weight: bold;
  min-height: 32px;
  border-radius: 16px;
  width: 190px;
  margin: 0 auto;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-tel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  flex-direction: column;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-tel a {
  width: 136px;
  display: inline-block;
  pointer-events: none;
  margin-bottom: 6px;
}

.pd_main-footer__contact dl dd .pd_main-footer__contact-tel span {
  font-size: 1.2rem;
  padding-left: 7px;
}

.pd_main-footer__other {
  width: 237px;
  margin-left: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_main-footer__other .pd_main-footer__other__sns {
  width: 44px;
  margin-right: 10px;
}

.pd_main-footer__other .pd_main-footer__other__sns li {
  line-height: 0;
}

.pd_main-footer__other .pd_main-footer__other__sns li a {
  display: block;
}

.pd_main-footer__other .pd_main-footer__other__security {
  width: 77px;
  margin-right: 10px;
  line-height: 0;
}

.pd_main-footer__other .pd_main-footer__other__eftc {
  width: 77px;
  line-height: 0;
}
.pd_main-footer__other .pd_main-footer__other__regular {
  width: 77px;
  line-height: 0;
}

.pd_main-footer__other .pd_main-footer__other__text {
  width: 100%;
  margin-top: 10px;
  font-size: 1.2rem;
}

.pd_main-footer__other .pd_main-footer__other__text a {
  color: #fff;
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-footer {
    margin-top: 30px;
  }

  #pd_main-footer #pd_main-footer__inner {
    padding: 25px 15px 20px 15px;
    display: block;
  }

  .pd_main-footer__navi {
    width: auto;
    padding-right: 0px;
  }

  .pd_main-footer__navi ul {
    margin-bottom: 0px;
    padding-bottom: 20px;
  }

  .pd_main-footer__navi ul li {
    width: 50%;
    margin-bottom: 10px;
    font-size: 1.3rem;
  }

  .pd_main-footer__contact {
    width: auto;
    padding: 10px 5px 10px;
    margin-bottom: 30px;
  }

  .pd_main-footer__contact dl dt {
    margin-bottom: 10px;
  }

  .pd_main-footer__contact dl dd .pd_main-footer__contact-btn {
    margin-bottom: 10px;
  }

  .pd_main-footer__contact dl dd .pd_main-footer__contact-tel a {
    pointer-events: auto;
  }

  .pd_main-footer__contact dl dd .pd_main-footer__contact-tel span {
    font-size: 1.2rem;
    padding-left: 5px;
    line-height: 1.6;
  }

  .pd_main-footer__other {
    width: auto;
    margin-left: 0px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .pd_main-footer__other .pd_main-footer__other__text {
    margin-top: 10px;
    text-align: center;
  }

  /* ------------- */
}

/* 共通parts */
/*
    File Name   : parts.scss
    Description : パーツ用のスタイル
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* style
----------------------------------------------------------- */
/* link
----------------------------------------------------------- */
.pd_link-defo {
  color: #333 !important;
}

.pd_link-underline {
  text-decoration: underline !important;
}

/* btn
----------------------------------------------------------- */
.pd_btn {
  display: inline-block;
  min-width: 200px;
  padding: 12px 25px;
  border-radius: 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.6rem;
  text-align: center;
  cursor: pointer;
}

.pd_btn-col01 {
  background: #fff;
  border: 1px solid #707070;
  color: #000000;
}

.pd_btn-col02 {
  background: #222222;
  border: 1px solid #222222;
  color: #fff;
}

.pd_btn-col03 {
  background: #003DA5;
  border: 1px solid #003DA5;
  color: #fff;
}

.pd_btn-col04 {
  background: #1876E9;
  border: 1px solid #1876E9;
  color: #fff;
}

.pd_btn-col05 {
  background: #B4B4B4;
  border: 1px solid #B4B4B4;
  color: #fff;
}

.pd_btn-center {
  margin: 0 auto;
}

@media screen and (max-width: 640px) {
  .pd_btn {
    padding: 12px 12px;
  }
}

.pd_btn-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.small .pd_btn-horizontal {
  flex-direction: column;
  justify-content: space-between;
}

.small .pd_btn-horizontal .pd_btn {
  margin-top: 20px;
}

.pd_btn-horizontal .pd_btn.pd_alertBtn {
  font-size: 14px;
  min-width: 0;
  margin: 15px auto 0;
  padding: 7px 22px;
}

.small .pd_btn-horizontal .pd_btn + .pd_btn {
  margin-top: 0;
}

.pd_btn-horizontal .pd_btn {
  margin: 0 10px;
}

.pd_btn-horizontal .pd_btn + .pd_btn {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

@media screen and (max-width: 640px) {
  .pd_btn-horizontal {
    display: block;
    text-align: center;
  }

  #DialogBtnConfirm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #DialogBtnConfirm .pd_btn {
    margin: 20px auto;
  }

  #DialogBtnConfirm .pd_btn + .pd_btn {
    margin: 0px auto;
  }

  .pd_btn-horizontal .pd_btn {
    margin: 0 auto;
  }

  .pd_btn-horizontal .pd_btn + .pd_btn {
    margin: 20px auto 0;
  }
}

.pd_btn-vertical {
  text-align: center;
}

.pd_btn-vertical .pd_btn, .pd_btn-vertical .pd_btn-wrap {
  margin: 0 auto;
}

.pd_btn-vertical .pd_btn + .pd_btn, .pd_btn-vertical .pd_btn + .pd_btn-wrap, .pd_btn-vertical .pd_btn-wrap + .pd_btn, .pd_btn-vertical .pd_btn-wrap + .pd_btn-wrap {
  margin: 20px auto 0;
}

.pd_icon-plus {
  display: inline-block;
  padding-left: 20px;
  background: url('/store/images/common/ion_plus_white.svg') left 50% no-repeat;
  background-size: 10px;
}

.pd_icon-cart {
  display: inline-block;
  padding-left: 20px;
  background: url('/store/images/common/icon_cart_white.svg') left 50% no-repeat;
  background-size: 14px;
}

/* title
----------------------------------------------------------- */
.pd_content__title {
  margin-bottom: 30px;
  font-size: 2.6rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_content__title {
    margin-top: 40px;
    line-height: 1.4;
  }
}

.pd_box {
  padding: 20px;
  font-size: 1.6rem;
}

.pd_box.pd_box-col02_2 {
  padding: 20px 0;
  font-size: 1.4rem;
}

.pd_box-col02_2 {
  background: #fff;
}

@media screen and (max-width: 640px) {
  .pd_box {
    padding: 15px 8px;
  }
}

.pd_box-col01 {
  background: #EDF4FF;
}

.pd_box-col02 {
  background: #FEEEEE;
}

.pd_box-col03 {
  background: #F7F7F7;
}

/* paging ページング
----------------------------------------------------------- */
.pd_pager-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px 0 55px;
}

.pd_pager-btn a {
  position: relative;
  display: block;
  background: #F2F2F2;
  min-width: 31px;
  height: 31px;
  border-radius: 4px;
  color: #000;
  text-align: center;
  border-radius: 50%;
}

.pd_pager-btn a span {
  display: none;
}

.pd_prev-btn a::before, .pd_next-btn a::after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
  height: 5px;
  margin: auto;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  content: '';
}

.pd_prev-btn a::before {
  left: 14px;
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
}

.pd_next-btn a::after {
  right: 15px;
}

.pd_pager-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 3px;
}

.pd_pager-list li {
  margin: 0 3px;
  min-width: 31px;
  text-align: center;
  line-height: 1;
  color: #666666;
}

.pd_pager-list li a {
  display: block;
  text-align: center;
  padding: 9px 3px 0;
  min-width: 31px;
  height: 31px;
  border-radius: 4px;
  color: #000;
  background: #F2F2F2;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.pd_pager-list li.pd_current a {
  color: #fff;
  background-color: #1876e9;
}

.pd_pager-count {
  font-size: 1.4rem;
  text-align: center;
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_pager-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0px 0 55px;
  }

  .pd_pager-btn {
    width: 140px;
    max-width: 40%;
  }

  .pd_pager-btn a {
    height: 44px;
    font-size: 1.4rem;
    padding-top: 12px;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
  }

  .pd_pager-btn a span {
    display: inline;
  }

  .pd_prev-btn a::before {
    left: 14px;
  }

  .pd_next-btn a::after {
    right: 15px;
  }

  .pd_pager-list {
    display: none;
  }

  .pd_pager-count {
    text-align: center;
    display: block;
  }

  /* ------------- */
}

/* List
----------------------------------------------------------- */
ul.pd_basic {
  list-style: disc;
  padding: 0 0 0 1em;
  margin-left: 5px;
}

ul.pd_basic li {
  margin-bottom: 1.5em;
}

/* box
----------------------------------------------------------- */
/* box-list-type01 */
@media screen and (min-width: 641px) {
  .pd_box-list-type01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pd_box-list-type01 li {
  width: 100%;
  max-width: 476px;
  margin-top: 20px;
  border: 1px solid #E5E5E5;
}

@media screen and (min-width: 641px) {
  .pd_box-list-type01 li:nth-child(-n+2) {
    margin-top: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_box-list-type01 li:first-child {
    margin-top: 0;
  }
}

.pd_box-list-type01__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 15px;
  border-bottom: 1px solid #E5E5E5;
  background: #F7F7F7;
}

@media screen and (max-width: 640px) {
  .pd_box-list-type01__head {
    padding: 10px;
  }
}

.pd_box-list-type01__head-active {
  background: #EDF4FF;
}

.pd_box-list-type01__head-active span {
  color: #003DA5;
}

.pd_box-list-type01__head-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.2rem;
}

.pd_box-list-type01__body {
  padding: 15px;
}

.pd_box-list-type01__body th, .pd_box-list-type01__body td, .pd_box-list-type01__body > div {
  padding-bottom: 5px;
  text-align: left;
}

.pd_box-list-type01__edit .pd_btn {
  min-width: 105px;
  padding: 5px 15px;
  font-size: 1.2rem;
}

.pd_box-list-type01__delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}

.pd_box-list-type01__delete .pd_icon-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
}

.pd_box-list-type01__delete a {
  color: #666666;
  font-size: 1rem;
  text-decoration: underline;
}

/* END box-list-type01 */
/* Table
----------------------------------------------------------- */
table {
  width: 100%;
  table-layout: fixed;
  text-align: left;
}

/* form 関係
----------------------------------------------------------- */
/* form パーツ関連 */
.pd_input-name {
  width: 165px;
}

@media screen and (max-width: 640px) {
  .pd_input-name {
    width: 100px;
  }
}

.pd_input-address {
  width: 125px;
}

.pd_input-city {
  width: 500px;
}

.pd_input-town {
  width: 500px;
}

.pd_input-apartment {
  width: 500px;
}

.pd_input-tel {
  width: 90px;
}

.pd_btn-address {
  min-width: auto;
  padding: 5px 10px;
  border: 17px;
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_select-prefecture {
  width: 188px;
}

.pd_btn-disable {
  background: #B4B4B4;
  color: #fff;
  border: none;
  pointer-events: none;
}

@media screen and (max-width: 640px) {
  .pd_input-city, .pd_input-town, .pd_input-apartment {
    width: 100%;
  }

  /* ------------- */
}

.pd_searchWrap {
  position: relative;
  z-index: 5;
  max-width: 1040px;
  margin: 165px auto 0;
  padding: 15px 30px 0 30px;
  background-color: rgba(255, 255, 255, 0.4);
}

/* END form パーツ関連 */
/* box-search-type01 */
.pd_box-search-type01 {
  max-width: 976px;
  margin: 0 auto;
  padding: 30px;
  background: #F7F7F7;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 25px;
}

@media screen and (max-width: 640px) {
  .pd_box-search-type01 {
    margin-bottom: 40px;
    padding: 30px 10px;
    font-size: 1.4rem;
  }
}

.pd_box-search-type01 .pd_btn {
  margin: 0 auto;
}

.pd_box-search__title {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.pd_box-search__itemWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}

.pd_box-search__year {
  width: 95px;
}

@media screen and (max-width: 640px) {
  .pd_box-search__year {
    width: 72px;
  }
}

@media screen and (max-width: 320px) {
  .pd_box-search__year {
    width: 87px;
  }
}

.pd_box-search__month {
  width: 70px;
}

@media screen and (max-width: 640px) {
  .pd_box-search__month {
    width: 50px;
  }
}

@media screen and (max-width: 320px) {
  .pd_box-search__month {
    width: 57px;
  }
}

/* END box-search-type01 */
/* textarea-type01 */
.pd_textarea-type01 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  background: transparent;
  font-size: 1.3rem;
  width: 100%;
  min-height: 7em;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_textarea-type01 {
    font-size: 1.6rem;
  }
}

/* END textarea-type01 */
/* select-type01 */
.pd_select-type01 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  padding: 0 27px 0 12px;
  height: 36px;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 1.2;
  background-color: #fff;
  background-image: url('/store/images/common/select_arrow.svg');
  background-position: calc(100% - 11px) 50%;
  background-repeat: no-repeat;
  background-size: 6.4px 4.3px;
  font-size: 1.3rem;
}

.pd_select-type01::-ms-expand {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_select-type01 {
    font-size: 1.6rem;
  }
}

/* END select-type01 */
/* select-type-twoway */
.pd_select-type-twoway {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  padding: 0 27px 0 12px;
  height: 36px;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 1.2;
  background-color: #fff;
  background-image: url('/store/images/common/select_arrow_twoway.svg');
  background-position: calc(100% - 11px) 50%;
  background-repeat: no-repeat;
  background-size: 6.4px 10.6px;
  font-size: 1.3rem;
}

.pd_select-type-twoway::-ms-expand {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_select-type-twoway {
    font-size: 1.6rem;
  }
}

/* END select-type-twoway */
/* custom-control ================= */
.pd_custom-control {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 25px;
  padding-left: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 5px;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .pd_custom-control {
    display: inline-block;
  }

  /* IE11 */
}

.pd_custom-control-input {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: -1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
}

.pd_custom-control-input[disabled] {
}

.pd_custom-control-indicator {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100% !important;
}

.pd_custom-control-description {
  display: block;
  margin-left: 5px;
}

/* custom-checkbox */
.pd_custom-control.pd_custom-checkbox {
  /*
  .pd_custom-control-input:focus {
      & ~ .pd_custom-control-indicator {
          outline: 3px dotted #5091FB;
      }
  }
  */
}

.pd_custom-control.pd_custom-checkbox .pd_custom-control-indicator {
  top: 50%;
  margin-top: -12px;
  background: url('/store/images/common/icon_check.svg') 50% 50% no-repeat;
  background-size: 100% 100%;
}

.pd_custom-control.pd_custom-checkbox .pd_custom-control-input:checked ~ .pd_custom-control-indicator {
  background: url('/store/images/common/icon_check_selected.svg') 50% 50% no-repeat;
  background-size: 100% 100%;
}

.pd_pressTabkey .pd_custom-control-input:focus ~ .pd_custom-control-indicator {
  outline-width: 3px;
  outline-style: dotted;
}

/* END custom-checkbox */
/* custom-radio */
.pd_custom-control.pd_custom-radio {
  /*
  .pd_custom-control-input:focus {
      & ~ .pd_custom-control-indicator {
          outline: 3px dotted #5091FB;
      }
  }
  */
}

.pd_custom-control.pd_custom-radio .pd_custom-control-indicator {
  top: 50%;
  margin-top: -12px;
  background: url('/store/images/common/icon_radio.svg') 50% 50% no-repeat;
  background-size: 100% 100%;
}

.pd_custom-control.pd_custom-radio .pd_custom-control-input:checked ~ .pd_custom-control-indicator {
  background: url('/store/images/common/icon_radio_selected.svg') 50% 50% no-repeat;
  background-size: 100% 100%;
}

.pd_custom-control.pd_custom-radio .pd_custom-control-input:disabled ~ .pd_custom-control-indicator {
  background: #B4B4B4;
  border-radius: 15px;
}

/* END custom-radio */
/* END custom-control ================= */
/* MODAL 関係
----------------------------------------------------------- */
.pd_contract {
  background: rgba(0, 0, 0, 0.4);
}

.pd_modal-bg {
  display: none;
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: auto;
}

.pd_modal-open {
  overflow: hidden;
  pointer-events: none;
}

.pd_modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3050;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow: auto;
  /*  */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 10px 20px 10px;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;
}

.pd_modal-content {
  position: relative;
  width: 100%;
  max-width: 976px;
  margin: auto;
}

.pd_modal-header {
  position: relative;
  width: 100%;
  padding: 35px 55px 0 55px;
  min-height: 80px;
  border-radius: 5px 5px 0 0;
  background: #fff;
  margin-bottom: -1px;
}

.pd_modal-header__ttl {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  color: #222;
  margin-bottom: 0px;
}

.pd_modal-header__close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
}

.pd_modal-header__close a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url('/store/images/common/icon_modal_close.svg') 50% 50% no-repeat;
  background-size: 20px 20px;
}

.pd_modal-body {
  position: relative;
  overflow: auto;
  padding: 0;
  border-radius: 0 0px 5px 5px;
  background: #fff;
  text-align: left;
  font-size: 1.4rem;
}

.pd_modal-body__inner {
  padding: 0 30px 30px;
  line-height: 1.5;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_modal-body__inner {
    padding: 0 20px 30px;
  }

  /* -------------- */
}

/* 商品一覧 */
/*
    File Name   : productlist.scss
    Description : 商品一覧 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* **.TITLE
----------------------------------------------------------- */
/* **.TITLE
----------------------------------------------------------- */
/* 商品詳細 */
/*
    File Name   : productdetail.scss
    Description : 商品詳細 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* **.TITLE
----------------------------------------------------------- */
/* **.TITLE
----------------------------------------------------------- */
/* マイページ系(注文・見積・契約など) */
/*
    File Name   : mypage01.scss
    Description : マイページ系(注文・見積・契約など) 用

    01.プラン選択条件一覧
    02.契約履歴一覧
    03.契約詳細
    04.注文履歴一覧

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* 01.プラン選択条件一覧
----------------------------------------------------------- */
/* refine-search */
.pd_refine-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #F7F7F7;
  padding: 25px 20px;
  font-size: 1.6rem;
  margin-bottom: 40px;
}

.pd_refine-search h2 {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.pd_refine-search dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_refine-search dl dt {
  font-weight: normal;
}

.pd_refine-search_item01 {
  margin-right: 20px;
}

.pd_refine-search_item01 dt {
  width: 76px;
}

.pd_refine-search_item01 dd {
  width: 160px;
}

.pd_refine-search_item01 dd .pd_input-type01 {
  width: 100%;
}

.pd_refine-search_item02 dt {
  width: 76px;
}

.pd_refine-search_item02 dd {
  width: 220px;
}

.pd_refine-search_item02 dd .pd_custom-control {
  margin: 0 10px 0 0;
}

.pd_refine-search_item03 dt {
  width: 93px;
}

.pd_refine-search_item03 dd {
  width: 287px;
}

.pd_refine-search_item03 dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_refine-search_item03 dd ul li .pd_select-type-twoway {
  width: 133px;
}

.pd_refine-search_item03 dd ul li:nth-of-type(2) {
  padding: 0 3px;
}

.pd_refine-search_item04 {
  margin-right: 20px;
}

.pd_refine-search_item04 dt {
  width: 76px;
}

.pd_refine-search_item04 dd {
  width: 488px;
}

.pd_refine-search_item04 dd .pd_input-type01 {
  width: 100%;
}

.pd_refine-search_item05 dt {
  width: 62px;
}

.pd_refine-search_item05 dd {
  width: 287px;
}

.pd_refine-search_item05 dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_refine-search_item05 dd ul li .pd_select-type-twoway {
  width: 133px;
}

.pd_refine-search_item05 dd ul li:nth-of-type(2) {
  padding: 0 3px;
}

.pd_refine-search_item06 {
  width: 100%;
  margin-top: 20px;
}

.pd_refine-search_item06 dt {
  width: 76px;
}

.pd_refine-search_item06 dd {
  width: calc(100% - 76px);
}

.pd_refine-search_item06 dd .pd_custom-control {
  margin: 0 10px 0 0;
}

.pd_refine-search_item07 {
  margin-right: 20px;
}

.pd_refine-search_item07 dt {
  width: 45px;
}

.pd_refine-search_item07 dd {
  width: 160px;
}

.pd_refine-search_item07 dd .pd_input-type01 {
  width: 100%;
}

.pd_refine-search_item08 {
  margin-right: 20px;
}

.pd_refine-search_item08 dt {
  width: 65px;
}

.pd_refine-search_item08 dd {
  width: 250px;
}

.pd_refine-search_item08 dd .pd_input-type01 {
  width: 100%;
}

.pd_refine-search_item09 dt {
  width: 85px;
}

.pd_refine-search_item09 dd {
  width: 287px;
}

.pd_refine-search_item09 dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_refine-search_item09 dd ul li .pd_select-type-twoway {
  width: 133px;
}

.pd_refine-search_item09 dd ul li:nth-of-type(2) {
  padding: 0 3px;
}

@media screen and (max-width: 640px) {
  .pd_refine-search_item09 .pd_delivery_preferred_date__calendar {
    width: 100%;
  }
}

.pd_refine-search_item09 .pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date {
  width: 135px;
}

@media screen and (max-width: 640px) {
  .pd_refine-search_item09 .pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date {
    width: 100%;
  }
}

.pd_refine-search__btn {
  width: 100%;
  padding-top: 25px;
  text-align: center;
}

.pd_refine-search__btn .pd_btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0 auto;
  width: 160px;
  min-width: 160px;
  font-size: 1.4rem;
  padding: 10px;
}

.pd_refine-search-no-item {
  background: #FEEEEE;
  padding: 17px 3px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #D00000;
  text-align: center;
  margin: 40px 0 45px;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_refine-search {
    display: block;
    padding: 25px 10px 30px;
    margin: 0 -10px 30px;
  }

  .pd_refine-search h2 {
    margin-bottom: 15px;
  }

  .pd_refine-search dl {
    display: block;
    margin: 0 0 15px 0;
  }

  .pd_refine-search dl dt {
    width: auto !important;
    margin-bottom: 5px;
  }

  .pd_refine-search dl dd {
    width: auto !important;
  }

  .pd_refine-search_item02 dd .pd_custom-control {
    width: 48.5%;
    margin: 0 0px 0 0;
  }

  .pd_refine-search_item03 dd ul, .pd_refine-search_item05 dd ul, .pd_refine-search_item09 dd ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .pd_refine-search_item03 dd ul li, .pd_refine-search_item05 dd ul li, .pd_refine-search_item09 dd ul li {
    max-width: 45%;
  }

  .pd_refine-search_item03 dd ul li .pd_select-type-twoway, .pd_refine-search_item05 dd ul li .pd_select-type-twoway, .pd_refine-search_item09 dd ul li .pd_select-type-twoway {
    width: 200px;
    max-width: 100%;
  }

  .pd_refine-search_item03 dd ul li:nth-of-type(2), .pd_refine-search_item05 dd ul li:nth-of-type(2), .pd_refine-search_item09 dd ul li:nth-of-type(2) {
    padding: 0 3px;
  }

  .pd_refine-search_item06 dd .pd_custom-control {
    width: 48.5%;
    margin: 0 0px 20px 0;
  }

  .pd_refine-search__btn {
    padding-top: 15px;
  }

  .pd_refine-search_item06 + .pd_refine-search__btn {
    margin-top: -5px;
    padding-top: 0;
  }

  .pd_refine-search-no-item {
    margin: 50px 0 60px;
  }

  /* ------------- */
}

/* END refine-search */
/* item-search-count-wrap */
.pd_item-search-count-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0;
}

.pd_item-search-count {
  color: #666666;
  padding-right: 10px;
}

.pd_item-search-viewnum {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_item-search-viewnum span {
  color: #666666;
  padding-right: 10px;
}

.pd_item-search-viewnum .pd_select-type01 {
  width: 84px;
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* END item-search-count-wrap */
/* plan-choice-list */
.pd_plan-choice-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.pd_plan-choice-list > li {
  width: calc(50% - 10px);
  margin: 0 20px 20px 0;
  border: 1px solid #E5E5E5;
  background: #fff;
}

.pd_plan-choice-list > li:nth-of-type(2n) {
  margin-right: 0;
}

.pd_plan-choice-list__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 7px 20px;
  background: #F7F7F7;
  border-bottom: 1px solid #E5E5E5;
}

.pd_plan-choice-list__header dt {
  font-weight: normal;
}

.pd_plan-choice-list__header dd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pd_plan-choice-list__header dd a.pd_plan-choice-list__delete-btn {
  display: inline-block;
  padding: 2px 2px 2px 20px;
  min-height: 16px;
  color: #666666;
  text-decoration: underline;
  font-size: 1rem;
  background: url('/store/images/common/icon_trash.svg') 0 50% no-repeat;
  background-size: 15px 16px;
}

.pd_plan-choice-list__header dd a.pd_plan-choice-list__detail-btn {
  margin-left: 13px;
  display: block;
  width: 104px;
  text-align: center;
  height: 34px;
  padding-top: 7px;
  border-radius: 17px;
  border: 1px solid #CCCCCC;
  background: #fff;
  font-size: 1.2rem;
  color: #222222;
}

.pd_plan-choice-list__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 20px 15px;
}

.pd_plan-choice-list__footer span {
  display: block;
}

.pd_plan-choice-list__footer strong {
  display: block;
  color: #D00000;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_plan-choice-list {
    display: block;
    padding-bottom: 0px;
  }

  .pd_plan-choice-list > li {
    width: auto;
    margin: 0 0px 30px 0;
  }

  .pd_plan-choice-list__header {
    padding: 10px 20px;
    display: block;
  }

  .pd_plan-choice-list__header dt {
    margin-bottom: 3px;
  }

  .pd_plan-choice-list__footer {
    display: block;
    padding: 0px 20px 15px;
  }

  .pd_plan-choice-list__footer span {
    margin-bottom: 3px;
  }

  /* ------------- */
}

/* agreement-readbox */
.pd_agreement-readbox {
  margin-bottom: -90px;
  margin-top: 50px;
  padding: 50px 0 90px;
  background: #F7F7F7;
}

.pd_agreement-readbox__title {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.pd_agreement-readbox__description {
  margin-bottom: 20px;
  text-align: center;
}

.pd_agreement-readbox__text {
  max-height: 335px;
  margin-bottom: 40px;
  padding: 20px;
  overflow-y: scroll;
  background: #fff;
  border: 1px solid #E5E5E5;
}

.pd_check-agreement-wrap {
  text-align: center;
  margin-bottom: 40px;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_agreement-readbox {
    margin-bottom: 0px;
    padding: 50px 0 40px;
  }

  .pd_agreement-readbox__text {
    padding: 20px 15px;
    margin-bottom: 30px;
  }

  .pd_check-agreement-wrap {
    text-align: left;
    margin: 0 20px 20px;
  }

  /* ------------- */
}

/* END agreement-readbox */
/* no-continue-modal__total-value */
.pd_no-continue-modal__total-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin: -20px 0 40px;
  line-height: 1.2;
}

.pd_no-continue-modal__total-value dt {
  font-size: 1.8rem;
  margin: 0 10px;
}

.pd_no-continue-modal__total-value dd {
  font-size: 1.6rem;
  line-height: 1;
  margin: 0 10px;
}

.pd_no-continue-modal__total-value dd strong {
  font-size: 2.8rem;
  font-weight: bold;
}

.pd_no-continue-modal__total-value dd.pd_no-continue-modal__total-value__atten {
  font-size: 1.4rem;
  color: #D00000;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_no-continue-modal__total-value {
    margin: -20px 0 25px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .pd_no-continue-modal__total-value dt {
    margin: 0 20px 0 0;
  }

  .pd_no-continue-modal__total-value dd {
    margin: 0px;
  }

  .pd_no-continue-modal__total-value dd.pd_no-continue-modal__total-value__atten {
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }

  /* ------------- */
}

/* END no-continue-modal__total-value */
/* continue-plan-list */
.pd_continue-plan-list > li {
  border: 1px solid #E5E5E5;
  background: #fff;
  margin-bottom: 20px;
}

.pd_continue-plan-list > li a {
  display: block;
  color: #000;
  text-decoration: none !important;
  padding: 20px 20px 15px 20px;
}

.pd_continue-plan-list > li a dl dt {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.pd_continue-plan-list > li a dl dd p {
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.pd_continue-plan-list > li a dl dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pd_continue-plan-list > li a dl dd ul li {
  font-size: 1.6rem;
  margin-left: 45px;
  text-align: right;
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_continue-plan-list > li a dl dd ul {
    display: block;
  }

  .pd_continue-plan-list > li a dl dd ul li {
    margin-left: 0px;
  }

  /* ------------- */
}

/* END continue-plan-list */
/* 04.注文履歴一覧
----------------------------------------------------------- */
.pd_order_history {
  margin-top: 20px;
  margin-bottom: 60px;
}

.pd_order_history__item:nth-child(n+2) {
  margin-top: 20px;
}

.pd_order_history__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 21px 20px;
  border: 1px solid #c7c7c7;
  background-color: #e0e0e0;
}

@media screen and (max-width: 640px) {
  .pd_order_history__top {
    padding: 20px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

.pd_order_history__top_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 640px) {
  .pd_order_history__top_left {
    display: block;
  }
}

.pd_order_history__top_left .pd_shipment_status, .pd_order_history__top_left .pd_shipment_status--red, .pd_order_history__top_left .pd_shipment_status--green, .pd_order_history__top_left .pd_shipment_status--blue, .pd_order_history__top_left .pd_shipment_status--gray {
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_order_history__top_left .pd_shipment_status--red {
  color: #d00000;
}

.pd_order_history__top_left .pd_shipment_status--green {
  color: #158900;
}

.pd_order_history__top_left .pd_shipment_status--blue {
  color: #003da5;
}

.pd_order_history__top_left .pd_shipment_status--gray {
  color: #666;
}

.pd_order_history__top_left .pd_order_date {
  margin-left: 40px;
}

@media screen and (max-width: 640px) {
  .pd_order_history__top_left .pd_order_date {
    margin-left: 0;
  }
}

.pd_order_history__top_left .pd_order_number {
  margin-left: 40px;
}

@media screen and (max-width: 640px) {
  .pd_order_history__top_left .pd_order_number {
    margin-left: 0;
  }
}

.pd_order_history__content {
  padding: 21px 20px;
  border: 1px solid #e5e5e5;
  border-top: transparent;
}

.pd_order_history__content_top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top {
    display: block;
  }
}

.pd_order_history__content_top + .pd_order_history__content_top {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

.pd_order_history__content_top_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top_left {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

.pd_order_history__content_top_left_sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  width: 100%;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top_left_sub {
    display: block;
    padding: 5px 0;
  }
}

.pd_order_history__content_top_left_img {
  min-width: 140px;
}

.pd_order_history__content_top_left_name {
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top_left_price {
    margin-top: 5px;
  }
}

.pd_order_history__content_top_left_price .pd_fs_large {
  font-size: 1.4rem;
}

.pd_order_history__content_top_right .pd_btn-horizontal {
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_top_right .pd_btn-horizontal {
    margin-top: 15px;
    margin-left: 0;
    text-align: right;
  }
}

.pd_order_history__content_top_right .pd_btn-horizontal .pd_btn {
  min-width: 170px;
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
}

.pd_order_history__content_bottom {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
  text-align: right;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_bottom_return_period {
    margin-bottom: 15px;
  }
}

.pd_order_history__content_bottom_payment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_order_history__content_bottom_payment {
    display: block;
  }
}

.pd_order_history__content_bottom_payment .pd_not_payment {
  display: inline-block;
  margin-right: 10px;
  color: #d00000;
}

.pd_order_history__content_bottom_payment .pd_fs_large {
  font-size: 1.8rem;
}

/* 05.注文内容
----------------------------------------------------------- */
/* orderdetail__contents */
.pd_orderdetail__contents .pd_cartproduct__addoptionitem_dt span {
  padding-left: 1em;
}

.pd_orderdetail__contents .pd_cartproduct__price_withTax:after {
  color: #000000;
  content: '★';
}

.pd_orderdetail__contents .pd_orderdetail-otherinfo {
  border-top: 1px solid #e5e5e5;
}

.pd_orderdetail__contents .pd_orderdetail_value__total_summary {
  max-width: none;
}

.pd_orderdetail__contents .pd_orderdetail_value__total_inner {
  width: auto;
}

.pd_orderdetail__contents .pd_cartproduct__quantity_and_price {
  padding-top: 10px;
}

.pd_orderdetail__contents .pd_orderdetail_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 50px;
}

.pd_orderdetail__contents .pd_orderdetail_info td a {
  margin-left: -5px;
  color: #1876E9;
}

.pd_orderdetail__contents .pd_orderdetail_info p {
  line-height: 2;
}

.pd_orderdetail__contents .pd_remarks {
  margin-top: 10px;
}

.pd_orderdetail__contents .pd_remarks span {
  margin-left: auto;
}

.pd_orderdetail__contents .pd_contract-otherinfo-user {
  border-bottom: 1px solid #E5E5E5;
}

.pd_orderdetail__contents .pd_cartproduct {
  width: 100%;
}

// .pd_orderdetail__contents .pd_cartproduct__top {
// display: grid;
// display: -ms-grid;
// grid-template-columns: 140px 1fr;
// grid-template-rows: auto auto;
// -ms-grid-columns: 140px 1fr;
// -ms-grid-rows: auto auto;
// }
.pd_orderdetail__contents .pd_cartproduct__img {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
}

.pd_orderdetail__contents .pd_cartproduct__set {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
}

.pd_orderdetail__contents .pd_cartproduct__set_addblock {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  width: 100%;
  padding-left: 20px;
}

.pd_orderdetail__contents .pd_cartproduct__addoption {
  margin-top: 0;
  margin-bottom: 0;
}

.pd_orderdetail__contents .pd_cartproduct__price .pd_t-col02, .pd_orderdetail__contents .pd_cartproduct__price--red .pd_t-col02, .pd_orderdetail__contents .pd_cartproduct__price--reset_line .pd_t-col02 {
  color: #000;
}

.pd_orderdetail__contents .pd_cartproduct__price.pd_bold, .pd_orderdetail__contents .pd_bold.pd_cartproduct__price--red, .pd_orderdetail__contents .pd_bold.pd_cartproduct__price--reset_line {
  margin-bottom: 0;
  margin-top: -4px;
  font-size: 1.8rem;
}

.pd_orderdetail__contents .pd_cartproduct__price.pd_bold span, .pd_orderdetail__contents .pd_bold.pd_cartproduct__price--red span, .pd_orderdetail__contents .pd_bold.pd_cartproduct__price--reset_line span {
  font-size: 1.4rem;
}

.pd_orderdetail__contents .pd_cartproduct__bottom {
  margin-top: 20px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem {
  margin-top: 10px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_btn {
  padding: 12px 43px;
  font-size: 1.4rem;
  line-height: 1.2;
  min-width: unset;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem span {
  display: inline-block;
  padding-left: 20px;
  background: url('/store/images/common/icon_buy_later.svg') left 50% no-repeat;
  background-size: 14px;
  color: #1876e9;
  margin-left: 20px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-review {
  background: url('/store/images/common/icon_pen_blue.svg') 1px 50% no-repeat;
  background-size: 12px 12px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-repair {
  background: url('/store/images/common/icon_settings_blue.svg') 0 50% no-repeat;
  background-size: 14px 14px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-contact {
  background: url('/store/images/common/icon_message_blue.svg') 0 50% no-repeat;
  background-size: 15.5px 14px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-receipt {
  background: url('/store/images/common/icon_printer_blue.svg') 0 50% no-repeat;
  background-size: 14px 14px;
}

.pd_orderdetail__contents .pd_cartproduct__choiceitem .pd_icon-return {
  background: url('/store/images/common/icon_return_blue.svg') 0 50% no-repeat;
  background-size: 14px auto;
}

.pd_orderdetail_value {
  background: #F7F7F7;
  padding: 30px 20px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.pd_orderdetail_value__val {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 31%;
  height: 100%;
}

.pd_orderdetail_value__val dt, .pd_orderdetail_value__val dd {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.6rem;
}

.pd_orderdetail_value__val dt:not(:nth-of-type(1)), .pd_orderdetail_value__val dd:not(:nth-of-type(1)) {
  margin-top: 5px;
}

.pd_orderdetail_value__val dt strong, .pd_orderdetail_value__val dd strong {
  font-size: 1.8rem;
}

.pd_orderdetail_value__val dt span, .pd_orderdetail_value__val dd span {
  font-size: 1.4rem;
}

.pd_orderdetail_value__val dt a, .pd_orderdetail_value__val dd a {
  color: #003DA5;
}

.pd_orderdetail_value__val dt a:hover, .pd_orderdetail_value__val dd a:hover {
  text-decoration: none;
}

.pd_orderdetail_value__val dt {
  text-align: left;
  width: 55%;
}

.pd_orderdetail_value__val dd {
  text-align: right;
  width: 45%;
}

.pd_orderdetail_value__val:not(:nth-of-type(1)) {
  margin-left: 120px;
}

.pd_orderdetail_value__total {
  border-top: 1px solid #DEDEDE;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pd_orderdetail_value__total_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 60%;
}

.pd_orderdetail_value__total-val {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.pd_orderdetail_value__total-val dt {
  font-size: 1.8rem;
  font-weight: normal;
  margin-right: 40px;
}

.pd_orderdetail_value__total-val dt span {
  font-size: 1.4rem;
}

.pd_orderdetail_value__total-val dd {
  line-height: 1.3;
}

.pd_orderdetail_value__total-val dd strong {
  font-size: 2.8rem;
  font-weight: bold;
}

.pd_orderdetail_value__total-val dd span {
  font-size: 1.6rem;
}

.pd_orderdetail_value__total-val dd em {
  font-size: 1.4rem;
  font-weight: normal;
  padding-left: 10px;
}

.pd_orderdetail_value__total_summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 30%;
  height: 100%;
  margin-left: 2em;
}

.pd_orderdetail_value__total_summary dt, .pd_orderdetail_value__total_summary dd {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.pd_orderdetail_value__total_summary dt:not(:nth-of-type(1)), .pd_orderdetail_value__total_summary dd:not(:nth-of-type(1)) {
  margin-top: 5px;
}

.pd_orderdetail_value__total_summary dt {
  text-align: left;
}

.pd_orderdetail_value__total_summary dt::before {
  content: '[';
  padding-right: 0.4rem;
}

.pd_orderdetail_value__total_summary dd {
  text-align: right;
}

.pd_orderdetail_value__total_summary dd:after {
  content: ']';
  padding-left: 0.4rem;
}

.pd_orderdetail_value__total_misc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 30%;
}

.pd_orderdetail_value__total_misc_main {
  background: #fff;
  line-height: 27px;
  display: inline-block;
  font-size: 1.4rem;
  -ms-flex-preferred-size: 217px;
  flex-basis: 217px;
  text-align: center;
  color: #D00000;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.pd_orderdetail_value__total_misc_main span {
  padding-left: 15px;
}

.pd_orderdetail_value__total_misc_sub {
  font-size: 1.2rem;
  padding-top: 2px;
}

.pd_orderdetail-otherinfo_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_contract-otherinfo__item_dist {
  width: 50%;
  margin-bottom: 35px;
  border: 1px solid #E5E5E5;
  padding: 20px;
}

.pd_contract-otherinfo__item_dist dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_contract-otherinfo__item_dist dt, .pd_contract-otherinfo__item_dist dd {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.pd_contract-otherinfo__item_dist dt:not(:nth-of-type(1)), .pd_contract-otherinfo__item_dist dd:not(:nth-of-type(1)) {
  margin-top: 5px;
}

.pd_contract-otherinfo__item_dist dt {
  width: 40%;
}

.pd_contract-otherinfo__item_dist dd {
  width: 60%;
  padding-left: 1rem;
  text-indent: -1rem;
}

.pd_contract-otherinfo__item_dist dd:before {
  content: ':';
  padding-right: 1rem;
}

.pd_contract-otherinfo__item_dist dd a {
  color: #1876E9;
}

.pd_orderdetail_value.short {
  padding: 30px 20px;
}

.pd_orderdetail_value.short .pd_orderdetail_value__total, .pd_orderdetail_value.short .pd_orderdetail_value__val, .pd_orderdetail_value.short dt, .pd_orderdetail_value.short dd {
  display: inline-block;
  width: auto;
  max-width: none;
  margin: 0;
}

.pd_orderdetail_value.short dd {
  margin-left: 30px;
}

.pd_orderdetail_value.short .pd_orderdetail_value__val:not(:nth-of-type(1)) {
  margin-left: 50px;
}

.pd_orderdetail_value.short .pd_orderdetail_value__total {
  width: 100%;
  margin-top: 20px;
}

.pd_orderdetail_value.short .pd_orderdetail_value__total_summary {
  margin-left: 2rem;
  height: auto;
  max-width: none;
  display: inline-block;
}

.pd_orderdetail_value.short .pd_orderdetail_value__total_summary:not(:nth-of-type(2)) {
  margin-left: 0;
}

@media screen and (min-width: 641px) {
  .pd_orderdetail_value__total_summary dd {
    padding-left: 1rem;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total-val {
    display: inline-block;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dt, .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dd {
    display: inline-block;
    width: auto;
    max-width: none;
    margin: 0;
  }
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_orderdetail__contents .pd_contract-info__link {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .pd_orderdetail__contents .pd_contract-info__link li {
    margin-left: 19px;
  }

  .pd_orderdetail__contents .pd_cartproduct__price_withTax {
    display: block;
  }

  .pd_orderdetail__contents .pd_cartproduct__top {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 140px 1fr;
    grid-template-rows: auto auto;
    -ms-grid-columns: 140px 1fr;
    -ms-grid-rows: auto auto;
  }

  .pd_orderdetail__contents .pd_cartproduct__img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    padding-right: 0;
  }

  .pd_orderdetail__contents .pd_cartproduct__set {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    padding-left: 20px;
  }

  .pd_orderdetail__contents .pd_cartproduct__set .pd_cartproduct__price, .pd_orderdetail__contents .pd_cartproduct__set .pd_cartproduct__price--red, .pd_orderdetail__contents .pd_cartproduct__set .pd_cartproduct__price--reset_line {
    font-weight: normal;
  }

  .pd_orderdetail__contents .pd_cartproduct__set_addblock {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 2;
    -ms-grid-colomn-span: 2;
    -ms-grid-row: 2;
    width: 100%;
    padding-left: 0;
  }

  .pd_orderdetail__contents .pd_cartproduct__price, .pd_orderdetail__contents .pd_cartproduct__price--red, .pd_orderdetail__contents .pd_cartproduct__price--reset_line {
    margin-top: 20px;
  }

  .pd_orderdetail__contents .pd_contract-otherinfo__item table th, .pd_orderdetail__contents .pd_contract-otherinfo__item table td {
    white-space: nowrap;
  }

  .pd_orderdetail__contents .pd_contract-info {
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  .pd_orderdetail__contents .pd_orderdetail_value__total_inner {
    width: 100%;
  }

  .pd_orderdetail__contents .pd_remarks span {
    font-size: 1.2rem;
  }

  .pd_orderdetail__contents .pd_orderdetail_info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 30px 0;
  }

  .pd_orderdetail__contents .pd_cartproduct__img_sp {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__img {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
  }

  .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__set_name {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
  }

  .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__price, .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__price--red, .pd_orderdetail__contents .pd_cartproduct__img_sp .pd_cartproduct__price--reset_line {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    margin-left: 20px;
  }

  .pd_orderdetail__contents .pd_cartproduct__choicelist {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-right: 10px;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    -ms-grid-columns: 1fr 106px;
    grid-template-columns: 1fr 106px;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) a {
    text-align: right;
    padding: 10px 0;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) .pd_cartproduct__choiceitem_a:nth-of-type(1) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) .pd_cartproduct__choiceitem_a:nth-of-type(2) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
  }

  .pd_orderdetail__contents .pd_cartproduct__choiceitem:nth-of-type(1) .pd_cartproduct__choiceitem_a:nth-of-type(3) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
  }

  .pd_orderdetail__contents .pd_cartproduct__quantity_and_price {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .pd_orderdetail__contents .pd_cartproduct__quantity_and_price .pd_cartproduct__price, .pd_orderdetail__contents .pd_cartproduct__quantity_and_price .pd_cartproduct__price--red, .pd_orderdetail__contents .pd_cartproduct__quantity_and_price .pd_cartproduct__price--reset_line {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pd_orderdetail_value {
    padding: 25px 20px 30px;
  }

  .pd_orderdetail_value__val {
    width: 100%;
  }

  .pd_orderdetail_value__val dt, .pd_orderdetail_value__val dd {
    margin-top: 5px;
  }

  .pd_orderdetail_value__val dt strong, .pd_orderdetail_value__val dd strong {
    font-size: 1.6rem;
  }

  .pd_orderdetail_value__val dt {
    width: 50%;
  }

  .pd_orderdetail_value__val dd {
    width: 50%;
  }

  .pd_orderdetail_value__val:not(:nth-of-type(1)) {
    margin-left: 0;
  }

  .pd_orderdetail_value__val dt:not(:nth-of-type(1)), .pd_orderdetail_value__val dd:not(:nth-of-type(1)) {
    margin-top: 5px;
  }

  .pd_orderdetail_value__total_inner {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .pd_orderdetail_value__total_summary_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .pd_orderdetail_value__total-val {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }

  .pd_orderdetail_value__total-val dt {
    margin: 0 0 10px 0;
  }

  .pd_orderdetail_value__total {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .pd_orderdetail_value__total_summary_wrap {
    margin-top: 10px;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary, .pd_orderdetail_value__total_summary {
    max-width: none;
    width: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 5px;
    margin-left: 0;
    text-align: right;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dt:not(:nth-of-type(1)), .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dd:not(:nth-of-type(1)), .pd_orderdetail_value__total_summary dt:not(:nth-of-type(1)), .pd_orderdetail_value__total_summary dd:not(:nth-of-type(1)) {
    margin-top: 0px;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dt, .pd_orderdetail_value__total_summary dt {
    min-width: 30vw;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total_summary dd, .pd_orderdetail_value__total_summary dd {
    width: auto;
  }

  .pd_orderdetail_value__total_misc {
    margin-top: 25px;
    width: 100%;
  }

  .pd_orderdetail_value__total_misc_sub {
    margin-top: 7px;
  }

  .pd_contract-otherinfo__item_dist {
    width: 100%;
    padding: 20px 15px;
  }

  .pd_contract-otherinfo__item_dist dt, .pd_contract-otherinfo__item_dist dd {
    width: 50%;
  }

  .pd_contract-otherinfo__item_dist dd a {
    word-break: break-all;
  }

  .pd_contract-otherinfo__item_dist dd:before {
    padding-right: 5px;
  }

  .pd_orderdetail_value.short dd {
    margin-left: 0;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val:not(:nth-of-type(1)) {
    margin-left: 0;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val:nth-of-type(2) {
    margin-top: 5px;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val dt {
    width: 60%;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__val dd {
    width: 40%;
    margin-left: 0;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total-val {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: initial;
    -webkit-box-direction: initial;
    -ms-flex-direction: initial;
    flex-direction: initial;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .pd_orderdetail_value.short .pd_orderdetail_value__total-val dd {
    padding-left: 1em;
  }

  /* ------------- */
}

/* END orderdetail__contents */
/* **.TITLE
----------------------------------------------------------- */
/* マイページ系(クーポン・解約・配送先・クレジットカード・返品など) */
/*
    File Name   : mypage02.scss
    Description : マイページ系(クーポン・解約・配送先・クレジットカード・返品など) 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* 解約申請
----------------------------------------------------------- */
.pd_cancellation__contents .pd_newLine-sp th {
  padding-top: 10px;
  vertical-align: top;
}

.pd_cancellation__contents .pd_selectWrap-contractorInfo {
  width: 390px;
}

@media screen and (max-width: 640px) {
  .pd_cancellation__contents .pd_selectWrap-contractorInfo {
    width: 200%;
  }
}

.pd_cancellation__contents .pd_select-type01 {
  width: 100%;
}

.pd_aboutCancel {
  margin-bottom: 40px;
  margin-top: 50px;
  padding: 50px 0 30px;
}

.pd_aboutCancel__title {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.pd_aboutCancel__description {
  margin-bottom: 20px;
  text-align: center;
}

.pd_aboutCancel__text {
  max-height: 335px;
  margin-bottom: 20px;
  padding: 20px;
  overflow-y: scroll;
  background: #fff;
  border: 1px solid #E5E5E5;
}

@media screen and (max-width: 640px) {
  .pd_aboutCancel__text {
    padding: 20px 15px;
  }
}

.pd_aboutCancel__terms {
  text-align: right;
}

.pd_aboutCancel__terms span {
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_contractorInfo {
    padding: 0 10px;
  }
}

.pd_informationBox {
  max-width: 976px;
  margin: 0 auto 50px;
  padding: 20px;
  border: 1px solid #E5E5E5;
}

.pd_informationBox__title {
  margin-bottom: 17px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_informationBox__text {
  margin-bottom: 5px;
}

.pd_informationBox__subTitle {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_table-style01 {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .pd_table-style01.pd_add_linefeed .pd_add_flex_column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 640px) {
  .pd_table-style01.pd_add_linefeed .pd_add_flex_column td {
    padding-left: 0;
  }
}

.pd_table-style01.pd_add_linefeed .pd_newLine-sp {
  margin-top: 10px;
}

.pd_table-style01.pd_add_linefeed .pd_newLine-sp td {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .pd_table-style01.pd_add_linefeed .pd_colon.pd_sp-view {
    display: inline-block !important;
  }
}

.pd_table-style01 th, .pd_table-style01 td {
  padding: 3px 0;
  text-align: left;
}

.pd_table-style01 th {
  width: 150px;
}

@media screen and (max-width: 640px) {
  .pd_table-style01 th {
    width: 50%;
  }
}

.pd_table-style01 th span {
  display: block;
  position: relative;
}

.pd_table-style01 th span:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: '：';
}

@media screen and (max-width: 640px) {
  .pd_table-style01 th span.pd_none_colon:after {
    display: none;
  }
}

.pd_table-style01 td {
  padding-left: 5px;
}

.pd_informationBox__selecet-costom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.pd_selectWrap-contractorInfo .select-type01 {
  width: 390px;
}

@media screen and (max-width: 640px) {
  .pd_selectWrap-contractorInfo .select-type01 {
    width: 100%;
  }
}

.pd_selectWrap-creditCard .pd_select-type01 {
  width: 90px;
}

.pd_informationBox__reason {
  width: 100%;
  height: 125px;
}

.pd_newLine-sp th {
  vertical-align: top;
}

@media screen and (max-width: 640px) {
  .pd_newLine-sp {
    display: block;
  }

  .pd_newLine-sp th, .pd_newLine-sp td {
    display: block;
    padding: 0;
  }

  .pd_newLine-sp th {
    width: 100%;
  }

  .pd_newLine-sp td {
    width: 200%;
    margin-top: 10px;
  }
}

.pd_cancellation__modalEl {
  display: none;
  position: fixed;
  z-index: 1005;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 976px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 40px 20px 50px;
  background: #fff;
  border-radius: 5px;
}

@media screen and (max-width: 640px) {
  .pd_cancellation__modalEl {
    width: 94.6%;
    margin: 0 auto;
  }
}

.pd_cancellation__modalEl__inner {
  position: relative;
}

/* 解約申請確認
----------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_cancellation-confirm__contents .pd_newLine-sp th {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 200%;
  }

  .pd_cancellation-confirm__contents .pd_newLine-sp th span {
    width: 100%;
  }

  .pd_cancellation-confirm__contents .pd_newLine-sp th span:after {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .pd_cancellation-confirm__contents .pd_table-style01 th {
    width: 215px;
  }
}

/* 解約申請完了
----------------------------------------------------------- */
.pd_cancellation__completed .pd_btn-horizontal {
  margin-top: 40px;
}

.pd_cancellation__completed .pd_box {
  margin-bottom: 20px;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal .pd_modal-header__ttl .pd_only_your_B_modal__link {
    margin-top: 20px;
    margin-left: 0;
  }
}

.pd_only_your_B_modal .pd_modal-header {
  padding: 35px 40px 0 40px;
}

.pd_only_your_B_modal__list {
  margin-top: 20px;
}

.pd_only_your_B_modal__item {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 170px 1fr;
  grid-template-rows: auto auto;
  -ms-grid-rows: auto auto;
  -ms-grid-columns: 170px 1fr;
  padding: 20px;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal__item {
    grid-template-columns: 90px 1fr;
    -ms-grid-columns: 90px 1fr;
  }
}

.pd_only_your_B_modal__item + .pd_only_your_B_modal__item {
  margin-top: 10px;
}

.pd_only_your_B_modal__item_img {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  font-size: 0;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal__item_img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
  }
}

.pd_only_your_B_modal__item_right_heading {
  -ms-grid-column: 2;
  grid-column: 2 / 2;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  padding-left: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal__item_right_heading {
    grid-column: 2 / 3;
    -ms-grid-column: 1;
  }
}

.pd_only_your_B_modal__item_right_p {
  -ms-grid-column: 2;
  grid-column: 2 / 2;
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  padding-left: 20px;
  margin-top: 20px;
  font-size: 1.6rem;
}

@media screen and (max-width: 640px) {
  .pd_only_your_B_modal__item_right_p {
    grid-column: 1 / 3;
    -ms-grid-column: 1;
    padding-left: 0;
  }
}

/* お知らせ一覧 */
/*
    File Name   : news.scss
    Description : お知らせ一覧用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
.pd_tabWrap {
  margin-bottom: 40px;
}

@media screen and (max-width: 640px) {
  .pd_tabWrap {
    min-height: 66px;
    margin: 0 -10px 30px;
  }
}

.pd_tab-border {
  position: relative;
}

.pd_tab-border:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #E5E5E5;
  content: '';
}

@media screen and (max-width: 640px) {
  .pd_tab-border:after {
    bottom: 6px;
    left: -10px;
    width: calc(100% + 20px);
  }
}

.pd_tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 6px;
}

.pd_tab > li {
  position: relative;
  z-index: 1;
  padding: 10px 38px;
  font-size: 1.6rem;
  white-space: nowrap;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_tab > li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 60px;
    padding: 10px;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.28;
  }
}

.pd_tab > li.pd_is-active {
  border-bottom: 5px solid #000000;
  font-weight: bold;
  cursor: auto;
}

.pd_tab > li.pd_is-active:after {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-top: 5px solid #000000;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: '';
}

@media screen and (max-width: 640px) {
  .pd_tab-all {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .pd_tab-message {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
  }

  .pd_tab-product {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .pd_tab-maintenance {
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
  }
}

.pd_tab-content {
  margin-bottom: 40px;
}

.pd_news-list > li {
  padding: 20px 0;
  border-bottom: 1px dashed #CCCCCC;
}

.pd_news-list > li:first-child {
  border-top: 1px dashed #CCCCCC;
}

.pd_news-list__date {
  margin-bottom: 10px;
  font-weight: bold;
}

.pd_news-list__tag {
  margin-left: 15px;
}

.pd_news-list__tag-caution {
  color: #D00000;
}

.pd_news-list__tag-product {
  color: #003DA5;
}

.pd_news-list__tag-new {
  margin-left: 5px;
  color: #D00000;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
}

.pd_news-list__tag-maintenance {
  color: #666;
}

.pd_news-list__text {
  position: relative;
  font-size: 1.6rem;
}

.pd_news-list__text a {
  color: #000000;
  text-decoration: underline;
}

.pd_tab-content__inner {
  display: none;
}

.pd_tab-content__inner.pd_is-active {
  display: block;
}

/* **.TITLE
----------------------------------------------------------- */
/* カート */
/*
    File Name   : cart.scss
    Description : カート 用

    01.カート
    02.お届け先指定
    03.お届け先指定（ゲスト）
    04.お支払い方法選択
    05.注文内容確認
    06.注文完了
    07.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* 01.カート
----------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_cartflow {
    padding-bottom: 15px;
    // overflow-x: scroll;
  }
}

.pd_cartflow__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.pd_cartflow__li {
  width: 25%;
  text-align: center;
}

.pd_cartflow__li span {
  display: block;
}

.pd_cartflow__li span img {
  width: 50px;
  height: 18px
}

.pd_cartflow__name {
  font-weight: bold;
}

.pd_cartflow__statusbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  height: 12px;
  border-radius: 20px;
  background-color: #ddd;
  position: relative;
  z-index: 0;
}

.pd_cartflow__statusbar::before {
  content: '';
  display: block;
  height: 12px;
  margin: auto;
  border-radius: 20px;
  background-color: #edf4ff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.pd_cartflow__statusbar.pd_step2::before {
  width: calc((100% / 5) * 2);
}

.pd_cartflow__statusbar.pd_step3::before {
  width: calc((100% / 5) * 3);
}

.pd_cartflow__statusbar.pd_step4::before {
  width: calc((100% / 5) * 4);
}

.pd_cartflow__statusbar.pd_step5::before {
  width: calc((100% / 5) * 5);
}

.pd_cartflow__statusbar_li {
  width: 25%;
  display: block;
  height: 12px;
  border-radius: 20px;
  position: relative;
  z-index: 2;
}

.pd_cartflow__statusbar_li.pd_is-active {
  background-color: #003da5;
}

.pd_cartbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 40px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_cartbox {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.pd_cartbox__left {
  width: 620px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox__left {
    width: 100%;
  }
}

.pd_cartbox__right {
  width: 316px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox__right {
    width: 100%;
    margin-top: 40px;
  }
}

.pd_cartbox .pd_indent {
  padding-left: 1em;
  text-indent: -1em;
}

.pd_cartproduct__li {
  padding: 20px 0;
  border-top: 1px solid #e5e5e5;
}

.pd_cartproduct__li:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.pd_cartproduct__img {
  min-width: 140px;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  grid-row: 1 / 4;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
}

.pd_cartproduct__img img {
  vertical-align: bottom;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    padding-right: 20px;
  }
}

.pd_cartproduct__set {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  width: 100%;
  padding-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__set {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
  }
}

.pd_cartproduct__set_name {
  font-weight: bold;
}

.pd_cartproduct__set_option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}

.pd_cartproduct__set_option:last-of-type {
  margin-bottom: 10px;
}

.pd_cartproduct__set_option_dt {
  min-width: 100px;
}

.pd_cartproduct__set_option_dd {
  word-break: break-all;
}

.pd_cartproduct__set_addblock {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3 / 4;
  -ms-grid-column: 2;
  -ms-grid-row: 3;
  width: 100%;
  padding-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__set_addblock {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-column-span: 2;
    padding-top: 10px;
    padding-left: 0;
  }
}

.pd_cartproduct__set_addblock_li {
  font-size: 1.2rem;
  color: #666;
}

.pd_cartproduct__set_addblock_li:nth-child(n+2) {
  margin-top: 5px;
}

.pd_cartproduct__bottom .pd_cartproduct__price, .pd_cartproduct__bottom .pd_cartproduct__price--red, .pd_cartproduct__bottom .pd_cartproduct__price--reset_line {
  margin-top: 0;
}

.pd_cartproduct__bottom .pd_color_black {
  color: #000;
}

.pd_cartproduct__price, .pd_cartproduct__price--red, .pd_cartproduct__price--reset_line {
  margin-top: 10px;
}

.pd_cartproduct__price--red {
  color: #d00000;
}

.pd_cartproduct__price--reset_line {
  text-decoration: line-through;
}

.pd_cartproduct__quantity_and_price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 3px;
}

.pd_cartproduct__quantity_and_price .pd_cartproduct__price, .pd_cartproduct__quantity_and_price .pd_cartproduct__price--red, .pd_cartproduct__quantity_and_price .pd_cartproduct__price--reset_line {
  margin-top: 0;
  margin-left: 25px;
}

.pd_cartproduct__quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_cartproduct__choicelist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
}

.pd_cartproduct__choiceitem:nth-child(n+2) {
  margin-left: 30px;
}

.pd_cartproduct__addoption {
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #f7f7f7;
}

.pd_cartproduct__addoption_dt {
  padding-bottom: 10px;
  font-weight: bold;
}

.pd_cartproduct__addoption_dd {
  padding: 10px 0;
  border-top: 1px solid #e5e5e5;
}

.pd_cartproduct__addoptionitem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__addoptionitem {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .pd_cartproduct__addoptionitem_dd {
    margin-top: 15px;
    text-align: right;
  }
}

.pd_remarks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.pd_remarks__trash .pd_icon-trash {
  font-size: 1.4rem;
}

.pd_recalculation {
  margin-bottom: 15px;
}

.pd_recalculation .pd_btn-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pd_recalculation .pd_btn-horizontal .pd_btn {
  min-width: 82px;
  margin: 0;
  padding: 8px 11px;
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_result {
  padding: 34px 20px;
  background-color: #f7f7f7;
}

.pd_result__top {
  margin-bottom: 25px;
  border-bottom: 1px solid #dedede;
}

.pd_result .pd_tax, .pd_result .pd_yen {
  font-size: 1.4rem;
}

.pd_result .pd_taxlist {
  margin-bottom: 20px;
}

.pd_result .pd_taxlist__sum {
  font-size: 1.2rem;
  text-align: right;
}

.pd_result .pd_taxlist__sum:nth-child(n+2) {
  margin-top: 10px;
}

.pd_result .pd_btn-horizontal.pd_procedure {
  display: block;
}

.pd_result .pd_btn-horizontal.pd_procedure .pd_btn {
  width: 100%;
  margin: 0;
  font-weight: bold;
}

.pd_result__bottom_small {
  font-size: 1.2rem;
}

.pd_earnedpoints {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 7px 20px 5px;
  border-radius: 3px;
  background-color: #fff;
}

.pd_earnedpoints__name, .pd_earnedpoints__num {
  font-size: 1.4rem;
}

.pd_icon-shippingfee {
  display: inline-block;
  padding-left: 25px;
  background: url('/store/images/common/icon_shippingfee.svg') left 50% no-repeat;
  background-size: 18px;
}

.pd_icon-trash {
  display: inline-block;
  padding: 2px 2px 2px 20px;
  min-height: 16px;
  color: #666666;
  text-decoration: underline;
  font-size: 1rem;
  background: url('/store/images/common/icon_trash.svg') 0 50% no-repeat;
  background-size: 15px 16px;
}

.pd_icon-buy-later {
  display: inline-block;
  padding-left: 20px;
  background: url('/store/images/common/icon_buy_later.svg') left 50% no-repeat;
  background-size: 14px;
  color: #1876e9;
}

.pd_shippingfee {
  margin-top: 20px;
  padding: 15px 20px 20px;
  background-color: #edf4ff;
}

.pd_shippingfee__heading {
  font-size: 1.6rem;
  text-align: center;
}

.pd_shippingfee__dl {
  padding: 10px;
  background-color: #fff;
}

.pd_shippingfee__dl:nth-child(n+2) {
  margin-top: 10px;
}

.pd_shippingfee__dt {
  margin-bottom: 5px;
  font-weight: bold;
}

.pd_carts-empty__contents .pd_box {
  margin-top: 40px;
}

.pd_carts-empty__contents .pd_btn-horizontal {
  margin-top: 40px;
}

/* 02.お届け先指定
----------------------------------------------------------- */
.pd_delivery_target__heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_delivery_target__heading {
    display: block;
  }
}

.pd_delivery_target__heading .pd_btn {
  padding: 7px 10.5px;
  font-size: 1.4rem;
  font-weight: bold;
}

.pd_delivery_target__name {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_delivery_target__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pd_delivery_target__left .pd_select-type-twoway {
  width: 131px;
  margin-left: 15px;
}

@media screen and (max-width: 320px) {
  .pd_delivery_target__left .pd_select-type-twoway {
    width: 120px;
  }
}

.pd_delivery_target__left .pd_btn {
  min-width: 81px;
  width: 81px;
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .pd_delivery_target__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.pd_delivery_target__right .pd_btn {
  min-width: 145px;
}

.pd_personal_information {
  margin-bottom: 45px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.pd_personal_information__name {
  margin-bottom: 15px;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_personal_information__name .honorifics {
  font-size: 1.4rem;
}

.pd_personal_information__postal_code, .pd_personal_information__address {
  margin-bottom: 5px;
}

.pd_cartproduct__heading {
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_cartproduct__delivery_method {
  margin-bottom: 15px;
}

.pd_free_shipping {
  margin-top: 10px;
  font-size: 1.4rem;
}

.pd_registration_code {
  margin-top: 60px;
}

.pd_registration_code__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_registration_code__input {
  width: 303px;
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .pd_registration_code__input {
    width: 100%;
  }
}

.pd_registration_code__input.pd_border, .pd_registration_code__input.pd_border--input {
  width: 100%;
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.pd_registration_code__input.pd_border--input .pd_input-type01 {
  width: 303px;
}

@media screen and (max-width: 640px) {
  .pd_registration_code__input.pd_border--input .pd_input-type01 {
    width: 100%;
  }
}

.pd_registration_code__input .pd_input-type01 {
  width: 100%;
}

.pd_registration_code__caution {
  margin-top: 10px;
  font-size: 1.4rem;
  color: #666;
}

.pd_modal.pd_cart .pd_modal-header {
  padding: 50px 40px 0;
}

.pd_modal.pd_cart .pd_list-style01__title {
  min-width: 210px;
}

.pd_modal.pd_cart .pd_list-style01__content {
  width: 100%;
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_push_left .pd_list-style01__title {
  min-width: calc(210px - 24px);
  margin-right: 24px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_list-style01__inner.pd_push_left .pd_list-style01__title {
    min-width: auto;
    margin-right: 0;
  }
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_push_left .pd_list-style01__content {
  margin-left: -24px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_list-style01__inner.pd_push_left .pd_list-style01__content {
    margin-left: 0;
  }
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_family_name .pd_list-style01__first, .pd_modal.pd_cart .pd_list-style01__inner.pd_family_name .pd_list-style01__second {
  min-width: 28px;
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_tel {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pd_modal.pd_cart .pd_list-style01__inner.pd_tel .pd_list-style01__title {
  margin-top: 6.5px;
}

.pd_modal.pd_cart .pd_list-style01__cautionWrap {
  display: block;
  width: 100%;
}

.pd_modal.pd_cart .pd_list-style01__cautionWrap .pd_list-style01__caution {
  display: block;
  margin-top: 5px;
  margin-left: 0;
}

.pd_modal.pd_cart .pd_list-style01__cautionWrap .pd_list-style01__caution:first-child {
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_list-style01 .pd_btnWrap {
    margin-left: 10px;
  }
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_btn-address {
    padding: 10px 7px;
  }
}

@media screen and (max-width: 320px) {
  .pd_modal.pd_cart .pd_btn-address {
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_modal.pd_cart .pd_input-name {
  width: 160px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_input-name {
    width: 93px;
  }
}

@media screen and (max-width: 320px) {
  .pd_modal.pd_cart .pd_input-name {
    width: 65px;
  }
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_input-address {
    width: 90px;
  }
}

.pd_modal.pd_cart .pd_input-town, .pd_modal.pd_cart .pd_input-apartment {
  width: 430px;
}

@media screen and (max-width: 640px) {
  .pd_modal.pd_cart .pd_input-tel {
    width: 75px;
  }
}

@media screen and (max-width: 320px) {
  .pd_modal.pd_cart .pd_input-tel {
    width: 57px;
  }
}

/* 03.お届け先指定（ゲスト）
----------------------------------------------------------- */
.pd_cartbox_guest__heading {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_cart_guest .pd_list-style01__title {
  min-width: 210px;
  margin-top: 6.5px;
}

.pd_cart_guest .pd_list-style01__content {
  width: 100%;
}

.pd_cart_guest .pd_list-style01__inner {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pd_cart_guest .pd_list-style01__inner.pd_family_name .pd_list-style01__first, .pd_cart_guest .pd_list-style01__inner.pd_family_name .pd_list-style01__second {
  min-width: 28px;
}

@media screen and (max-width: 640px) {
  .pd_cart_guest .pd_list-style01 .pd_btnWrap {
    margin-left: 5px;
  }
}

.pd_cart_guest .pd_list-style01__caution {
  display: block;
  width: 100%;
  margin-top: 3px;
  margin-left: 0;
}

@media screen and (max-width: 640px) {
  .pd_cart_guest .pd_list-style01__caution-name {
    margin-left: 23px;
  }
}

.pd_cart_guest .pd_input-name {
  width: 130px;
}

@media screen and (max-width: 640px) {
  .pd_cart_guest .pd_input-name {
    width: 100px;
  }
}

@media screen and (max-width: 320px) {
  .pd_cart_guest .pd_input-name {
    width: 84px;
  }
}

.pd_cart_guest .pd_input-address {
  width: 126px;
}

.pd_cart_guest .pd_input-town, .pd_cart_guest .pd_input-apartment, .pd_cart_guest .pd_input-email {
  width: 100%;
}

.pd_by_delivery_target {
  padding: 25px;
  background-color: #f7f7f7;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target {
    padding: 20px;
  }
}

.pd_by_delivery_target .pd_custom-checkbox {
  margin: 0;
}

.pd_by_delivery_target__content {
  display: none;
  margin-top: 20px;
  text-align: left;
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content {
    background-color: #f7f7f7;
  }
}

.pd_by_delivery_target__content .pd_list-style01 {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content .pd_list-style01 {
    border: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content .pd_list-style01 li {
    padding: 10px 0 0 0;
    border-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content .pd_list-style01 li:first-child {
    padding-top: 0;
  }
}

.pd_by_delivery_target__content .pd_list-style01__title {
  min-width: 190px;
}

.pd_by_delivery_target__content .pd_input-name {
  width: 115px;
}

@media screen and (max-width: 640px) {
  .pd_by_delivery_target__content .pd_input-name {
    width: 100px;
  }
}

@media screen and (max-width: 320px) {
  .pd_by_delivery_target__content .pd_input-name {
    width: 84px;
  }
}

.pd_by_delivery_target__content .pd_input-address {
  width: 120px;
}

.pd_cart_guest_terms {
  margin-top: 60px;
}

.pd_cart_guest_terms__heading {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_cart_guest_terms__content {
  padding: 20px;
  border: 1px solid #e5e5e5;
  text-align: center;
}

.pd_cart_guest_terms__content_p {
  text-align: left;
}

.pd_cart_guest_terms__content_overflow {
  height: 130px;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  text-align: left;
  overflow-y: scroll;
}

.pd_cart_guest_terms .pd_custom-checkbox {
  margin: 20px 0 0 0;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_handlable_cvs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_handlable_cvs__a {
  color: #1876e9;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_cvs_payment .pd_handlable_cvs__p {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_handlable_cvs .pd_icon-otherlink {
  background: url('/store/images/common/icon_otherlink.svg') 100% 50% no-repeat;
  background-size: 10px 10px;
  padding-right: 16px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01 {
  margin-top: 20px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__title {
  min-width: 160px;
  width: 160px;
  margin-top: 6.5px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__title {
    min-width: auto;
    width: 100%;
    margin-top: 0;
  }
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_recipient .pd_recipient__name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_recipient .pd_recipient__name .pd_input-name {
  width: 170px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_recipient .pd_recipient__name .pd_input-name {
    width: 100%;
  }
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_recipient .pd_recipient__name .pd_input-name:nth-child(2) {
  margin-left: 10px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_customer_number {
  display: none;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01__inner.pd_customer_number .pd_customer_number__p {
  margin-top: 6.5px;
}

.pd_cartbox_payment__input_content.pd_cvs_payment .pd_list-style01 .pd_select-type-twoway {
  width: 170px;
}

.pd_cartbox_payment__input_content.pd_cod .pd_cod__p + .pd_cod__p {
  margin-top: 5px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_pay_easy__dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_pay_easy__dt {
  min-width: 75px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_pay_easy__p {
  margin-top: 5px;
  margin-left: 75px;
  font-size: 1.2rem;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_pay_easy .pd_pay_easy__p {
    margin-left: 0;
  }
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 {
  margin-top: 20px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01__inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01__title {
  min-width: 188px;
  width: 188px;
  margin-top: 6.5px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_select-type-twoway {
  width: 170px;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_settlement_person__name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_settlement_person__name .pd_input-name {
  width: 170px;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_settlement_person__name .pd_input-name {
    width: 100%;
  }
}

.pd_cartbox_payment__input_content.pd_pay_easy .pd_list-style01 .pd_settlement_person__name .pd_input-name:nth-child(2) {
  margin-left: 10px;
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_semicolon {
  display: inline-block;
  margin-left: auto;
  font-weight: normal;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_bank_transfer .pd_semicolon {
    margin-left: 5px;
  }
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_bank_transfer__dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 640px) {
  .pd_cartbox_payment__input_content.pd_bank_transfer .pd_bank_transfer__dl {
    display: block;
  }
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_bank_transfer__dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 160px;
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_list-style01 {
  margin-top: 20px;
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_list-style01__inner {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.pd_cartbox_payment__input_content.pd_bank_transfer .pd_list-style01__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  min-width: 160px;
  width: 160px;
  margin-top: 6.5px;
}

.pd_coupon_use {
  margin-top: 50px;
}

.pd_coupon_use .pd_custom-radio {
  margin: 0;
}

.pd_coupon_use .pd_custom-radio .pd_custom-control-indicator {
  top: 0;
  margin-top: 0;
}

.pd_coupon_use__p, .pd_coupon_use__p--gray, .pd_coupon_use__p--red {
  margin-top: 20px;
  margin-left: 30px;
}

.pd_coupon_use__p--gray {
  color: #666;
}

.pd_coupon_use__p--red {
  margin-top: 10px;
  color: #d00000;
}

.pd_coupon_use__input_wrap {
  margin-top: 20px;
  margin-left: 30px;
}

.pd_coupon_use__input_wrap .pd_input-name {
  width: 300px;
}

@media screen and (max-width: 640px) {
  .pd_coupon_use__input_wrap .pd_input-name {
    width: 100%;
  }
}

.pd_coupon_use .pd_btn-horizontal {
  margin-top: 30px;
}

.pd_coin_use {
  margin-top: 50px;
}

.pd_coin_use .pd_custom-radio {
  width: 100%;
  margin-right: 0;
}

.pd_coin_use .pd_custom-radio:last-child {
  margin-bottom: 0;
}

.pd_coin_use .pd_custom-radio .pd_custom-control-indicator {
  top: 0;
  margin-top: 0;
}

.pd_coin_use .pd_custom-radio .pd_custom-control-indicator.pd_in_input {
  top: 10%;
}

@media screen and (max-width: 640px) {
  .pd_coin_use .pd_custom-radio .pd_custom-control-indicator.pd_in_input {
    top: 0;
  }
}

.pd_coin_use .pd_input-name {
  width: 110px;
  text-align: right;
}

.pd_coin_use__caution {
  font-size: 1.4rem;
  color: #d00000;
}

.pd_coin_use .pd_btn-horizontal {
  margin-top: 30px;
}

.pd_delivery_preferred_date {
  margin-top: 50px;
}

.pd_delivery_preferred_date .pd_input-name {
  width: 165px;
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date .pd_input-name {
    width: 100%;
  }
}

.pd_delivery_preferred_date .pd_select-type-twoway {
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date .pd_select-type-twoway {
    margin-left: 0;
  }
}

.pd_delivery_preferred_date .pd_select-type-twoway.pd_clock {
  padding-left: 30px;
  background-image: url('/store/images/common/icon_clock.svg'), url('/store/images/common/select_arrow_twoway.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: center left 10px, calc(100% - 11px) 50%;
  background-size: 13px, 6.4px 10.6px;
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date .pd_select-type-twoway.pd_clock {
    width: 49%;
  }
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pd_delivery_preferred_date .pd_btn-horizontal {
  margin-top: 50px;
}

.pd_delivery_preferred_date__info_dt {
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.pd_delivery_preferred_date__info_dd {
  font-size: 1.4rem;
}

.pd_delivery_preferred_date .pd_cartproduct__top {
  margin-top: 20px;
  grid-template-columns: 100px 1fr;
  -ms-grid-columns: 100px 1fr;
}

.pd_delivery_preferred_date .pd_cartproduct__img {
  min-width: 100px;
}

.pd_delivery_preferred_date__calendar {
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_delivery_preferred_date__calendar {
    width: 49%;
  }
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date {
  padding-left: 30px;
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date::-webkit-input-placeholder {
  color: #000;
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date:-ms-input-placeholder {
  color: #000;
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date::-ms-input-placeholder {
  color: #000;
}

.pd_delivery_preferred_date__calendar .pd_js-delivery_preferred_date::placeholder {
  color: #000;
}

.pd_delivery_preferred_date__calendar .ui-datepicker-trigger {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  cursor: pointer;
}

.ui-datepicker {
  display: none;
  width: 320px;
  padding: 20px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 10px 0px black;
  box-shadow: 0 0 10px 0px black;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ui-datepicker td{
  padding-bottom 10px
}
// .ui-datepicker .ui-state-default.ui-state-highlight{
//   height 30px !important
// }
// .ui-datepicker .ui-state-default{
//   height 16px !important
// }


.ui-calendar-select-state .ui-state-icon{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 16px;
  margin: 0 auto;
  font-size: 15px
  text-align: center;
  font-weight bold
 /* font-family: osaka, Times; */ 
  margin-top 6px
}
.ui-state-icon.ui-state-icon-ok{ //選択可能
  color: #1876e9;
}
.ui-state-icon.ui-state-icon-full{ //満員
  color: #666;
}
.pd_date_select-bottom{
  border-top: 1px solid #e5e5e5
  margin-top 16px
  padding-top 16px
  font-size 14px
  &>p{
    margin-right 0
    margin-left auto
    width 90px
    line-height 1.6
    &>span{
      margin-right 10px
    /*  font-family: osaka, Times; */ 
      width 14px
      text-align: center
      display inline-block
    }
    &:nth-child(1)>span{
      color: #1876e9;
    }
    &:nth-child(2)>span{
      color: #1876e9;
    }
    &:nth-child(3)>span{
      color: #666;
    }
  }
}
.ui-state-not-allowed{
  cursor: not-allowed;
}

@media screen and (max-width: 640px) {
  .ui-datepicker {
    width: calc(100% - 50px);
    margin: 0 auto;
  }
}

.ui-datepicker *, .ui-datepicker *::before, .ui-datepicker *::after {
  line-height: normal;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ui-datepicker-header {
  position: relative;
}

.ui-datepicker-prev, .ui-datepicker-next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  height: 100%;
  position: absolute;
  top: 2px;
  cursor: pointer;
}

.ui-datepicker-prev span, .ui-datepicker-next span {
  display: block;
  width: 12px;
  height: 12px;
  font-size: 0;
  border: 2px solid #1876e9;
  border-top: transparent;
  border-right: transparent;
}

.ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker-prev span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ui-datepicker-next {
  right: 2px;
}

.ui-datepicker-next span {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.ui-datepicker-title {
  padding: 0 20px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: normal;
  text-align: center;
}

.ui-datepicker-calendar {
  margin-top: 25px;
}

.ui-datepicker th {
  padding: 0.7em 0.3em;
  font-size: 1.5rem;
  color: #acacac;
  text-align: center;
}

.ui-datepicker .ui-state-default {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  font-size: 1.5rem;
  color: #000;
  text-align: center;
}

.ui-datepicker .ui-state-default.ui-state-highlight {
  border-radius: 40px;
  background-color: #1876e9;
  color: #fff;
}

.pd_no_payment__p {
  font-size: 1.6rem;
  margin-top: -5px;
}

/* **.TITLE
----------------------------------------------------------- */
/* 契約 */
/*
    File Name   : contract.scss
    Description : 契約 用

    01.契約手続き
    02.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
.pd_cartflow.pd_contractflow .pd_cartflow__li, .pd_cartflow.pd_contractflow .pd_cartflow__statusbar_li {
  width: calc((100% / 3));
}

.pd_cartflow.pd_contractflow .pd_cartflow__statusbar.pd_step2::before {
  width: calc((100% / 3) * 2);
}

.pd_cartflow.pd_contractflow .pd_cartflow__statusbar.pd_step3::before {
  width: calc((100% / 3) * 3);
}

/* 01.契約手続き
----------------------------------------------------------- */
.pd_cartbox.pd_contract_procedure .pd_contract_procedure__credit_heading {
  font-size: 1.6rem;
}

.pd_cartbox.pd_contract_procedure .pd_table_layout__th:first-child, .pd_cartbox.pd_contract_procedure .pd_table_layout__th:nth-child(2) {
  width: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pd_cartbox.pd_contract_procedure .pd_table_layout__td:first-child, .pd_cartbox.pd_contract_procedure .pd_table_layout__td:nth-child(2) {
  width: 50%;
}

.pd_cartbox.pd_contract_procedure .pd_table_layout__td:nth-child(2) {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pd_plan_confirmation {
  margin-top: 50px;
}

.pd_plan_confirmation__box {
  padding: 20px;
  border: 1px solid #e5e5e5;
}

.pd_plan_confirmation__dt {
  margin-bottom: 0.5em;
  font-weight: bold;
}

.pd_plan_confirmation__dd + .pd_plan_confirmation__dd {
  margin-top: 0.5em;
}

.pd_contract .pd_modal-header {
  padding: 35px 40px 0 40px;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 0;
}

.pd_contract__heading_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_contract__heading_box {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .pd_contract__heading_box .pd_modal-header__ttl {
    text-align: left;
  }
}

.pd_contract__heading_box .pd_btn {
  margin-left: 20px;
  padding: 4px 12px;
  font-size: 1.4rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_contract__heading_box .pd_btn {
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_delivery_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .pd_delivery_list {
    display: block;
  }
}

.pd_delivery_list__item {
  width: calc(((100% - 20px) / 2));
  border: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_delivery_list__item {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .pd_delivery_list__item:nth-child(n+2) {
    margin-top: 20px;
  }
}

.pd_delivery_list__item:nth-child(n+3) {
  margin-top: 20px;
}

.pd_delivery_list__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #c7c7c7;
  background-color: #e0e0e0;
}

.pd_delivery_list__top.pd_active {
  color: #003da5;
  background-color: #edf4ff;
}

.pd_delivery_list__top_name {
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_delivery_list__top_name .pd_honorifics {
  font-size: 1.4rem;
}

.pd_delivery_list__top_name .pd_icon_person {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 8px;
  border-radius: 3px;
  font-size: 1.1rem;
  background-color: #fff;
}

.pd_delivery_list__top .pd_btn {
  min-width: 104px;
  padding: 8px;
  font-size: 1.2rem;
}

.pd_delivery_list__bottom {
  padding: 16px 20px;
}

.pd_delivery_list__bottom p + p {
  margin-top: 0.5em;
}

/* 02.TITLE
----------------------------------------------------------- */
/* プラン */
/*
    File Name   : plan.scss
    Description : プラン 用

    01.プラン
    02.プラン詳細
    03.プラン選択条件保存完了
    04.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* 04.TITLE
----------------------------------------------------------- */
/* キャンペーン */
/*
    File Name   : campaign.scss
    Description : キャンペーン 用

    01.キャンペーン一覧
    02.キャンペーントップ
    03.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* 01.キャンペーン一覧
----------------------------------------------------------- */
.pd_campaign_list__item {
  border-top: 1px solid #e5e5e5;
}

.pd_campaign_list__item:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.pd_campaign_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0;
  color: #000;
}

.pd_campaign_item:hover .pd_campaign_item__heading, .pd_campaign_item:hover .pd_campaign_item__p {
  text-decoration: underline;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item {
    display: block;
  }
}

.pd_campaign_item .pd_campaign_discount {
  padding: 5px 8px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #d00000;
}

.pd_campaign_item .pd_campaign_item_count {
  margin-left: 10px;
}

.pd_campaign_item .pd_campaign_event_date {
  margin-left: 20px;
  padding: 5px 8px;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item .pd_campaign_event_date {
    margin-left: 0;
  }
}

.pd_campaign_item .pd_campaign_icon_new {
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #d00000;
}

.pd_campaign_item__left {
  min-width: 280px;
}

.pd_campaign_item__right {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item__right {
    margin-top: 20px;
    margin-left: 0;
  }
}

.pd_campaign_item__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item__option {
    display: block;
  }
}

.pd_campaign_item__option_left, .pd_campaign_item__option_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_campaign_item__option_right {
    margin-top: 15px;
  }
}

.pd_campaign_item__p {
  margin-top: 30px;
  font-size: 1.6rem;
}

/* 02.キャンペーントップ
----------------------------------------------------------- */
.pd_campaign__contents.pd_has_flex .pd_content__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_campaign__contents.pd_has_flex .pd_content__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_campaign__contents.pd_has_flex .pd_content__inner .pd_category_sidebar {
    margin-top: 60px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media screen and (max-width: 640px) {
  .pd_campaign__contents.pd_has_flex .pd_content__inner .pd_categorybox {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}

.pd_campaign_description__tag {
  margin-top: 30px;
}

.pd_campaign_description__tag_sale {
  display: inline-block;
  padding: 5px 7px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #d00000;
}

.pd_campaign_description__tag_new {
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #d00000;
}

.pd_campaign_description__tag_period {
  display: inline-block;
  margin-left: 7px;
  padding: 8px 12px;
  border-radius: 3px;
  font-size: 1.4rem;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_campaign_description__tag_period {
    display: block;
    max-width: 296px;
    margin-top: 10px;
    margin-left: 0;
  }
}

.pd_campaign_description__p {
  margin-top: 20px;
  font-size: 1.6rem;
}

.pd_category_main__hr {
  width: 100%;
  height: 1px;
  margin: 0;
  background-color: #e5e5e5;
  border: none;
}

.pd_category_main__hr.pd_mt80 {
  margin-top: 80px;
}

.pd_category_main__etc {
  margin-top: 40px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__etc {
    margin: 0 -10px;
    padding-top: 40px;
    border-top: none;
  }
}

.pd_category_main__etc_heading {
  font-size: 2.4rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_category_main__etc_heading {
    padding: 0 10px;
  }
}

.pd_category_main__etc_list {
  margin-top: 30px;
  padding-bottom: 70px;
  position: relative;
}

.pd_category_main__etc_list .slick-arrow {
  width: 44px;
  height: 44px;
  margin: auto;
  border: none;
  border-radius: 50%;
  font-size: 0;
  background-color: #f7f7f7;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.pd_category_main__etc_list .slick-arrow::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  border: 2px solid #000;
  border-top: transparent;
  border-right: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pd_category_main__etc_list .slick-arrow.slick-prev {
  right: 54px;
}

.pd_category_main__etc_list .slick-arrow.slick-next {
  left: 54px;
}

.pd_category_main__etc_list .slick-arrow.slick-next::before {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.pd_category_main__etc_list__item {
  padding: 0 7px;
}

.pd_category_main__etc_list__link {
  display: block;
  color: #000;
}

.pd_category_main__etc_list__link_img {
  font-size: 0;
}

.pd_category_main__etc_list__link_img img {
  width: 100%;
}

.pd_category_main__etc_list__link_bottom {
  padding: 20px;
  border: 1px solid #dbdbdb;
}

.pd_category_main__etc_list__link_bottom__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_category_main__etc_list__link_bottom__event_date {
  margin-top: 15px;
  padding: 10px;
  font-size: 1.2rem;
  background-color: #f7f7f7;
}

.pd_category_main__etc_list__link_bottom__p {
  margin-top: 15px;
  font-size: 1.6rem;
}

.pd_category_main__etc_list__link_bottom__item_count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
  padding-top: 15px;
  border-top: 1px solid #dbdbdb;
}

.pd_category_main__etc_list__link_bottom__item_count .pd_icon_sale {
  display: inline-block;
  padding: 5px 7px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #d00000;
}

.pd_category_main__etc_list__link_bottom__item_count .pd_sum_text {
  margin-left: 10px;
}

/* 03.TITLE
----------------------------------------------------------- */
/* カテゴリ・商品 */
/*
    File Name   : category.scss
    Description : カテゴリ 用

    01.カテゴリ
    02.商品詳細（単品）
    03.商品詳細（セット品）
    04.テキストモード
    05.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* 01.カテゴリ
----------------------------------------------------------- */
.pd_category__contents .pd_content__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_category__contents .pd_content__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_category__contents .pd_content__inner .pd_category_sidebar {
    margin-top: 60px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media screen and (max-width: 640px) {
  .pd_category__contents .pd_content__inner .pd_categorybox {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}

.pd_category_sidebar {
  width: 200px;
}

@media screen and (max-width: 640px) {
  .pd_category_sidebar {
    width: 100%;
  }
}

.pd_category_sidebar__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_category_sidebar__heading {
    padding: 0 10px;
  }
}

.pd_category_sidebar_box + .pd_category_sidebar_box {
  margin-top: 50px;
}

.pd_category_sidelist {
  margin-top: 20px;
}

.pd_category_sidelist__item {
  border-top: 1px solid #e5e5e5;
}

.pd_category_sidelist__item:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.pd_category_sidelist__link {
  display: block;
  padding: 16px 32px 16px 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: #222;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_category_sidelist__link {
    padding: 16px 32px 16px 10px;
  }
}

.pd_category_sidelist__link.pd_icon_plus_minus::before, .pd_category_sidelist__link.pd_icon_plus_minus::after {
  content: '';
  display: block;
  width: 12px;
  height: 1px;
  margin: auto;
  background-color: #adadad;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
}

.pd_category_sidelist__link.pd_icon_plus_minus::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.pd_category_sidelist__link.pd_icon_plus_minus.pd_is-active::after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.pd_category_sidelist__link.pd_icon_arw_right::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: auto;
  border-top: 1px solid #adadad;
  border-right: 1px solid #adadad;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pd_category_sidelist.pd_category_sidebar_lv2 {
  display: none;
  margin-top: 0;
}

.pd_category_sidelist.pd_category_sidebar_lv2 .pd_category_sidelist__item {
  border-top: 1px solid #e5e5e5;
  border-bottom: none;
}

.pd_category_sidelist.pd_category_sidebar_lv2 .pd_category_sidelist__link {
  padding: 16px 32px 16px 10px;
  font-weight: normal;
}

@media screen and (max-width: 640px) {
  .pd_category_sidelist.pd_category_sidebar_lv2 .pd_category_sidelist__link {
    padding: 16px 32px 16px 20px;
  }
}

.pd_category_main {
  width: 736px;
}

@media screen and (max-width: 640px) {
  .pd_category_main {
    width: 100%;
    padding: 0 10px;
  }
}

.pd_category_main__heading {
  font-size: 2.6rem;
  font-weight: bold;
}

.pd_category_main__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__head {
    display: block;
  }
}

.pd_category_main__head_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 300px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__head_left {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pd_category_main__head_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 315px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__head_right {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.pd_category_main__display_count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__display_count {
    margin-left: 0;
  }
}

.pd_category_main__display_count .pd_select-type-twoway {
  width: 86px;
}

.pd_category_main__btn .pd_btn {
  min-width: 118px;
  padding: 8px 12px;
  font-size: 1.2rem;
  font-weight: bold;
}

.pd_category_main__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_category_main__option {
    margin-left: 0;
  }
}

.pd_category_main__option .pd_select-type-twoway {
  width: 96px;
}

.pd_category_main__layout {
  margin-left: 20px;
  font-size: 0;
}

.pd_category_main__layout_btn:nth-child(2) {
  margin-left: 10px;
}

.pd_category_main .pd_product_history__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 20px 0 30px 0;
  padding: 20px 0 45px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
}

.pd_category_main .pd_product_history__ul:after {
  content: '';
  display: block;
  width: 32%;
}

.pd_category_main .pd_product_history__li {
  // width: calc((100% - 40px) / 3);
  padding: 0;
  margin-left: 0 !important;
  width: 32%;
  // max-width: 232px;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_product_history__li {
    // width: calc((100% - 10px) / 2);
    width: calc(50% - 7.5px);
  }
}

.pd_category_main .pd_product_history__li:nth-child(n+4) {
  margin-top: 40px;
}

.pd_category_main .pd_product_history__li:nth-child(3n+2) {
  // margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_product_history__li:nth-child(3n+2) {
    // margin-left: 0;
  }
}

.pd_category_main .pd_product_history__li:nth-child(3n) {
  // margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_product_history__li:nth-child(3n) {
    // margin-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_product_history__li:nth-child(n+3) {
    margin-top: 30px;
  }
}

.pd_category_main .pd_product_history__li:nth-child(2n) {
  // margin-left: 10px;
}

.pd_category_main .pd_relation_item__favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 15;
}

.pd_category_main .pd_product_history_item__option {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.pd_category_main .pd_relation_item__description {
  margin-top: 12px;
  font-weight: bold;
}

.pd_category_main .pd_relation_item__part_number {
  margin-top: 12px;
  color: #666;
  font-size: 1.1rem;
}

.pd_category_main .pd_relation_item__part_number + .pd_product_history__p--red_bold, .pd_category_main .pd_relation_item__part_number + .pd_product_history__p--black_bold {
  margin-top: 5px;
}

.pd_category_main .pd_favorite__productReview {
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pd_category_main .pd_category_bnr__list {
  padding-bottom: 20px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_category_bnr__list {
    margin-bottom: 20px;
    padding: 18px 0;
    background-color: #000;
  }
}

.pd_category_main .pd_category_bnr__list .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 74px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_category_bnr__list .slick-dots {
    width: 176px;
    margin: auto;
    bottom: -18px;
    left: 0;
  }
}

.pd_category_main .pd_category_bnr__list .slick-dots button {
  width: 24px;
  height: 4px;
  padding: 0;
  border: none;
  font-size: 0;
  background-color: #ddd;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_category_bnr__list .slick-dots button {
    width: 48px;
  }
}

.pd_category_main .pd_category_bnr__list .slick-dots li:nth-child(n+2) {
  margin-left: 2px;
}

@media screen and (max-width: 640px) {
  .pd_category_main .pd_category_bnr__list .slick-dots li:nth-child(n+2) {
    margin-left: 16px;
  }
}

.pd_category_main .pd_category_bnr__list .slick-dots li.slick-active button {
  background-color: #1876e9;
}

@media screen and (max-width: 640px) {
  .pd_category_modal .pd_modal-header {
    padding: 25px 20px 0 20px;
  }
}

.pd_category_modal__heading_bottom {
  margin-top: 10px;
  font-size: 1.4rem;
  color: #666;
}

.pd_category_modal__heading_bottom .pd_fs20 {
  font-size: 2rem;
}

.pd_category_modal__body {
  margin-top: 20px;
  padding: 20px;
  background-color: #f7f7f7;
}

.pd_category_modal__body_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box {
    display: block;
  }
}

.pd_category_modal__body_box + .pd_category_modal__body_box {
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box + .pd_category_modal__body_box {
    margin-top: 20px;
  }
}

.pd_category_modal__body_box.pd_category_modal_keyword {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pd_category_modal__body_box.pd_category_modal_keyword .pd_input-name {
  width: 578px;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box.pd_category_modal_keyword .pd_input-name {
    width: 100%;
  }
}

.pd_category_modal__body_box.pd_category_modal_keyword .pd_select-type-twoway {
  width: 178px;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box.pd_category_modal_keyword .pd_select-type-twoway {
    display: block;
    width: 140px;
    margin-top: 15px;
    margin-left: auto;
  }
}

.pd_category_modal__body_box.pd_category_modal_category .pd_select-type-twoway {
  width: 208px;
}

.pd_category_modal__body_box.pd_category_modal_price .pd_input-name {
  width: 155px;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box.pd_category_modal_price .pd_input-name {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_box.pd_category_modal_price .pd_category_modal__body_right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.pd_category_modal__body_box.pd_category_modal_price .pd_category_modal__body_right .pd_category_tilde {
  display: inline-block;
  margin: 0 10px;
}

.pd_category_modal__body_box.pd_category_modal_sale .pd_custom-checkbox {
  margin: 0;
}

.pd_category_modal__body_heading {
  min-width: 80px;
  margin-right: 10px;
  font-size: 1.6rem;
  text-align: right;
}

@media screen and (max-width: 640px) {
  .pd_category_modal__body_heading {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: left;
  }
}

.pd_category_modal .pd_btn-horizontal {
  margin-top: 30px;
}

.pd_category_filtering {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 100;
}

@media screen and (max-width: 640px) {
  .pd_category_filtering {
    right: 10px;
    bottom: 20px;
  }
}

.pd_category_filtering__a {
  display: block;
  width: 66px;
  height: 66px;
  margin: 70px 0 10px auto;
  padding: 35px 0 10px 0;
  border-radius: 50%;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background-color: #222;
  background-image: url('/store/images/common/icon_filtering.svg');
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: center top 15px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
}

/* 05.TITLE
----------------------------------------------------------- */
/* other（追加認証、利用規約、特定商取引、、、etc） */
/*
    File Name   : other.scss
    Description : 追加認証、利用規約、特定商取引、、、etc

    01.追加認証
    02.規約同意
    03.アンケート入力
    04.アンケート完了
    05.特定商取引に基づく表記
    06.利用規約
    07.個人情報保護方針
    08.アクセスエラー
    09.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* 01.追加認証
----------------------------------------------------------- */
.pd_add_info_box {
  margin: 60px 0;
  text-align: center;
}

.pd_add_info_box__heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_add_info_box_input_area {
  width: 342px;
  margin: 0 auto;
  text-align: left;
}

@media screen and (max-width: 640px) {
  .pd_add_info_box_input_area {
    width: 100%;
  }
}

.pd_add_info_box_input_area .pd_input-name {
  width: 100%;
  margin-top: 20px;
}

.pd_add_info_box_input_area .pd_add_info_box__input_p {
  margin-top: 10px;
  color: #666;
}

/* 02.規約同意
----------------------------------------------------------- */
.pd_terms__contents {
  margin-top: 24px;
}

.pd_terms_logo {
  padding: 0 5px;
  margin-bottom: 50px;
}

@media screen and (max-width: 640px) {
  .pd_terms_logo {
    padding: 0;
  }
}

.pd_terms_logo__link {
  color: #000;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_terms_box_section {
  margin-top: 60px;
}

.pd_terms_box__heading {
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_terms_box__p + .pd_terms_box__p {
  margin-top: 20px;
}

.pd_terms_box__overflow {
  height: 335px;
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  overflow-y: scroll;
}

.pd_terms_box__input_wrap {
  margin-top: 30px;
  text-align: center;
}

/* 03.アンケート入力
----------------------------------------------------------- */
.pd_enquete_entry_box__p .pd_num_large {
  font-size: 1.6rem;
  font-weight: bold;
  color: #d00000;
}

.pd_enquete_entry_box_input_area {
  margin-top: 40px;
}

.pd_enquete_entry_box__dl + .pd_enquete_entry_box__dl {
  margin-top: 35px;
}

.pd_enquete_entry_box__dd {
  margin-top: 15px;
}

.pd_enquete_entry_box__dd .pd_select-type-twoway {
  width: 320px;
}

@media screen and (max-width: 640px) {
  .pd_enquete_entry_box__dd .pd_select-type-twoway {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .pd_enquete_entry_box__fieldset {
    width: 100%;
  }
}

.pd_enquete_entry_box__fieldset .pd_custom-control {
  margin-bottom: 20px;
}

/* 04.アンケート完了
----------------------------------------------------------- */
.pd_enquete_complete_box {
  margin: 60px 0;
  text-align: center;
}

.pd_enquete_complete_box [class^='pd_enquete_complete_box__p'] {
  margin-top: 30px;
}

.pd_enquete_complete_box__p, .pd_enquete_complete_box__p--bold, .pd_enquete_complete_box__p--gray {
  font-size: 1.6rem;
}

.pd_enquete_complete_box__p .pd_num_large, .pd_enquete_complete_box__p--bold .pd_num_large, .pd_enquete_complete_box__p--gray .pd_num_large {
  font-size: 2.4rem;
  font-weight: bold;
  color: #003da5;
}

.pd_enquete_complete_box__p--bold {
  font-weight: bold;
}

.pd_enquete_complete_box__p--gray {
  font-size: 1.4rem;
  color: #666;
}

/* 05.特定商取引に基づく表記
----------------------------------------------------------- */
.pd_particular_box .pd_indent {
  padding-left: 1em;
  text-indent: -1em;
}

.pd_particular_box_section {
  margin-top: 50px;
}

.pd_particular_box__heading {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_particular_box__p {
  font-size: 1.6rem;
}

.pd_particular_box__p + .pd_particular_box__p {
  margin-top: 20px;
}

.pd_particular_box__p.pd_ver-align img {
  display: inline;
  width: 120px;
  margin-left: 5px;
  vertical-align: middle;
}

.pd_particular_box__caution {
  margin-top: 20px;
  color: #666;
}

.pd_particular_box_tableTypeA {
  margin-top: 20px;
}

.pd_particular_box_tableTypeA__th, .pd_particular_box_tableTypeA__td {
  padding: 17px;
  border: 1px solid #e5e5e5;
}

.pd_particular_box_tableTypeA__th {
  width: 17%;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_particular_box_tableTypeA__th {
    width: 30%;
  }
}

.pd_particular_box_tableTypeA__td {
  width: 83%;
}

@media screen and (max-width: 640px) {
  .pd_particular_box_tableTypeA__td {
    width: 70%;
  }
}

.pd_particular_box_tableTypeA__td a {
  color: #1876e9;
}

.pd_particular_box_tableTypeB {
  margin-top: 20px;
}

.pd_particular_box_tableTypeB__th, .pd_particular_box_tableTypeB__td {
  padding: 17px;
  border: 1px solid #e5e5e5;
}

.pd_particular_box_tableTypeB__th {
  width: 34%;
  background-color: #f7f7f7;
  vertical-align: middle;
}

.pd_particular_box_tableTypeB__td {
  width: 66%;
}

/* 06.利用規約
----------------------------------------------------------- */
.pd_terms_use_section + .pd_terms_use_section {
  margin-top: 40px;
}

.pd_terms_use_section__heading {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
}

.pd_terms_use_box .pd_indent {
  padding-left: 1em;
  text-indent: -1em;
}

.pd_terms_use_box__p {
  font-size: 1.6rem;
}

.pd_terms_use_box__p + .pd_terms_use_box__p {
  margin-top: 20px;
}

.pd_terms_use_section .pd_terms_use_box__p {
  font-size: 1.4rem;
}

.pd_terms_use_section>.pd_terms_use_box__in_ul {
  margin-left: 1em;
}

.pd_terms_use_box__ul {
  padding-left: 1em;
  list-style-type: decimal;
}

.pd_terms_use_box__p + .pd_terms_use_box__ul {
  margin-top: 20px;
}

.pd_terms_use_box__li + .pd_terms_use_box__li {
  margin-top: 20px;
}

.pd_terms_use_box__in_ul {
  margin-top: 20px;
  padding-left: 2.5em;
  text-indent: -2.5em;
}

.pd_terms_use_box__in_li.pd_two_digits {
  text-indent: -3em;
}

.pd_terms_use_box__in_li + .pd_terms_use_box__in_li {
  margin-top: 10px;
}

/* 07.個人情報保護方針
----------------------------------------------------------- */
.pd_person_info_section + .pd_person_info_section {
  margin-top: 70px;
  padding-top: 50px;
  border-top: 1px solid #e5e5e5;
}

.pd_person_info_section.pd_border_none {
  margin-top: 60px;
  padding-top: 0;
  border-top: none;
}

.pd_person_info_section__h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
}

.pd_person_info_box .pd_indent {
  padding-left: 1em;
  text-indent: -1em;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box .pd_person_info_box_tableTypeA {
    width: calc(100% + 30px);
    margin-left: -30px;
  }
}

.pd_person_info_box__p {
  font-size: 1.6rem;
}

.pd_person_info_box__p + .pd_person_info_box__p {
  margin-top: 20px;
}

.pd_person_info_box__ul {
  margin-top: 50px;
  padding-left: 2em;
  text-indent: -2em;
}

.pd_person_info_box__li {
  font-size: 1.6rem;
}

.pd_person_info_box__li + .pd_person_info_box__li {
  margin-top: 20px;
}

.pd_person_info_box__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 50px;
}

.pd_person_info_box__info p {
  text-align: right;
}

.pd_person_info_box__info_right {
  margin-left: 30px;
}

.pd_person_info_box__dl + .pd_person_info_box__dl {
  margin-top: 20px;
}

.pd_person_info_box__dt {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box__dt.pd_indent {
    padding-left: 2em;
    text-indent: -2em;
  }
}

.pd_person_info_box__dd_wrap {
  padding-left: 30px;
}

.pd_person_info_box__dd + .pd_person_info_box__dd {
  margin-top: 10px;
}

.pd_person_info_box__in_dl {
  margin-top: 20px;
}

.pd_person_info_box__in_dt {
  margin-bottom: 10px;
  font-weight: bold;
}

.pd_person_info_box__in_dd + .pd_person_info_box__in_dd {
  margin-top: 5px;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box__in_dd.pd_indent {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
}

.pd_person_info_box_tableTypeA {
  margin-top: 20px;
}

.pd_person_info_box_tableTypeA__th, .pd_person_info_box_tableTypeA__td {
  padding: 17px;
  border: 1px solid #e5e5e5;
}

.pd_person_info_box_tableTypeA__th {
  width: 17%;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box_tableTypeA__th {
    width: 30%;
  }
}

.pd_person_info_box_tableTypeA__td {
  width: 83%;
}

@media screen and (max-width: 640px) {
  .pd_person_info_box_tableTypeA__td {
    width: 70%;
  }
}

.pd_person_info_box__link_list {
  margin-top: 20px;
}

.pd_person_info_box__link_item + .pd_person_info_box__link_item {
  margin-top: 20px;
}

.pd_person_info_box__link_a {
  padding-right: 16px;
  color: #1876e9;
  background: url('/store/images/common/icon_otherlink.svg') 100% 50% no-repeat;
  background-size: 10px 10px;
}

/* 08.アクセスエラー
----------------------------------------------------------- */
.pd_error_box {
  text-align: center;
}

.pd_error_box__p {
  font-size: 1.6rem;
}

.pd_error_box__p + .pd_error_box__p {
  margin-top: 20px;
}

/* 09.TITLE
----------------------------------------------------------- */
/* その他の各page */
/*
    File Name   : pageother.scss
    Description : その他の各コンテンツページ用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* **.TITLE
----------------------------------------------------------- */
/* **.TITLE
----------------------------------------------------------- */
/* TOP ONLY */
/*
    File Name   : top.scss
    Description : トップページ専用

    01.TOP
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
#page.pd_top-index #pd_main-header-sub {
  margin-bottom: 0;
}

/* 01.TOP
----------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .pd_top_info {
    padding: 13px 10px;
  }
}

.pd_top_info .pd_content__inner {
  padding: 0;
}

.pd_top_info__list {
  padding-right: 80px;
  position: relative;
}

.pd_top_info__link {
  color: #000;
}

.pd_top_info__link:hover {
  text-decoration: underline;
}

.pd_top_info .slick-arrow {
  width: 10px;
  height: 10px;
  margin: auto;
  padding: 0;
  border: none;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  font-size: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}

.pd_top_info .slick-arrow.slick-prev {
  right: 65px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.pd_top_info .pd_slick-counter {
  margin: auto;
  position: absolute;
  top: 0;
  right: 25px;
  bottom: 0;
}

.pd_top-index__contents .pd_content__title {
  display: none;
}

.pd_top_mv {
  margin-top: 20px;
}

.pd_top_mv__inr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_top_mv__inr {
    display: block;
  }
}

.pd_top_mv_main__link {
  display: block;
  font-size: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.pd_top_mv_main__link:hover {
  opacity: 0.7;
}

.pd_top_mv_sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 476px;
}

@media screen and (max-width: 640px) {
  .pd_top_mv_sub {
    width: 100%;
    margin-top: 10px;
  }
}

@media screen and (max-width: 640px) {
  .pd_top_mv_sub__item {
    width: calc(((100% - 10px) / 2));
  }
}

.pd_top_mv_sub__item:nth-child(n+3) {
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .pd_top_mv_sub__item:nth-child(n+3) {
    margin-top: 10px;
  }
}

.pd_top_mv_sub__link {
  display: block;
  font-size: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.pd_top_mv_sub__link:hover {
  opacity: 0.7;
}

.pd_top_bnr {
  margin-top: 30px;
}

.pd_top_bnr_news{
  font-size: 13px
}

.pd_top_bnr_a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: calc((504px / 2));
  padding: 40px;
  color: #000;
  background-image: url('/store/images/top/top_bnr_bg01.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

@media screen and (max-width: 640px) {
  .pd_top_bnr_a {
    display: block;
    height: auto;
    padding: 26px 17px;
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    background-image: url('/store/images/top/top_bnr_bg01_sp.png');
  }
}

.pd_top_bnr_a:hover {
  opacity: 0.7;
}

.pd_top_bnr_left {
  min-width: 370px;
}

@media screen and (max-width: 640px) {
  .pd_top_bnr_left {
    min-width: auto;
  }
}

.pd_top_bnr_left__heading {
  font-size: 2rem;
  font-weight: bold;
  line-height: 30px;
}

@media screen and (max-width: 640px) {
  .pd_top_bnr_left__heading {
    text-align: center;
  }
}

.pd_top_bnr_left__sub {
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .pd_top_bnr_left__sub {
    text-align: center;
  }
}

.pd_top_bnr_left .pd_btn-horizontal {
  margin-top: 20px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.pd_top_bnr_left .pd_btn-horizontal .pd_btn {
  min-width: 110px;
  margin: 0;
  padding: 8px 12px;
  font-size: 1.2rem;
}

.pd_top_bnr_right {
  width: 100%;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_top_bnr_right {
    margin-top: 25%;
    padding-bottom: 40px;
  }
}

.pd_top_bnr_right_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 150px;
  margin-left: 35px;
}

@media screen and (max-width: 640px) {
  .pd_top_bnr_right_list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: auto;
    margin-left: 0;
  }
}

.pd_top_bnr_right_list__item {
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_top_bnr_right_list__item {
    width: 50%;
    padding: 10px 10px 22px;
  }
}

@media screen and (max-width: 320px) {
  .pd_top_bnr_right_list__item:first-child {
    padding-bottom: 44px;
  }
}

.pd_top_bnr_right_list__item:nth-child(3) {
  width: 98px;
}

.pd_top_bnr_right_list__item:nth-child(3) .pd_top_bnr_right_list_figure {
  margin-top: 20px;
}

.pd_top_bnr_right_list_figure {
  text-align: center;
}

.pd_top_bnr_right_list_figure__caption {
  margin: auto;
  font-weight: bold;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.pd_top_bnr_right_logo {
  width: 117px;
  font-size: 0;
  position: absolute;
  right: 0;
  bottom: -30px;
}

@media screen and (max-width: 640px) {
  .pd_top_bnr_right_logo {
    margin: auto;
    bottom: 0;
    left: 0;
  }
}

.pd_top_bnr_right_logo img {
  width: 117px;
}

.pd_top_pickup {
  margin-top: 60px;
  padding: 50px 0 40px;
  background-color: #f7f7f7;
}

.pd_top_pickup__inr {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 2px;
}

.pd_top_pickup_list {
  position: relative;
  padding-bottom: 70px;
}

@media screen and (max-width: 640px) {
  .pd_top_pickup_list__item {
    padding: 0 10px;
  }
}

.pd_top_pickup_list__link {
  display: block;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.pd_top_pickup_list__link:hover {
  opacity: 0.7;
}

.pd_top_pickup_list__bottom {
  color: #000;
}

.pd_top_pickup_list__h3 {
  margin-top: 20px;
  font-weight: bold;
}

.pd_top_pickup_list__p {
  margin-top: 15px;
}

.pd_top_pickup .slick-arrow {
  width: 44px;
  height: 44px;
  margin: auto;
  border: none;
  border-radius: 50%;
  font-size: 0;
  background-color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.pd_top_pickup .slick-arrow.slick-prev {
  right: 54px;
}

.pd_top_pickup .slick-arrow.slick-next {
  left: 54px;
}

.pd_top_pickup .slick-arrow.slick-next::before {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.pd_top_pickup .slick-arrow::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  border: 2px solid #000;
  border-top: transparent;
  border-right: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pd_top_ranking {
  margin-top: 80px;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product {
  margin-top: 40px;
  padding: 0;
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .pd_top_ranking .pd_product_history.pd_top_ranking_product {
    padding: 0 10px;
  }
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 0;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history__li {
  width: calc(((100% - 60px) / 4));
  padding: 0;
}

@media screen and (max-width: 640px) {
  .pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history__li {
    width: calc(((100% - 10px) / 2));
  }
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history__li:nth-child(n+2) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history__li:nth-child(n+2) {
    margin-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history__li:nth-child(even) {
    margin-left: 10px;
  }
}

@media screen and (max-width: 640px) {
  .pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history__li:nth-child(n+3) {
    margin-top: 10px;
  }
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history__a {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history__a:hover {
  opacity: 0.7;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__h3 {
  font-weight: bold;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__h3 + .pd_product_history__p--red_bold, .pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__h3 + .pd_product_history__p--black_bold {
  margin-top: 5px;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_product_history_item__img img:first-child {
  width: 229px;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__ranking, .pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__ranking--No1, .pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__ranking--No2, .pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__ranking--No3, .pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__ranking--No4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 1.5rem;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__ranking--No1 {
  background-color: #c7a55a;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__ranking--No2 {
  background-color: #8a8a8a;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__ranking--No3 {
  background-color: #7a5112;
}

.pd_top_ranking .pd_product_history.pd_top_ranking_product .pd_top_ranking__ranking--No4 {
  color: #000;
  background-color: #f2f2f2;
}

.pd_top_category {
  margin-top: 60px;
  position: relative;
}

.pd_top_category .pd_content__inner {
  max-height: 345px;
  padding-bottom: 65px;
  overflow-y: hidden;
}

.pd_top_category .pd_categorybox__close {
  background-color: #fff;
}

.pd_top_category_heading_box {
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_top_category_heading_box {
    margin-bottom: 65px;
  }
}

.pd_top_category_heading_box__link {
  color: #1876e9;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 640px) {
  .pd_top_category_heading_box__link {
    width: 155px;
    margin: auto;
    text-align: center;
    bottom: -40px;
    left: 0;
  }
}

.pd_top_category_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_top_category_list__item {
  width: calc(((100% - 60px) / 4));
  height: 82px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__item {
    width: calc(((100% - 10px) / 2));
  }
}

.pd_top_category_list__item:nth-child(n+2) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__item:nth-child(n+2) {
    margin-left: auto;
  }
}

.pd_top_category_list__item:nth-child(n+5) {
  margin-top: 20px;
}

.pd_top_category_list__item:nth-child(4n+5) {
  margin-left: 0;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__item:nth-child(4n+5) {
    margin-left: auto;
  }
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__item:nth-child(even) {
    margin-left: 10px;
  }
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__item:nth-child(n+3) {
    margin-top: 10px;
  }
}

.pd_top_category_list__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding: 8px 20px 8px 110px;
  border: 1px solid #e5e5e5;
  background-repeat: no-repeat;
  background-position: center left 20px;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__link {
    padding: 8px 15px 8px 75px;
    background-position: center left 10px;
  }
}

.pd_top_category_list__link:hover {
  opacity: 0.7;
}

.pd_top_category_list__link.pd_bg_movie {
  background-image: url('/store/images/top/top_category01.png');
  background-size: 76px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__link.pd_bg_movie {
    background-size: 51px;
  }
}

.pd_top_category_list__link.pd_bg_camera {
  background-image: url('/store/images/top/top_category02.png');
  background-size: 71px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__link.pd_bg_camera {
    background-size: 53px;
  }
}

.pd_top_category_list__link.pd_bg_audio {
  background-image: url('/store/images/top/top_category03.png');
  background-size: 80px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__link.pd_bg_audio {
    background-size: 59px;
  }
}

.pd_top_category_list__link.pd_bg_housework {
  background-image: url('/store/images/top/top_category04.png');
  background-size: 70px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__link.pd_bg_housework {
    background-size: 52px;
  }
}

.pd_top_category_list__link.pd_bg_beauty {
  background-image: url('/store/images/top/top_category05.png');
  background-size: 51px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__link.pd_bg_beauty {
    background-size: 38px;
  }
}

.pd_top_category_list__link.pd_bg_health {
  background-image: url('/store/images/top/top_category06.png');
  background-size: 60px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__link.pd_bg_health {
    background-size: 45px;
  }
}

.pd_top_category_list__link.pd_bg_cooking {
  background-image: url('/store/images/top/top_category07.png');
  background-size: 67px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__link.pd_bg_cooking {
    background-size: 51px;
  }
}

.pd_top_category_list__link.pd_bg_air_conditioning_season {
  background-image: url('/store/images/top/top_category08.png');
  background-size: 54px;
}

@media screen and (max-width: 640px) {
  .pd_top_category_list__link.pd_bg_air_conditioning_season {
    background-size: 41px;
  }
}

.pd_top_category_list__link_span {
  font-size: 1.6rem;
  font-weight: bold;
  color: #000;
}

.pd_top_section_col2 {
  margin-top: 80px;
}

.pd_top_section_col2 .pd_content__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .pd_top_section_col2 .pd_content__inner {
    display: block;
  }
}

.pd_top_campaign {
  width: 448px;
  padding-bottom: calc(37px + 30px);
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_top_campaign {
    width: 100%;
  }
}

.pd_top_campaign .pd_top__heading {
  margin-bottom: 25px;
  font-size: 1.8rem;
  text-align: center;
}

.pd_top_campaign_list__item {
  padding: 0;
}

.pd_top_campaign_list__item:nth-child(n+2) {
  margin-top: 20px;
}

.pd_top_campaign_list__link {
  display: block;
  padding: 15px;
  border: 1px solid #e5e5e5;
  color: #000;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.pd_top_campaign_list__link:hover {
  opacity: 0.7;
}

.pd_top_campaign_list__link .pd_campaign_item__heading {
  font-size: 1.4rem;
}

.pd_top_campaign_list__link .pd_campaign_item__option {
  margin-top: 5px;
}

.pd_top_campaign_list__link .pd_campaign_discount {
  font-size: 1rem;
}

.pd_top_campaign_list__link .pd_campaign_item_count {
  font-size: 1.2rem;
}

.pd_top_campaign_list__link .pd_campaign_event_date {
  margin-left: 10px;
  font-size: 1.2rem;
}

@media screen and (max-width: 640px) {
  .pd_top_campaign_list__link .pd_campaign_event_date {
    margin-left: 0;
  }
}

.pd_top_campaign .pd_btn-horizontal {
  margin: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.pd_top_campaign .pd_btn-horizontal .pd_btn {
  padding: 8px 12px;
  font-size: 1.2rem;
}

.pd_top_news {
  width: 448px;
  padding-bottom: calc(37px + 30px);
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_top_news {
    width: 100%;
    margin-top: 60px;
  }
}

.pd_top_news .pd_news-list > li {
  padding: 26px 0;
}

.pd_top_news .pd_top__heading {
  margin-bottom: 25px;
  font-size: 1.8rem;
  text-align: center;
}

.pd_top_news .pd_news-list__text {
  font-size: 1.4rem;
}

.pd_top_news .pd_btn-horizontal {
  margin: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.pd_top_news .pd_btn-horizontal .pd_btn {
  padding: 8px 12px;
  font-size: 1.2rem;
}

.pd_top_ec {
  margin-top: 60px;
  padding: 50px 0 40px;
  background-color: #edf4ff;
}

.pd_top_ec__inr {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 2px;
}

@media screen and (max-width: 640px) {
  .pd_top_ec__inr {
    padding: 0 10px;
  }
}

.pd_top_ec_heading_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_heading_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.pd_top_ec_heading_box .pd_top__heading {
  margin-bottom: 0;
  font-size: 1.8rem;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_heading_box .pd_top__heading {
    margin-top: 20px;
  }
}

.pd_top_ec__label, .pd_top_ec__label--registration_free {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 3px;
}

.pd_top_ec__label--registration_free {
  margin-right: 15px;
  font-weight: bold;
  color: #d00000;
  background-color: #fff;
}

.pd_top_ec_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: calc(40px + 22px);
}

@media screen and (max-width: 640px) {
  .pd_top_ec_list {
    display: block;
  }
}

.pd_top_ec_list__item, .pd_top_ec_list__item--No1, .pd_top_ec_list__item--No2, .pd_top_ec_list__item--No3, .pd_top_ec_list__item--No4, .pd_top_ec_list__item--No5 {
  width: calc(((100% - 80px) / 5));
  padding: 145px 10px 25px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center top 55px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_list__item, .pd_top_ec_list__item--No1, .pd_top_ec_list__item--No2, .pd_top_ec_list__item--No3, .pd_top_ec_list__item--No4, .pd_top_ec_list__item--No5 {
    width: 100%;
    padding: 85px 10px 25px;
    background-position: center top 45px !important;
  }
}

.pd_top_ec_list__item:nth-child(n+2), .pd_top_ec_list__item--No1:nth-child(n+2), .pd_top_ec_list__item--No2:nth-child(n+2), .pd_top_ec_list__item--No3:nth-child(n+2), .pd_top_ec_list__item--No4:nth-child(n+2), .pd_top_ec_list__item--No5:nth-child(n+2) {
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_list__item:nth-child(n+2), .pd_top_ec_list__item--No1:nth-child(n+2), .pd_top_ec_list__item--No2:nth-child(n+2), .pd_top_ec_list__item--No3:nth-child(n+2), .pd_top_ec_list__item--No4:nth-child(n+2), .pd_top_ec_list__item--No5:nth-child(n+2) {
    margin-top: calc(15px + 22px);
    margin-left: 0;
  }
}

.pd_top_ec_list__item--No1 {
  background-image: url('/store/images/top/top_ec01.png');
  background-size: 94px;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_list__item--No1 {
    background-size: 52px;
  }
}

.pd_top_ec_list__item--No2 {
  background-image: url('/store/images/top/top_ec02.png');
  background-size: 84px;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_list__item--No2 {
    background-size: 57px;
  }
}

.pd_top_ec_list__item--No3 {
  background-image: url('/store/images/top/top_ec03.png');
  background-size: 67px;
  background-position: center top 45px;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_list__item--No3 {
    background-size: 45px;
  }
}

.pd_top_ec_list__item--No4 {
  background-image: url('/store/images/top/top_ec04.png');
  background-size: 56px;
  background-position: center top 45px;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_list__item--No4 {
    background-size: 34px;
  }
}

.pd_top_ec_list__item--No5 {
  background-image: url('/store/images/top/top_ec05.png');
  background-size: 64px;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_list__item--No5 {
    background-size: 45px;
  }
}

.pd_top_ec_list__pos_num {
  width: 44px;
  height: 44px;
  margin: auto;
  position: absolute;
  top: -22px;
  right: 0;
  left: 0;
}

.pd_top_ec_list__p {
  text-align: left;
}

.pd_top_ec_list__p--fs12 {
  font-size: 1.2rem;
  text-align: center;
}

.pd_top_ec_list__p--fs12 .pd_top_ec_list__fsL {
  display: inline-block;
  margin-top: 12px;
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_top_ec_list__p--fs12 .pd_top_ec_list__fsL {
    margin-left: 5px;
  }
}

.pd_top_ec_list__p--fs13 {
  margin-top: 15px;
  font-size: 1.3rem;
}

.pd_top_ec .pd_btn-horizontal {
  width: 400px;
  margin: 40px auto 0;
}

body:not([data-eccom-login]) .eccom-login, body:not([data-eccom-login]) .eccom-logout {
  display: none !important;
}

@media screen and (max-width: 640px) {
  .pd_top_ec .pd_btn-horizontal {
    width: 100%;
  }
}

.pd_top_ec .pd_btn-horizontal .pd_new_member {
  margin: 0;
}

.pd_top_ec_etc {
  margin-top: 20px;
  text-align: center;
}

.pd_top_ec_etc__link {
  color: #1876e9;
}

.pd_top_use_category {
  padding: 50px 0 35px;
  background-color: #f7f7f7;
}

.pd_top_use_category__inr {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 2px;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category__inr {
    padding: 0;
  }
}

.pd_top_use_category .pd_top__heading {
  margin-bottom: 20px;
  font-size: 1.8rem;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category .pd_top__heading {
    padding: 0 10px;
  }
}

.pd_top_use_category_wrap {
  padding: 20px 0;
  border-top: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_wrap {
    padding: 0;
    border-top: none;
  }
}

.pd_top_use_category_dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_dl {
    display: block;
    border-top: 1px solid #e5e5e5;
  }
}

.pd_top_use_category_dl + .pd_top_use_category_dl {
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_dl + .pd_top_use_category_dl {
    margin-top: 0;
  }
}

.pd_top_use_category_dt {
  min-width: 190px;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_dt {
    min-width: auto;
    width: 100%;
    padding: 15px 30px 15px 10px;
    position: relative;
  }
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_dt::before, .pd_top_use_category_dt::after {
    content: '';
    display: block;
    width: 12px;
    height: 1px;
    margin: auto;
    background-color: #adadad;
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_dt::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_dt.pd_is-active::after {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

.pd_top_use_category_dd {
  margin-left: 45px;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_dd {
    display: none;
    margin-left: 0;
  }
}

.pd_top_use_category_ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -10px;
  max-width: 740px;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_ul {
    margin-top: 0;
  }
}

.pd_top_use_category_li {
  margin-top: 10px;
  margin-right: 30px;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_li {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    border-top: 1px solid #e5e5e5;
  }
}

.pd_top_use_category_a {
  color: #000;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_a {
    display: block;
    width: 100%;
    padding: 15px 30px 15px 20px;
    position: relative;
  }
}

.pd_top_use_category_a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_a::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: auto;
    border-top: 1px solid #adadad;
    border-right: 1px solid #adadad;
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.pd_top_use_category_store {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_store {
    display: block;
  }
}

.pd_top_use_category_store__logo {
  display: block;
  min-width: 190px;
  font-size: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.pd_top_use_category_store__logo:hover {
  opacity: 0.7;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_store__logo {
    width: 170px;
    margin: 0 auto;
  }
}

.pd_top_use_category_store_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-left: 45px;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_store_list {
    margin-top: 10px;
    margin-left: 0;
    padding: 0 10px;
  }
}

.pd_top_use_category_store_list__item {
  margin-top: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 640px) {
  .pd_top_use_category_store_list__item {
    margin-top: 7px;
  }
}

.pd_top_use_category_store_list__a {
  display: block;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 1.2rem;
  color: #000;
  background-color: #fff;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.pd_top_use_category_store_list__a:hover {
  opacity: 0.7;
}

/* 最近見た商品用 */
/*
    File Name   : product_history.scss
    Description : 最近見た商品 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/*
$starFull: 16px;//星1個の幅
$starHalf: $starFull / 2;//星半分（0.5）の幅

$rate0-5: $starHalf;//星1
$rate1: $starFull;//星1
$rate1-5: $starFull + $starHalf;//星1.5
$rate2: $starFull * 2;//星2
$rate2-5: ($starFull * 2) + $starHalf;//星2.5
$rate3: $starFull * 3;//星3
$rate3-5: ($starFull * 3) + $starHalf;//星3.5
$rate4: $starFull * 4;//星4
$rate4-5: ($starFull * 4) + $starHalf;//星4.5
$rate5 :$starFull * 5;//星5

.pd_rate {
  position: relative;
  display: inline-block;
  width: $starFull * 5;
  height: $starFull;
  font-size: $starFull;
  &:before,&:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '★★★★★';
    display: inline-block;
    height: $starFull;
    line-height: $starFull;
  }
  &:before {
  color: #ddd;
  }
  &:after {
  color: #ffa500;
  overflow: hidden;
  white-space: nowrap;//これがないと改行されて星の半分（0.5）が表現できなくなる
  }
}
.pd_rate0:after {//星0
  width: 0;
}
.pd_rate0-5:after {//星0.5
    width: $rate0-5;
}
.pd_rate1:after {//星1
  width: $rate1;
}
.pd_rate1-5:after {//星1.5
  width: $rate1-5;
}
.pd_rate2:after {//星2
  width: $rate2;
}
.pd_rate2-5:after {//星2.5
  width: $rate2-5;
}
.pd_rate3:after {//星3
  width: $rate3;
}
.pd_rate3-5:after {//星3.5
  width: $rate3-5;
}
.pd_rate4:after {//星4
  width: $rate4;
}
.pd_rate4-5:after {//星4.5
  width: $rate4-5;
}
.pd_rate5:after {//星5
  width: $rate5;
}
*/
.pd_product_history {
  margin-top: 100px;
  padding: 40px 0;
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) {
  .pd_product_history {
    margin-top: 30px;
  }
}

.pd_product_history__ul {
  padding-bottom: 70px;
  position: relative;
}

.pd_product_history__ul .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

// .pd_product_history__ul .slick-arrow {
// width: 44px;
// height: 44px;
// margin: auto;
// border: none;
// border-radius: 50%;
// font-size: 0;
// background-color: #fff;
// position: absolute;
// right: 0;
// bottom: 0;
// left: 0;
// cursor: pointer;
// }
//
// .pd_product_history__ul .slick-arrow::before {
// content: '';
// display: block;
// width: 8px;
// height: 8px;
// margin: auto;
// border: 2px solid #000;
// border-top: transparent;
// border-right: transparent;
// position: absolute;
// top: 0;
// right: 0;
// bottom: 0;
// left: 0;
// -webkit-transform: rotate(45deg);
// transform: rotate(45deg);
// }
//
// .pd_product_history__ul .slick-arrow.slick-prev {
// right: 54px;
// }
//
// .pd_product_history__ul .slick-arrow.slick-next {
// left: 54px;
// }
//
// .pd_product_history__ul .slick-arrow.slick-next::before {
// -webkit-transform: rotate(-135deg);
// transform: rotate(-135deg);
// }
.pd_product_history__li {
  padding: 0 10px;
}

.pd_product_history__li.slick-slide {
  height: auto;
  float: none;
}

.pd_product_history__a {
  display: block;
  height: 100%;
  padding-bottom: 27px;
  color: #000;
  position: relative;
}

.pd_product_history__p, .pd_product_history__p--red, .pd_product_history__p--red_bold, .pd_product_history__p--black, .pd_product_history__p--black_bold, .pd_product_history__p--gray, .pd_product_history__p--line, .pd_product_history__p--line_bold {
  margin-top: 5px;
  font-size: 1.2rem;
  text-align: right;
}

.pd_product_history__p--red, .pd_product_history__p--red_bold {
  color: #d00000;
}

.pd_product_history__p--red_bold {
  margin-top: 44px;
  font-weight: bold;
}

.pd_product_history__p--red_bold .pd_fs_large {
  font-size: 1.4rem;
}

.pd_product_history__p--black, .pd_product_history__p--black_bold {
  color: #000;
}

.pd_product_history__p--black_bold {
  margin-top: 44px;
  font-weight: bold;
}

.pd_product_history__p--black_bold .pd_fs_large {
  font-size: 1.4rem;
}

.pd_product_history__p--gray {
  color: #666;
  font-size: 1.1rem;
}

.pd_product_history__p--gray + .pd_relation_item__description {
  margin-top: 3px;
}

.pd_product_history__p--line, .pd_product_history__p--line_bold {
  text-decoration: line-through;
}

.pd_product_history__p--line .pd_fs_large, .pd_product_history__p--line_bold .pd_fs_large {
  font-size: 1.4rem;
}

.pd_product_history__p--line_bold {
  font-weight: bold;
}

.pd_product_history_item__img {
  margin-bottom: 10px;
  font-size: 0;
  position: relative;
}

.pd_product_history_item__img img:first-child {
  width: 100%;
}
@media screen and (max-width: 640px)
    .pd_product_history_item__img img:first-child
        height: 100%!important
        width: 100%!important

.pd_product_history_item__img .pd_product_history__sale, .pd_product_history_item__img .pd_product_history__sale--blue, .pd_product_history_item__img .pd_product_history__sale--yellow {
  display: inline-block;
  padding: 5px 7px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #d00000;
  position: absolute;
  top: 10px;
  left: 0;
}

.pd_product_history_item__img .pd_product_history__sale--blue {
  background-color: #1876e9;
}

.pd_product_history_item__img .pd_product_history__sale--yellow {
  background-color: #ffba00;
}

.pd_product_history_item__img.pd_end_item::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.pd_product_history_item__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 29px;
}

.pd_product_history_item__option span {
  font-size: 1.2rem;
}

.pd_product_history_item__option + .pd_product_history__p--red_bold, .pd_product_history_item__option + .pd_product_history__p--black_bold {
  margin-top: 5px;
}

.pd_product_history_item__option .pd_product_history_item__tag, .pd_product_history_item__option .pd_product_history_item__tag--black, .pd_product_history_item__option .pd_product_history_item__tag--blue {
  display: inline-block;
  min-width: 72px;
  margin-right: 5px;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .pd_product_history_item__option .pd_product_history_item__tag, .pd_product_history_item__option .pd_product_history_item__tag--black, .pd_product_history_item__option .pd_product_history_item__tag--blue {
    min-width: 79px;
    margin-right: 5px;
  }
}

.pd_product_history_item__option .pd_product_history_item__tag--black {
  color: #fff;
  background-color: #000;
}

.pd_product_history_item__option .pd_product_history_item__tag--blue {
  color: #fff;
  background-color: #1876e9;
}

.pd_product_history .pd_favorite__productReview {
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pd_product_history .pd_favorite__productReview .pd_icon-star img {
  display: inline-block;
}

/* 関連商品 */
/*
    File Name   : relation_item.scss
    Description : 関連商品 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
.pd_product_history.pd_relation_item {
  margin-top: 0;
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
}

.pd_product_history.pd_relation_item .pd_product_history__heading {
  font-size: 2.4rem;
}

@media screen and (max-width: 640px) {
  .pd_product_history.pd_relation_item .pd_product_history__heading {
    font-size: 2rem;
  }
}

.pd_product_history.pd_relation_item .pd_relation_item__favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
}

.pd_product_history.pd_relation_item .pd_product_history_item__option {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.pd_product_history.pd_relation_item .pd_relation_item__description {
  margin-top: 12px;
  font-weight: bold;
}

.pd_product_history.pd_relation_item .pd_relation_item__part_number {
  margin-top: 12px;
  color: #666;
}

.pd_product_history.pd_relation_item .pd_relation_item__part_number + .pd_product_history__p--red_bold, .pd_product_history.pd_relation_item .pd_relation_item__part_number + .pd_product_history__p--black_bold {
  margin-top: 5px;
}

.pd_product_history.pd_relation_item .slick-arrow {
  background-color: #f7f7f7;
}

/* こんな商品も買われています */
/*
    File Name   : also_product.scss
    Description : こんな商品も買われています 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
.pd_product_history.pd_also_product {
  margin-top: 0;
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
}

.pd_product_history.pd_also_product .pd_product_history__heading {
  font-size: 2.4rem;
}

@media screen and (max-width: 640px) {
  .pd_product_history.pd_also_product .pd_product_history__heading {
    font-size: 2rem;
  }
}

.pd_product_history.pd_also_product .pd_also_product__favorite {
  cursor: pointer
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 5;
}

.pd_product_history.pd_also_product .pd_product_history_item__option {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.pd_product_history.pd_also_product .pd_also_product__description {
  margin-top: 12px;
  font-weight: bold;
}

.pd_product_history.pd_also_product .pd_also_product__part_number {
  margin-top: 12px;
  color: #666;
}

.pd_product_history.pd_also_product .pd_also_product__part_number + .pd_product_history__p--red_bold, .pd_product_history.pd_also_product .pd_also_product__part_number + .pd_product_history__p--black_bold {
  margin-top: 5px;
}

.pd_product_history.pd_also_product .slick-arrow {
  background-color: #f7f7f7;
}

/* あなたにおすすめ */
/*
    File Name   : recommend_for_you.scss
    Description : あなたにおすすめ 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
.pd_product_history.pd_recommend_for_you {
  background-color: #fff;
}

.pd_product_history.pd_recommend_for_you .slick-arrow {
  background-color: #f7f7f7;
}

/* おすすめ商品 */
/*
    File Name   : recommend_product.scss
    Description : おすすめ商品 用

    **.TITLE
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* サジェスト用 */
/*
    File Name   : suggest.scss
    Description : サジェスト用
*/
/* PC */
@media screen and (min-width: 641px) {
  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  /* ------------- */
}

/* ------------------------------------------------------ */
/* jquery UI autocomplete
----------------------------------------------------------- */
.ui-widget-content.ui-autocomplete {
  border-top: 1px solid #E5E5E5;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  background: #ffffff;
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  z-index: 999;
}

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: 0;
}

.ui-menu .ui-menu {
  position: absolute;
}

.ui-menu .ui-menu-item {
  margin: 0;
  cursor: pointer;
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: block;
  line-height: 1.3;
  padding: 12px 15px;
  color: #000;
  font-size: 1.2rem;
  border-bottom: 1px solid #E5E5E5;
}

/* SP */
@media screen and (max-width: 640px) {
  .ui-widget-content.ui-autocomplete {
    left: 10px !important;
    width: calc(100% - 20px) !important;
  }

  .ui-menu .ui-menu-item-wrapper {
    padding: 12px 15px;
  }

  /* ------------- */
}

/* module */
/*
    File Name   : module.scss
    Description : 共通モジュールcss
*/
/* common
----------------------------------------------- */
.pd_pos-rela {
  position: relative !important;
}

.pd_op_view {
  opacity: 1 !important;
}

.pd_visible {
  overflow: visible !important;
}

img {
  max-width: 100%;
  height: auto;
}

td img, th img {
  max-width: inherit;
  height: inherit;
}

.pd_cl {
  clear: both;
}

.pd_none {
  display: none !important;
}

/* clearfix */
.pd_cf:after, .pd_cf:before {
  content: '';
  display: table;
}

.pd_cf:after {
  clear: both;
}

/* font系 */
.pd_bold {
  font-weight: bold !important;
}

.pd_normal {
  font-weight: normal !important;
}

.pd_t-col01 {
  color: #D00000;
}

.pd_t-col02 {
  color: #1876E9;
}

.pd_t-col03 {
  color: #003DA5;
}

.pd_t-col04 {
  color: #158900;
}

.pd_t-col05 {
  color: #666666;
}

.pd_gray {
  color: #666666 !important;
}

/* 背景色系 */
.pd_bg-col01 {
  background: #F7F7F7;
}

/* PC
----------------------------------------------- */
@media screen and (min-width: 641px) {
  .pd_align-r {
    text-align: right !important;
  }

  .pd_align-c {
    text-align: center !important;
  }

  .pd_align-l {
    text-align: left !important;
  }

  /* view */
  .pd_sp-view {
    display: none;
  }

  /* margin-top */
  .pd_mt10_minus {
    margin-top: -10px !important;
  }

  .pd_mt5_minus {
    margin-top: -5px !important;
  }

  .pd_mt0 {
    margin-top: 0 !important;
  }

  .pd_mt5 {
    margin-top: 5px !important;
  }

  .pd_mt10 {
    margin-top: 10px !important;
  }

  .pd_mt15 {
    margin-top: 15px !important;
  }

  .pd_mt20 {
    margin-top: 20px !important;
  }

  .pd_mt25 {
    margin-top: 25px !important;
  }

  .pd_mt30 {
    margin-top: 30px !important;
  }

  .pd_mt35 {
    margin-top: 35px !important;
  }

  .pd_mt40 {
    margin-top: 40px !important;
  }

  .pd_mt45 {
    margin-top: 45px !important;
  }

  .pd_mt50 {
    margin-top: 50px !important;
  }

  /* margin-bottom */
  .pd_mb0 {
    margin-bottom: 0 !important;
  }

  .pd_mb5 {
    margin-bottom: 5px !important;
  }

  .pd_mb10 {
    margin-bottom: 10px !important;
  }

  .pd_mb15 {
    margin-bottom: 15px !important;
  }

  .pd_mb20 {
    margin-bottom: 20px !important;
  }

  .pd_mb25 {
    margin-bottom: 25px !important;
  }

  .pd_mb30 {
    margin-bottom: 30px !important;
  }

  .pd_mb35 {
    margin-bottom: 35px !important;
  }

  .pd_mb40 {
    margin-bottom: 40px !important;
  }

  .pd_mb50 {
    margin-bottom: 50px !important;
  }

  .pd_mb60 {
    margin-bottom: 60px !important;
  }

  .pd_mb70 {
    margin-bottom: 70px !important;
  }

  .pd_mb100 {
    margin-bottom: 100px !important;
  }

  /* margin-right */
  .pd_mr0 {
    margin-right: 0 !important;
  }

  .pd_mr5 {
    margin-right: 5px !important;
  }

  .pd_mr10 {
    margin-right: 10px !important;
  }

  .pd_mr15 {
    margin-right: 15px !important;
  }

  .pd_mr20 {
    margin-right: 20px !important;
  }

  .pd_mr30 {
    margin-right: 30px !important;
  }

  .pd_mr40 {
    margin-right: 40px !important;
  }

  .pd_mr50 {
    margin-right: 50px !important;
  }

  /* margin-left */
  .pd_ml0 {
    margin-left: 0 !important;
  }

  .pd_ml5 {
    margin-left: 5px !important;
  }

  .pd_ml10 {
    margin-left: 10px !important;
  }

  .pd_ml15 {
    margin-left: 15px !important;
  }

  .pd_ml20 {
    margin-left: 20px !important;
  }

  .pd_ml30 {
    margin-left: 30px !important;
  }

  .pd_ml40 {
    margin-left: 40px !important;
  }

  .pd_ml50 {
    margin-left: 50px !important;
  }

  /* padding-top */
  .pd_pt0 {
    padding-top: 0 !important;
  }

  .pd_pt5 {
    padding-top: 5px !important;
  }

  .pd_pt8 {
    padding-top: 8px !important;
  }

  .pd_pt18 {
    padding-top: 18px !important;
  }

  .pd_pt10 {
    padding-top: 10px !important;
  }

  .pd_pt15 {
    padding-top: 15px !important;
  }

  .pd_pt20 {
    padding-top: 20px !important;
  }

  .pd_pt25 {
    padding-top: 25px !important;
  }

  .pd_pt30 {
    padding-top: 30px !important;
  }

  .pd_pt35 {
    padding-top: 35px !important;
  }

  .pd_pt40 {
    padding-top: 40px !important;
  }

  .pd_pt45 {
    padding-top: 45px !important;
  }

  .pd_pt50 {
    padding-top: 50px !important;
  }

  .pd_pt55 {
    padding-top: 55px !important;
  }

  .pd_pt70 {
    padding-top: 70px !important;
  }

  .pd_pt75 {
    padding-top: 75px !important;
  }

  /* padding-bottom */
  .pd_pb0 {
    padding-bottom: 0 !important;
  }

  .pd_pb5 {
    padding-bottom: 5px !important;
  }

  .pd_pb10 {
    padding-bottom: 10px !important;
  }

  .pd_pb15 {
    padding-bottom: 15px !important;
  }

  .pd_pb20 {
    padding-bottom: 20px !important;
  }

  .pd_pb30 {
    padding-bottom: 30px !important;
  }

  .pd_pb40 {
    padding-bottom: 40px !important;
  }

  .pd_pb50 {
    padding-bottom: 50px !important;
  }

  /* padding-right */
  .pd_pr0 {
    padding-right: 0 !important;
  }

  .pd_pr5 {
    padding-right: 5px !important;
  }

  .pd_pr10 {
    padding-right: 10px !important;
  }

  .pd_pr15 {
    padding-right: 15px !important;
  }

  .pd_pr20 {
    padding-right: 20px !important;
  }

  .pd_pr30 {
    padding-right: 30px !important;
  }

  .pd_pr40 {
    padding-right: 40px !important;
  }

  .pd_pr50 {
    padding-right: 50px !important;
  }

  /* padding-left */
  .pd_pl0 {
    padding-left: 0 !important;
  }

  .pd_pl5 {
    padding-left: 5px !important;
  }

  .pd_pl10 {
    padding-left: 10px !important;
  }

  .pd_pl15 {
    padding-left: 15px !important;
  }

  .pd_pl20 {
    padding-left: 20px !important;
  }

  .pd_pl30 {
    padding-left: 30px !important;
  }

  .pd_pl40 {
    padding-left: 40px !important;
  }

  .pd_pl50 {
    padding-left: 50px !important;
  }

  /* width-size px */
  .pd_w100 {
    width: 100px !important;
  }

  /* width-size % */
  .pd_w100p {
    width: 100% !important;
  }

  .pd_w75p {
    width: 75% !important;
  }

  .pd_w50p {
    width: 50% !important;
  }

  .pd_w25p {
    width: 25% !important;
  }

  .pd_w10p {
    width: 10% !important;
  }

  .pd_w_auto {
    width: auto !important;
  }

  /* flex-order */
  .pd_order1_pc {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .pd_order2_pc {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .pd_order3_pc {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .pd_order4_pc {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .pd_order5_pc {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  /* show with PC */
  .pd_mediaPC {
    display: block !important;
  }

  .pd_mediaSP {
    display: none !important;
  }
}

/* SP
----------------------------------------------- */
@media screen and (max-width: 640px) {
  /* view */
  .pd_sp-view {
    display: block !important;
  }

  .pd_pc-view {
    display: none !important;
  }

  /*  */
  .pd_align-r_sp {
    text-align: right !important;
  }

  .pd_align-c_sp {
    text-align: center !important;
  }

  .pd_align-l_sp {
    text-align: left !important;
  }

  /* font-size */
  .pd_fs8_sp {
    font-size: 8px !important;
  }

  .pd_fs9_sp {
    font-size: 9px !important;
  }

  .pd_fs10_sp {
    font-size: 10px !important;
  }

  .pd_fs11_sp {
    font-size: 11px !important;
  }

  .pd_fs12_sp {
    font-size: 12px !important;
  }

  .pd_fs13_sp {
    font-size: 13px !important;
  }

  .pd_fs14_sp {
    font-size: 14px !important;
  }

  .pd_fs15_sp {
    font-size: 15px !important;
  }

  .pd_fs16_sp {
    font-size: 16px !important;
  }

  .pd_fs17_sp {
    font-size: 17px !important;
  }

  .pd_fs18_sp {
    font-size: 18px !important;
  }

  .pd_fs19_sp {
    font-size: 19px !important;
  }

  .pd_fs20_sp {
    font-size: 20px !important;
  }

  .pd_fs21_sp {
    font-size: 21px !important;
  }

  .pd_fs22_sp {
    font-size: 22px !important;
  }

  .pd_fs23_sp {
    font-size: 23px !important;
  }

  .pd_fs24_sp {
    font-size: 24px !important;
  }

  /* margin-top */
  .pd_mt0_sp {
    margin-top: 0 !important;
  }

  .pd_mt5_sp {
    margin-top: 5px !important;
  }

  .pd_mt10_sp {
    margin-top: 10px !important;
  }

  .pd_mt15_sp {
    margin-top: 15px !important;
  }

  .pd_mt20_sp {
    margin-top: 20px !important;
  }

  .pd_mt25_sp {
    margin-top: 25px !important;
  }

  .pd_mt30_sp {
    margin-top: 30px !important;
  }

  .pd_mt35_sp {
    margin-top: 35px !important;
  }

  .pd_mt40_sp {
    margin-top: 40px !important;
  }

  .pd_mt45_sp {
    margin-top: 45px !important;
  }

  .pd_mt50_sp {
    margin-top: 50px !important;
  }

  /* margin-bottom */
  .pd_mb0_sp {
    margin-bottom: 0 !important;
  }

  .pd_mb5_sp {
    margin-bottom: 5px !important;
  }

  .pd_mb10_sp {
    margin-bottom: 10px !important;
  }

  .pd_mb15_sp {
    margin-bottom: 15px !important;
  }

  .pd_mb20_sp {
    margin-bottom: 20px !important;
  }

  .pd_mb25_sp {
    margin-bottom: 25px !important;
  }

  .pd_mb30_sp {
    margin-bottom: 30px !important;
  }

  .pd_mb35_sp {
    margin-bottom: 35px !important;
  }

  .pd_mb40_sp {
    margin-bottom: 40px !important;
  }

  .pd_mb50_sp {
    margin-bottom: 50px !important;
  }

  .pd_mb60_sp {
    margin-bottom: 60px !important;
  }

  .pd_mb70_sp {
    margin-bottom: 70px !important;
  }

  .pd_mb100_sp {
    margin-bottom: 100px !important;
  }

  .pd_mlr10_sp_minus {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  /* margin-right */
  .pd_mr10_sp_minus {
    margin-right: -10px !important;
  }

  .pd_mr0_sp {
    margin-right: 0 !important;
  }

  .pd_mr5_sp {
    margin-right: 5px !important;
  }

  .pd_mr10_sp {
    margin-right: 10px !important;
  }

  .pd_mr15_sp {
    margin-right: 15px !important;
  }

  .pd_mr20_sp {
    margin-right: 20px !important;
  }

  .pd_mr30_sp {
    margin-right: 30px !important;
  }

  .pd_mr40_sp {
    margin-right: 40px !important;
  }

  .pd_mr50_sp {
    margin-right: 50px !important;
  }

  /* margin-left */
  .pd_ml0_sp {
    margin-left: 0 !important;
  }

  .pd_ml5_sp {
    margin-left: 5px !important;
  }

  .pd_ml7_sp {
    margin-left: 7px !important;
  }

  .pd_ml10_sp {
    margin-left: 10px !important;
  }

  .pd_ml15_sp {
    margin-left: 15px !important;
  }

  .pd_ml20_sp {
    margin-left: 20px !important;
  }

  .pd_ml30_sp {
    margin-left: 30px !important;
  }

  .pd_ml40_sp {
    margin-left: 40px !important;
  }

  .pd_ml50_sp {
    margin-left: 50px !important;
  }

  /* padding-top */
  .pd_pt0_sp {
    padding-top: 0 !important;
  }

  .pd_pt5_sp {
    padding-top: 5px !important;
  }

  .pd_pt10_sp {
    padding-top: 10px !important;
  }

  .pd_pt15_sp {
    padding-top: 15px !important;
  }

  .pd_pt20_sp {
    padding-top: 20px !important;
  }

  .pd_pt25_sp {
    padding-top: 25px !important;
  }

  .pd_pt30_sp {
    padding-top: 30px !important;
  }

  .pd_pt35_sp {
    padding-top: 35px !important;
  }

  .pd_pt40_sp {
    padding-top: 40px !important;
  }

  .pd_pt45_sp {
    padding-top: 45px !important;
  }

  .pd_pt50_sp {
    padding-top: 50px !important;
  }

  /* padding-bottom */
  .pd_pb0_sp {
    padding-bottom: 0 !important;
  }

  .pd_pb5_sp {
    padding-bottom: 5px !important;
  }

  .pd_pb10_sp {
    padding-bottom: 10px !important;
  }

  .pd_pb15_sp {
    padding-bottom: 15px !important;
  }

  .pd_pb20_sp {
    padding-bottom: 20px !important;
  }

  .pd_pb30_sp {
    padding-bottom: 30px !important;
  }

  .pd_pb40_sp {
    padding-bottom: 40px !important;
  }

  .pd_pb50_sp {
    padding-bottom: 50px !important;
  }

  /* padding-right */
  .pd.pr0_sp {
    padding-right: 0 !important;
  }

  .pd_pr5_sp {
    padding-right: 5px !important;
  }

  .pd_pr10_sp {
    padding-right: 10px !important;
  }

  .pd_pr15_sp {
    padding-right: 15px !important;
  }

  .pd_pr20_sp {
    padding-right: 20px !important;
  }

  .pd_pr30_sp {
    padding-right: 30px !important;
  }

  .pd_pr40_sp {
    padding-right: 40px !important;
  }

  .pd_pr50_sp {
    padding-right: 50px !important;
  }

  /* padding-left */
  .pd_pl0_sp {
    padding-left: 0 !important;
  }

  .pd_pl5_sp {
    padding-left: 5px !important;
  }

  .pd_pl10_sp {
    padding-left: 10px !important;
  }

  .pd_pl15_sp {
    padding-left: 15px !important;
  }

  .pd_pl20_sp {
    padding-left: 20px !important;
  }

  .pd_pl30_sp {
    padding-left: 30px !important;
  }

  .pd_pl40_sp {
    padding-left: 40px !important;
  }

  .pd_pl50_sp {
    padding-left: 50px !important;
  }

  /* width-size % */
  .pd_w100_sp {
    width: 100px !important;
  }

  .pd_w100p_sp {
    width: 100% !important;
  }

  .pd_w75p_sp {
    width: 75% !important;
  }

  .pd_w50p_sp {
    width: 50% !important;
  }

  .pd_w25p_sp {
    width: 25% !important;
  }

  .pd_w10p_sp {
    width: 10% !important;
  }

  /* flex-order */
  .pd_order1_sp {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .pd_order2_sp {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .pd_order3_sp {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .pd_order4_sp {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .pd_order5_sp {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  /* show with SP */
  .pd_mediaPC {
    display: none !important;
  }

  .pd_mediaSP {
    display: block !important;
  }
}

/* カートヘッダー */
.eccom-cart-header-body {
  width: 100%;
  height: 56px;
  background: #fff;
}

.eccom-cart-header-inner {
  width: 980px;
  margin: 0 auto;
}

.eccom-cart-header-logo {
  width: 100%;
  height: 100%;
  display: block;
  padding: 12px 0 0 2px;
}

.eccom-cart-header-logo-img {
  width: 180px;
}

/* SP */
@media screen and (max-width: 640px) {
  .eccom-cart-header-inner {
    width: 100%;
    margin: 0;
  }

  .eccom-cart-header-logo-img {
    width: 130px;
  }

  .eccom-cart-header-logo {
    padding: 10px 0 0 10px;
  }
}

/* /カートヘッダー */
/* ------------------------------------ */
/* DialogConfirm用css 追加 */
/* ------------------------------------ */
.pd_modal-content.small {
  max-width: 400px;
}

.pd_modal-content.middle {
  max-width: 680px;
}

.pd_modal-content.large {
  max-width: 976px;
}

.pd_modal-content .pd_modal-header__ttl-confirm {
  margin-bottom: 20px;
}

.pd_modal-header__lead {
  margin-bottom: 10px;
  font-size: 1.6em;
  line-height: 1.5;
}

.pd_modal-body__detail {
  max-height: 100px;
  overflow-y: scroll;
  margin-bottom: 35px;
  padding: 15px;
  border: 1px solid #eee;
  line-height: 1.5;
}

.pd_top_bnr_left__sub {
  font-size: 11px;
}

.pd_top_news_caution-link::before {
  content: '>';
  color: #000;
  padding-right: 0.5em;
}
.pd_top_ec_list__item--No4 {
  position: relative;
}

.pd_top_ec_list__pos_num-4-pre {
  position: absolute;
  top: 25px;
  font-size: 12px;
  text-align: center;
  width: 65%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (min-width: 641px) {
  .pd_top_bnr_left__heading {
    font-size: 19px;
  }

  .pd_top_bnr_left__sub {
    max-width: 330px;
    word-break: normal;
  }

  .pd_top_bnr_a {
    padding-left: 30px;
  }
}

@media screen and (max-width: 640px) {
  .pd_top_bnr_left__heading {
    font-size: 18px;
  }

  .pd_top_bnr_left__sub {
    word-break: normal;
  }
}


/* ------------------------------------------------------ */
/* serch-impl2
----------------------------------------------------------- */
body[data-eccom-login="1"]
    .pd_serchimpl2_wrap
        display: block
// セレクトボックス
.pd_serchimpl2_ChooseLabel
    position relative
    width: 180px
    &::after
        border-left 3.25px solid transparent
        border-right 3.25px solid transparent
        border-top 4.3px solid #000
        content ""
        display block
        right 12px
        position absolute
        top 50%
        -webkit-transform translateY(-50%)
        transform translateY(-50%)
    &::before
        display: block
        content: ""
        background-image: url('/store/images/common/serchImplCategory.svg')
        background-size: cover
        background-repeat: no-repeat
        height: 12px
        position: absolute
        left 14px
        top: 50%
        transform: translateY(-50%)
        width: 12px
        z-index 1
.pd_serchimpl2_ChooseAddress
    appearance none
    background-color: #fff
    border 1px solid #ccc
    border-radius 3px
    line-height: 46px
    overflow hidden
    padding 0px 31.5px 0px 31.5px
    position relative
    text-overflow ellipsis
    width 100%
    white-space nowrap
// ラジオボタン
.pd_serchimpl2-main_SelectRadio
    align-items: center
    background-color: #EDF4FF
    border-right: 1px solid #1876e9
    display flex
    line-height: 46px
    justify-content left
    padding: 0 0 0 14px
    position relative
.pd_serchimpl2-main_SerchProduct
    padding-right: 30px
.pd_serchimpl2-main_SerchAccessories
    padding-right: 21px

.pd_serchimpl2-main_InputRadio
    display none
    padding: 0 0 0 14px
    &:checked
        & ~ .pd_serchimpl2-main_Indicator
            background url("/store/images/common/icon_radio_selected.svg") no-repeat
            background-size 100% 100%
    &:disabled
        & ~ .pd_serchimpl2-main_Indicator
            background #b4b4b4
            border-radius 15px

.pd_serchimpl2-main_Indicator
    background url("/store/images/common/icon_radio.svg") no-repeat
    background-size 100% 100%
    height 16px
    width 16px

.pd_serchimpl2-main_RadioDescriptionBox
    margin 0 0 0 auto
    width 86.3%

@media screen and (min-width: 641px)
    .pd_serchimpl2-main_RadioDescriptionBox
        margin 0 0 0 10px
        width auto
.pd_serchimpl2_wrap
    background-color: #f7f7f7
    font-size: 14px
    padding: 14px 0
    position: relative
.pd_serchimpl2_wrap-inner
    width: 976px
    margin: 0 auto
    display: flex
    justify-content: space-between
.pd_serchimpl2-main_SelectDetail
    box-sizing: border-box
    border: 1px solid #1876E9
    border-radius: 3px
    display: flex
    flex-wrap: nowrap
.pd_serchimpl2-main_inputWrap
    position: relative
    height: 100%
    .pd_serchimpl2-main_inputBtn
        background-image: url('/store/images/common/serchImplSerchIcon.svg')
        background-repeat: no-repeat
        background-size: contain
        background-color: transparent
        border: 0px;
        display: block
        outline: none
        position: absolute
        right: 13.3px
        top: 50%
        transform: translateY(-50%)
        height: 14.95px
        width: 14.95px
.pd_serchimpl2-main_inputDetail
    border: 0
    box-sizing: border-box
    line-height: 46px
    padding:0 0 0 14.3px
    height: 100%
    width: 450px
.pd_serchimpl2_wrap-sp
    display: none
@media screen and (max-width: 640px)
    .pd_serchimpl2_wrap-pc
        display: none !important
    .pd_serchimpl2_wrap-sp
        display: block
    .pd_serchimpl2_wrap
        padding 7px 10px
    .pd_serchimpl2_wrap-inner
        border: 1px solid #1876E9
        border-radius: 3px
        display: flex
        width: 100%
    .pd_serchimpl2_ChooseLabel
        width: 32.676%
        &::before
            display: none
        &::after
            background-image: url("/store/images/common/select_arrow_twoway.svg")
            background-size: cover
            border: 0px
            width: 6px
            height: 11px
            right: 11px
    .pd_serchimpl2_ChooseAddress
        background-color: #EDF4FF
        border: 0px
        border-right: 1px solid #1876E9
        border-radius: 0px
        padding: 0 15px 0 16px
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    // .pd_serchimpl2_Choices
    .pd_serchimpl2-main_inputWrap
        height: auto
        width: calc(100% - 32.676%)
    .pd_serchimpl2-main_inputDetail
        padding: 0 0 0 11px
        width: 100%
@media screen and (max-width: 330px)
    .pd_serchimpl2_ChooseAddress
        padding: 0 15px 0 10px

//---------------------------------------//

#pd_main-header-sub__search {
  display: flex;
}

#pd_main-header-sub__search > li {
  background: #fff;
  height: 46px;
  min-width: 182px;
}

#pd_main-header-sub__search > li > a {
  cursor: default;
  display: block;
  position: relative;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  padding: 14px 27px 0 30px;
  line-height: 1.2;
  height: 46px;
  background: url('/store/images/common/select_arrow.svg') calc(100% - 11px) 20px no-repeat;
  background-size: 6.4px 4.3px;
  color: #000000;
  font-size: 1.4rem;
  white-space: nowrap;
}

#pd_main-header-sub__search li#pd_main-header-sub__search-category > a::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 23px;
  left: 13px;
  margin-top: -7px;
  width: 12px;
  height: 12px;
  background-image: url('/store/images/common/icon_category.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

#pd_main-header-sub__search li#pd_main-header-sub__search-category:hover #pd_main-header-sub__search-category-body {
  display: block;
}

#pd_main-header-sub__search li#pd_main-header-sub__search-series > a::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 23px;
  left: 13px;
  margin-top: -7px;
  width: 12px;
  height: 12px;
  background-image: url('/store/images/common/icon_series.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

#pd_main-header-sub__search li#pd_main-header-sub__search-series:hover #pd_main-header-sub__search-series-body {
  display: block;
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-header-sub__search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    z-index: 10;
    margin-bottom: -1px;
  }

  #pd_main-header-sub__search > li {
    background: none;
    min-height: 46px;
    height: auto;
    border-radius: 3px 3px 0 0;
    min-width: inherit;
    margin-right: 0px;
    width: calc(50% - 4px);
    padding-bottom: 0px;
  }

  #pd_main-header-sub__search > li a {
    position: relative;
    z-index: 10;
  }

  #pd_main-header-sub__search > li.pd_on {
    border: 1px solid #CCCCCC;
    border-bottom: none;
    background: #F7F7F7;
  }

  #pd_main-header-sub__search > li.pd_on > a {
    border: none;
    height: 55px;
    padding-bottom: 7px;
    background-color: #F7F7F7;
  }

  #pd_main-header-sub__search li#pd_main-header-sub__search-category {
    margin-right: 8px;
  }

  /* ------------- */
}


/* カテゴリから探す（serchimpl2__search-category-body） */
#pd_main-header-sub__search-category-body {
  display: none;
  /*  */
  position: absolute;
  top: calc(100% - 14px);
  z-index: 100;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 976px;
  height: 300px;
  border: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-category-body > ul {
  width: 240px;
  height: 300px;
  background: #F7F7F7;
  overflow: auto;
}

#pd_main-header-sub__search-category-body > ul > li {
  border-bottom: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-category-body > ul > li:last-child {
  border-bottom: none;
}

#pd_main-header-sub__search-category-body > ul > li > a {
  position: relative;
  display: block;
  padding: 8px 15px;
  color: #000;
  font-size: 1.4rem;
}

#pd_main-header-sub__search-category-body > ul > li dl {
  display: none;
  /*  */
  position: absolute;
  top: 0;
  left: 240px;
  width: calc(976px - 240px);
  height: 300px;
  overflow: auto;
  background: #fff;
  padding: 20px;
  border: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-category-body > ul > li dl dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

#pd_main-header-sub__search-category-body > ul > li dl dt figure {
  line-height: 0;
  width: 40px;
  display: none;
}

#pd_main-header-sub__search-category-body > ul > li dl dt strong {
  display: block;
  width: calc(100% - 40px);
  font-weight: bold;
  font-size: 1.8rem;
}
#pd_main-header-sub__search-category-body > ul > strong {
  display: block;
  width: calc(100% - 40px);
  font-weight: bold;
  font-size: 1.8rem;
}

#pd_main-header-sub__search-category-body > ul > li dl dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#pd_main-header-sub__search-category-body > ul > li dl dd ul li {
  font-size: 1.4rem;
  width: 33.33%;
  padding-right: 10px;
  margin: 0 0 10px 0;
}

#pd_main-header-sub__search-category-body > ul > li.pd_on {
  background: #666666;
}

#pd_main-header-sub__search-category-body > ul > li.pd_on > a {
  color: #fff;
}

#pd_main-header-sub__search-category-body > ul > li.pd_on dl {
  display: block;
}

/* PC */
@media screen and (min-width: 641px) {
  #pd_main-header-sub__search-category-body > ul > li dl dd {
    display: block !important;
  }

  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-header-sub__search-category-body {
    position: absolute;
    z-index: 2;
    top: calc(100% - 4px) !important;
    left: 0 !important;
    /*
    margin-top: -1px;
    */
    margin-bottom: 8px;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: calc(100% + 20px);
    margin: 0px -10px 0;
    padding: 0 10px 10px;
    height: auto;
    border: none;
    min-width: inherit;
    max-height: inherit;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  #pd_main-header-sub__search-category-body > ul {
    border: 1px solid #CCCCCC;
    border-radius: 0px 3px 3px 3px;
    width: auto;
    height: auto;
    background: #F7F7F7;
    padding: 10px 10px 4px 10px;
  }

  #pd_main-header-sub__search-category-body > ul > li {
    border-bottom: none;
    margin-bottom: 6px;
    background: none !important;
  }

  #pd_main-header-sub__search-category-body > ul > li > a {
    display: none;
  }

  #pd_main-header-sub__search-category-body > ul > li dl {
    display: block !important;
    /*  */
    position: static;
    top: inherit;
    left: inherit;
    width: auto;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 0;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dt {
    cursor: pointer;
    /*  */
    padding: 13px 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0px;
    background: url('/store/images/common/icon_plus.svg') calc(100% - 10px) 50% no-repeat;
    background-size: 12px 12px;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dt.pd_open {
    background: url('/store/images/common/icon_minus.svg') calc(100% - 10px) 50% no-repeat;
    background-size: 12px 12px;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dt figure {
    width: 40px;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dt strong {
    width: calc(100% - 40px);
    padding-left: 12px;
    font-size: 1.6rem;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dd {
    display: none;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dd ul {
    display: block;
    padding: 5px 20px 10px;
  }

  #pd_main-header-sub__search-category-body > ul > li dl dd ul li {
    font-size: 1.4rem;
    width: auto;
    padding-right: 0px;
    margin: 0 0 10px 0;
  }

  /* ------------- */
}

/* END カテゴリから探す（serchimpl2__search-category-body） */
/* シリーズから探す（serchimpl2__search-series-body） */
#pd_main-header-sub__search-series-body {
  display: none;
  /*  */
  position: absolute;
  top: calc(100% - 14px);
  z-index: 100;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-width: 240px;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-series-body ul li {
  border-bottom: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-series-body ul li:last-child {
  border-bottom: none;
}

#pd_main-header-sub__search-series-body ul li a {
  display: block;
  padding: 8px 15px;
  color: #000;
  font-size: 1.4rem;
  white-space: nowrap;
}

#pd_main-header-sub__search-series-body ul li a figure {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-header-sub__search-series-body {
    position: absolute;
    z-index: 2;
    top: calc(100% - 4px) !important;
    left: 0 !important;
    /*
    margin-top: -1px;
    */
    margin-bottom: 8px;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: calc(100% + 20px);
    margin: 0px -10px 0;
    padding: 0 10px 10px;
    height: auto;
    border: none;
    min-width: inherit;
    max-height: inherit;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  #pd_main-header-sub__search-series-body > ul {
    border: 1px solid #CCCCCC;
    border-radius: 3px 0px 3px 3px;
    width: auto;
    height: auto;
    background: #F7F7F7;
    padding: 10px 10px 4px 10px;
  }

  #pd_main-header-sub__search-series-body > ul li {
    border-bottom: none;
    margin-bottom: 6px;
  }

  #pd_main-header-sub__search-series-body > ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 13px 10px;
    font-weight: bold;
    font-size: 1.6rem;
    white-space: normal;
  }

  #pd_main-header-sub__search-series-body > ul li a figure {
    display: block;
    width: 40px;
    line-height: 0;
  }

  #pd_main-header-sub__search-series-body > ul li a span {
    display: block;
    width: calc(100% - 40px);
    padding-left: 12px;
  }

  /* ------------- */
}
label,button
  cursor: pointer
input::-ms-clear {
// visibility: hidden;
   transform: translateX(-20px)
}
